import {createEvent, createStore} from "effector";

export interface ISnackbarModel {
  title: string;
  status: string;
  visible: boolean;
}

export const updateSnackbarStatus = createEvent<ISnackbarModel>();
export const hideSnackbar = createEvent();

export const updateSnackbarStatusEvent = (state: ISnackbarModel, data: ISnackbarModel) => data;
export const hideSnackbarEvent = (state: ISnackbarModel) => {
  const nextState = {...state};
  nextState.visible = false;
  return nextState;
};

export const $snackbar = createStore<ISnackbarModel>({status: "", title: "", visible: false});