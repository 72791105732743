import {
  $tradesData,
  $tradesLoadingData, ITradeResponseModel,
  resetTradesData, resetTradesLoadingData,
  tradesLoadingUpdate, tradesLoadingUpdateEvent, tradesLoadingUpdatePage, tradesLoadingUpdatePageEvent,
  updateTradesData,
  updateTradesDataEvent
} from "./index";
import {combine, createEffect} from "effector";
import {API} from "../../api";

export const getSuccessTradesEx = createEffect(async ({page, offset}: {page: number, offset: number}): Promise<ITradeResponseModel[]> => {
  const response = await API.Trade.getSuccessTrades(page, offset);
  tradesLoadingUpdate(response.data['data']['loading_data']);

  return response.data['data']['trade_data'];
});

$tradesData
  .on(getSuccessTradesEx.doneData, (_currentData, newData) => [..._currentData, ...newData])
  .on(updateTradesData, updateTradesDataEvent)
  .reset(resetTradesData);

$tradesLoadingData
  .on(tradesLoadingUpdate, tradesLoadingUpdateEvent)
  .on(tradesLoadingUpdatePage, tradesLoadingUpdatePageEvent)
  .reset(resetTradesLoadingData);

export const $tradesDataCombine = combine({
  loading: getSuccessTradesEx.pending,
  trades: $tradesData,
  loadingData: $tradesLoadingData
});