import * as React from 'react';
import Dropdown from 'react-dropdown';
import './style_custom.scss';

const options = [
  { value: 'BUY', label: 'active' },
  { value: 'SELL', label: 'Продам' },
  { value: 'REQUEST', label: 'Объявление' },
];

const defaultOption = options[0];

export function DropdownStatusModal() {
  return (
      <div className="custom-dropdown__panel-admin-modal">
        <Dropdown options={options} value={defaultOption} placeholder="Select an option" />
      </div>
  );
}