import * as React from 'react';
import'./style.css';

type ModalChatsProps = {
  active: boolean;
  setActive: (v: boolean) => void;
  children: React.ReactNode;
};

export default function ModalWallet(props: ModalChatsProps) {
  return (
      <div>
        <div className={props.active ? "modal active" : "modal"} onClick={() => props.setActive(false)}>
          <div className="modal__content" onClick={e => e.stopPropagation()}>
              {props.children}
          </div>
        </div>
      </div>
  );
};