import React, { useState, useRef, useEffect } from 'react';
import styles from './AutoresizeInput.module.scss';

  export default function GrowingInput() {
    const [width, setWidth] = useState(1);

    const changeHandler = (evt: any) => {
      setWidth(evt.target.value.length);
    };

    return (
        <input className={styles.Input} style={{ width: width +'ch'}} type="text" autoFocus onChange={changeHandler} placeholder="0" maxLength={15} />
    )
};
