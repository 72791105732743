import {createEvent, createStore} from "effector";

export interface IOfferResponseModel {
  id?: number;
  uuid: string;
  author_uuid: string;
  offer_type: string;
  additionally: string;
  currency: string;
  amount: number;
  bet: number;
  surcharge_type: string;
  city: string;
  only_prof: boolean;
  seller_prof: boolean;
  fake_name: string;
  room_uuid?: string;
}

export interface IOfferLoadModel {
  last_page: boolean;
  offset: number;
  page: number;
}

export interface IOffersFilterParameters {
  currency: string;
  offer_type: string;
}

export const updateOffersData = createEvent<IOfferResponseModel[]>();
export const offersLoadingUpdate = createEvent<IOfferLoadModel>();
export const offersLoadingUpdatePage = createEvent<number>();

export const resetOffersLoadingUpdate = createEvent();
export const resetOffersData = createEvent();
export const offersDeleteItem = createEvent<number>();

export const updateOffersDataEvent = (state: IOfferResponseModel[], data: IOfferResponseModel[]) => [...state, ...data];
export const offersLoadingUpdateEvent = (state: IOfferLoadModel, data: IOfferLoadModel) => data;
export const offersLoadingUpdatePageEvent = (state: IOfferLoadModel, data: number) => {
  const nextState = {...state};
  nextState.page = data;
  return nextState;
};
export const offersDeleteItemEvent = (state: IOfferResponseModel[], item: number) => {
  const nextState = {...state};
  nextState.slice(item-1, 1)
  return nextState;
};

export const $offersData = createStore<IOfferResponseModel[]>([]);
export const $offersLoadingData = createStore<IOfferLoadModel>({last_page: true, offset: -1, page: 1});