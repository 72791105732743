import * as React from 'react';
import styles from './Add.module.scss';
import GrowingInput from "../../components/AutoresizeInput/AutoresizeInput";
import logo from '../../images/example_logo.svg';
import arrow from '../../images/arrow_left.svg';
import {CustomDropdown} from "../../components/DropdownAddSellers/CustomDropdown";

export function Add() {
  return (
      <section className={styles.Add}>
        <div className={styles.Add__Top}>
          <div className={styles.Add__Header}>
            <img src={logo} alt=""/>
            <h2>Вы покупаете у <strong>продавца</strong></h2>
          </div>

          <div className={styles.Add__Buy}>
            <div className={styles.Add__Buy__Amount}>
              <GrowingInput></GrowingInput>
              <label htmlFor="">BTC</label>
            </div>

            <div className={styles.Add__Buy__Price}>
              <span>Цена за один BTC </span>
              <button>Купить все</button>
            </div>
          </div>

          <div className={styles.Add__Payments}>
            <div className={styles.Add__Payments__Methods}>
              <h3>Методы оплаты</h3>
              <CustomDropdown></CustomDropdown>
            </div>

            <div className={styles.Add__Payments__Limits}>
              <h3>Лимиты</h3>
              <span>8 ~ 9 BTC</span>
            </div>

            <div className={styles.Add__Payments__Detail}>
              <h3>Детали объявления</h3>
              <img src={arrow} alt=""/>
            </div>
          </div>
        </div>

        <div className={styles.Add__Bottom}>
          <div className={styles.Add__Bottom__Button}>
            <button>Купить</button>
          </div>
        </div>
      </section>
  );
}