import {OfferApi} from "./offer/api";
import axios from "axios";
import {TradeApi} from "./trade/api";
import {UserApi} from "./user/api";
import {AdminApi} from "./admin/api";

const axiosInstance = axios.create();

export const API = {
  Offer: new OfferApi(),
  Trade: new TradeApi(),
  User: new UserApi(),
  Admin: new AdminApi()
};