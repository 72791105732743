// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OperationUnavailble_operation__unavailable__NtbJM {\n  width: 100vw;\n  height: 90vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  row-gap: 10px;\n  box-sizing: border-box;\n}\n\n.OperationUnavailble_operation__unavailable__NtbJM h2 {\n  margin: 0 0 10px;\n  font-size: 28px;\n  letter-spacing: 0.7px;\n}\n\n.OperationUnavailble_operation__unavailable__NtbJM p {\n  margin: 0;\n  text-align: center;\n  color: #3d3d3f;\n  font-size: 18px;\n  max-width: 300px;\n}", "",{"version":3,"sources":["webpack://./src/pages/OperationUnavailble/OperationUnavailble.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EAEA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EAEA,sBAAA;AADF;;AAIA;EACE,gBAAA;EAEA,eAAA;EACA,qBAAA;AAFF;;AAKA;EACE,SAAA;EAEA,kBAAA;EACA,cAAA;EACA,eAAA;EAEA,gBAAA;AAJF","sourcesContent":[".operation__unavailable {\n  width: 100vw;\n  height: 90vh;\n\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  row-gap: 10px;\n\n  box-sizing: border-box;\n}\n\n.operation__unavailable h2 {\n  margin: 0 0 10px;\n\n  font-size: 28px;\n  letter-spacing: 0.7px;\n}\n\n.operation__unavailable p {\n  margin: 0;\n\n  text-align: center;\n  color: #3d3d3f;\n  font-size: 18px;\n\n  max-width: 300px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"operation__unavailable": "OperationUnavailble_operation__unavailable__NtbJM"
};
export default ___CSS_LOADER_EXPORT___;
