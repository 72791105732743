import {
  $roomsData,
  $roomsLoadingData,
  $roomsMode,
  $selectedRoomData,
  IRoomsModel,
  resetSelectedRoomData,
  roomsLoadingUpdate,
  roomsLoadingUpdateEvent,
  roomsLoadingUpdatePage,
  roomsLoadingUpdatePageEvent, roomsModeReset,
  roomsModeUpdate, roomsModeUpdateEvent,
  roomsResetData,
  roomsResetLoadingPage,
  roomsUpdateData,
  roomsUpdateDataEvent,
  selectedRoomDataEvent, updateChatClosedStatus, updateChatClosedStatusEvent,
  updateSelectedRoomData
} from "./index";
import {combine, createEffect} from "effector";
import {socketRooms} from "../../../api/socket";
import {persist} from "effector-storage/local";

export const getRoomsFx = createEffect(({page, offset, moderatorMode}: {page: number, offset: number, moderatorMode: boolean}): Promise<IRoomsModel[]> => {
  socketRooms.emit('get_rooms', {
    "data": {
      "loading_data": {
        "page": page,
        "offset": offset
      },
      "moderator_mode": moderatorMode
    }
  });

  return new Promise<IRoomsModel[]>((resolve, reject) => {
    socketRooms.on('rooms_response', (rooms: any) => {
      roomsLoadingUpdate(rooms['data']['loading_data']);
      resolve(rooms['data']['sio_rooms_data']);
    });
  });
});

export const getUserRoomsFx = createEffect(({page, offset, moderatorMode, offerUuid}: {page: number, offset: number, moderatorMode: boolean, offerUuid: string}): Promise<IRoomsModel[]> => {
  socketRooms.emit('get_user_rooms', {
    "data": {
      "offer_data": {
        "uuid": offerUuid,
      },
      "loading_data": {
        "page": page,
        "offset": offset
      },
      "moderator_mode": moderatorMode
    }
  });

  return new Promise<IRoomsModel[]>((resolve, reject) => {
    socketRooms.on('rooms_response', (rooms: any) => {
      roomsLoadingUpdate(rooms['data']['loading_data']);
      resolve(rooms['data']['sio_rooms_data']);
    });
  });
});

$roomsData
  .on(getRoomsFx.doneData, (_currentRooms, newRooms) => [..._currentRooms, ...newRooms])
  .on(getUserRoomsFx.doneData, (_currentRooms, newRooms) => [..._currentRooms, ...newRooms])
  .on(roomsUpdateData, roomsUpdateDataEvent)
  .reset(roomsResetData);

$roomsLoadingData
  .on(roomsLoadingUpdate, roomsLoadingUpdateEvent)
  .on(roomsLoadingUpdatePage, roomsLoadingUpdatePageEvent)
  .reset(roomsResetLoadingPage);

$selectedRoomData
  .on(updateSelectedRoomData, selectedRoomDataEvent)
  .on(updateChatClosedStatus, updateChatClosedStatusEvent)
  .reset(resetSelectedRoomData);

$roomsMode
    .on(roomsModeUpdate, roomsModeUpdateEvent)
    .reset(roomsModeReset);

export const $roomsCombineData = combine({
  loading: getUserRoomsFx.pending,
  roomsData: $roomsData,
  roomsLoadingData: $roomsLoadingData,
  roomsMode: $roomsMode
});

persist({store: $selectedRoomData, key: 'selectedRoomPersistData'});
persist({store: $roomsMode, key: 'roomsModePersist'});