import {createEvent, createStore} from "effector";

export interface IErrorRequestFieldsModel {
  amountNoWrite: boolean;
  betNoWrite?: boolean;
  cityNoWrite: boolean;
  addressNoWrite: boolean;
  additionallyNoWrite: boolean;
}

export enum ETradeType {
  SELL = 'SELL',
  BUY = 'BUY',
  REQUEST = 'REQUEST'
}

export const updateTradeType = createEvent<ETradeType>();
export const updateErrorRequestFields = createEvent<IErrorRequestFieldsModel>();
export const updateErrorRequestAmountField = createEvent<boolean>();
export const updateErrorRequestBetField = createEvent<boolean>();
export const updateErrorRequestCityField = createEvent<boolean>();
export const updateErrorRequestAddressField = createEvent<boolean>();
export const resetErrorRequestFields = createEvent();

export const updateTradeTypeEvent = (state: ETradeType, data: ETradeType) => data;
export const updateErrorRequestFieldsEvent = (state: IErrorRequestFieldsModel, data: IErrorRequestFieldsModel) => data;

export const updateErrorRequestAmountFieldEvent = (state: IErrorRequestFieldsModel, data: boolean) => {
  const nextState = {...state};
  nextState.amountNoWrite = data;
  return nextState;
};
export const updateErrorRequestBetFieldEvent = (state: IErrorRequestFieldsModel, data: boolean) => {
  const nextState = {...state};
  nextState.betNoWrite = data;
  return nextState;
};
export const updateErrorRequestCityFieldEvent = (state: IErrorRequestFieldsModel, data: boolean) => {
  const nextState = {...state};
  nextState.cityNoWrite = data;
  return nextState;
};
export const updateErrorRequestAddressFieldEvent = (state: IErrorRequestFieldsModel, data: boolean) => {
  const nextState = {...state};
  nextState.addressNoWrite = data;
  return nextState;
};

export const $tradeType = createStore<ETradeType>(ETradeType.SELL);
export const $errorRequestFields = createStore<IErrorRequestFieldsModel>({
  additionallyNoWrite: false,
  addressNoWrite: false,
  amountNoWrite: false,
  betNoWrite: false,
  cityNoWrite: false
});