import styles from './CreateAdd.module.scss';
import add from '../../images/new_add.svg';
import {CreateAddDropdown} from "../../components/DropdownAdd/CustomDropdown";
import {useStore} from "effector-react";
import {
  $errorRequestFields,
  $tradeType,
  ETradeType,
  updateErrorRequestAddressField,
  updateErrorRequestAmountField,
  updateErrorRequestBetField,
  updateErrorRequestCityField,
  updateErrorRequestFields,
  updateTradeType
} from "../../models/Offers/AddOffer";
import {TextareaAutosize} from "@mui/material";
import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {updateSnackbarStatus} from "../../models/Snackbar";
import {API} from "../../api";
import ReactDropdown from "react-dropdown";
import {AxiosResponse} from "axios";
import {TelegramBackButton} from "../../components/BackButton/BackButton";
import {$user} from "../../models/User";
import {useNavigate} from "react-router-dom";
import RoutePaths from "../../routePaths";

interface IDropDownState {
  selected: ISelected;
  isOpen: boolean;
}

interface ISelected {
  label: string;
  value: string;
}

export function CreateAdd() {
  const navigate = useNavigate();

  const tradeType = useStore($tradeType);
  const errorRequestFields = useStore($errorRequestFields);
  const getUserData = useStore($user);

  const amountInputRef = useRef<HTMLInputElement>(null);
  const betFromMeInputRef = useRef<HTMLInputElement>(null);
  const betFromYouInputRef = useRef<HTMLInputElement>(null);
  const cityInputRef = useRef<HTMLInputElement>(null);
  const addressInputRef = useRef<HTMLInputElement>(null);
  const profCheckboxRef = useRef<HTMLInputElement>(null);
  const [blockButton, setBlockButton] = useState<boolean>(false);

  const additionallyInputRef = useRef<HTMLTextAreaElement>(null);

  const currencyRef = useRef<ReactDropdown>(null);

  const userData = useStore($user);

  // useEffect(() => {
  //   if (userData.rating < 10) {
  //     profCheckboxRef.current!.checked = true;
  //     profCheckboxRef.current!.disabled = true;
  //   }
  // }, [userData, tradeType]);

  const publishSellOrBuyOfferButton = async () => {
    if (blockButton) {
      updateSnackbarStatus({
        title: "Вы уже отправили публикацию, ожидайте!",
        status: "error",
        visible: true
      });
      return;
    }
    updateErrorRequestFields({
      amountNoWrite: amountInputRef.current! && amountInputRef.current.value === '',
      betNoWrite: betFromMeInputRef.current! && betFromMeInputRef.current.value === '' && betFromYouInputRef.current! && betFromYouInputRef.current.value === '' && userData.is_prof,
      cityNoWrite: cityInputRef.current! && cityInputRef.current.value === '',
      addressNoWrite: false,
      additionallyNoWrite: false
    });

    if (amountInputRef.current! && amountInputRef.current.value === '') {
      updateSnackbarStatus({
        title: "Вы не указали сумму",
        status: "error",
        visible: true
      });
      return;
    } else if (betFromMeInputRef.current! && betFromMeInputRef.current.value === '' && betFromYouInputRef.current! && betFromYouInputRef.current.value === '' && userData.is_prof) {
      updateSnackbarStatus({
        title: "Вы не указали доплату",
        status: "error",
        visible: true
      });
      return;
    } else if (cityInputRef.current! && cityInputRef.current.value === '') {
      updateSnackbarStatus({
        title: "Вы не указали город",
        status: "error",
        visible: true
      });
      return;
    }

    if (Number(amountInputRef.current!.value) === 0) {
      updateSnackbarStatus({
        title: "Сумма не может быть равной нулю!",
        status: "error",
        visible: true
      });
      return;
    }

    let additionallyFormat;
    if (!additionallyInputRef.current || additionallyInputRef.current.value === '') {
      additionallyFormat = 'Дополнительная информация отсутствует.';
    } else {
      additionallyFormat = additionallyInputRef.current.value;
    }

    let addressFormat;
    if (!addressInputRef.current || addressInputRef.current.value === '') {
      addressFormat = 'Улица отсутствует.';
    } else {
      addressFormat = addressInputRef.current.value;
    }

    let surchargeType = '';
    if (userData.is_prof) {
      surchargeType = betFromMeInputRef.current!.value !== '' ? "От меня" : "От Вас"
    } else {
      if (betFromMeInputRef.current! && betFromMeInputRef.current.value === '' && betFromYouInputRef.current! && betFromYouInputRef.current.value === '') {
        surchargeType = 'NONE_SUR'
      } else {
        surchargeType = betFromMeInputRef.current!.value !== '' ? "От меня" : "От Вас"
      }
    }

    const getCurrency = currencyRef.current!.state as IDropDownState;

    let response: AxiosResponse<any, any>;
    setBlockButton(true);

    if (tradeType === ETradeType.SELL) {
      if (!userData.is_prof) {
        response = await API.Offer.publishSellOffer(
          additionallyFormat, profCheckboxRef.current!.checked,
          tradeType, getUserData.user_uuid, getCurrency.selected.value, Number(amountInputRef.current!.value.replace(',', '.')),
          betFromMeInputRef.current!.value !== "" ? Number(betFromMeInputRef.current!.value.replace(',', '.')) : Number(betFromYouInputRef.current!.value.replace(',', '.')),
          cityInputRef.current!.value, addressFormat, surchargeType
        );
      } else {
        response = await API.Offer.publishSellOffer(
          additionallyFormat, false,
          tradeType, getUserData.user_uuid, getCurrency.selected.value, Number(amountInputRef.current!.value.replace(',', '.')),
          betFromMeInputRef.current!.value !== "" ? Number(betFromMeInputRef.current!.value.replace(',', '.')) : Number(betFromYouInputRef.current!.value.replace(',', '.')),
          cityInputRef.current!.value, addressFormat, surchargeType
        );
      }
    } else if (tradeType === ETradeType.BUY) {
      if (!userData.is_prof) {
        response = await API.Offer.publishBuyOffer(
          additionallyFormat, profCheckboxRef.current!.checked,
          tradeType, getUserData.user_uuid, getCurrency.selected.value, Number(amountInputRef.current!.value.replace(',', '.')),
          betFromMeInputRef.current!.value !== "" ? Number(betFromMeInputRef.current!.value.replace(',', '.')) : Number(betFromYouInputRef.current!.value.replace(',', '.')),
          cityInputRef.current!.value, addressFormat, surchargeType
        );
      } else {
        response = await API.Offer.publishBuyOffer(
          additionallyFormat, false,
          tradeType, getUserData.user_uuid, getCurrency.selected.value, Number(amountInputRef.current!.value.replace(',', '.')),
          betFromMeInputRef.current!.value !== "" ? Number(betFromMeInputRef.current!.value.replace(',', '.')) : Number(betFromYouInputRef.current!.value.replace(',', '.')),
          cityInputRef.current!.value, addressFormat, surchargeType
        );
      }
    } else {
      setBlockButton(false);
      return;
    }

    if (response.data['status'] === 'OK') {
      updateSnackbarStatus({
        title: "Ваше объявление успешно опубликовано!",
        status: "success",
        visible: true
      });
      navigate(RoutePaths.MARKET);
    } else if (response.data['status'] === 'UniqueViolationError') {
      updateSnackbarStatus({
        title: "Произошла ошибка уникальности. Попробуйте обновить страницу!",
        status: "success",
        visible: true
      });
    } else if (response.data['status'] === 'DataBase Error') {
      updateSnackbarStatus({
        title: response.data['message'],
        status: "success",
        visible: true
      });
    } else if (response.data['status'] === 'The word is forbidden') {
      setBlockButton(false);
      updateSnackbarStatus({
        title: "Внимание! Вы используете запрещенное слово - " + response.data['word'],
        status: "warning",
        visible: true
      });
    } else if (response.data['status'] === 'There is no balance to create an offer') {
      setBlockButton(false);
      updateSnackbarStatus({
        title: "Недостаточно баланса для публикации объявления!",
        status: "warning",
        visible: true
      });
    } else if (response.data['status'] === 'The limit on offers has been reached') {
      setBlockButton(false);
      updateSnackbarStatus({
        title: "Недостаточно баланса для публикации объявления!",
        status: "warning",
        visible: true
      });
    } else if (response.data['status'] === 'No write amount') {
      setBlockButton(false);
      updateSnackbarStatus({
        title: "Вы не указали сумму, или указали ее с ошибкой!",
        status: "warning",
        visible: true
      });
    } else if (response.data['status'] === 'No write bet') {
      setBlockButton(false);
      updateSnackbarStatus({
        title: "Вы не указали доплату, или указали ее с ошибкой!",
        status: "warning",
        visible: true
      });
    } else if (response.data['status'] === 'Is not a buy offer') {
      setBlockButton(false);
      updateSnackbarStatus({
        title: "Oops! Произошла проблема, попробуйте перезагрузить страницу!(buy)",
        status: "warning",
        visible: true
      });
    } else if (response.data['status'] === 'Is not a sell offer') {
      setBlockButton(false);
      updateSnackbarStatus({
        title: "Oops! Произошла проблема, попробуйте перезагрузить страницу!(sell)",
        status: "warning",
        visible: true
      });
    } else if (response.data['status'] === 'Is not a request offer') {
      setBlockButton(false);
      updateSnackbarStatus({
        title: "Oops! Произошла проблема, попробуйте перезагрузить страницу!(request)",
        status: "warning",
        visible: true
      });
    } else if (response.data['status'] === 'Not send username!') {
      setBlockButton(false);
      updateSnackbarStatus({
        title: "Нельзя выставлять свои контакты!",
        status: "warning",
        visible: true
      });
    }
  }

  const publishRequestOfferButton = async () => {
    if (blockButton) {
      updateSnackbarStatus({
        title: "Вы уже отправили публикацию, ожидайте!",
        status: "error",
        visible: true
      });
      return;
    }
    updateErrorRequestFields({
      amountNoWrite: false,
      betNoWrite: false,
      cityNoWrite: false,
      addressNoWrite: false,
      additionallyNoWrite: additionallyInputRef.current! && additionallyInputRef.current.value === ''
    });

    if (additionallyInputRef.current! && additionallyInputRef.current.value === '') {
      updateSnackbarStatus({
        title: "Вы не указали дополнительную информацию!",
        status: "error",
        visible: true
      });
      return;
    }

    let response: AxiosResponse<any, any>;

    setBlockButton(true);
    if (!userData.is_prof) {
      response = await API.Offer.publishRequestOffer(
        additionallyInputRef.current!.value, profCheckboxRef.current!.checked, tradeType,
        getUserData.user_uuid
      );
    } else {
      response = await API.Offer.publishRequestOffer(
        additionallyInputRef.current!.value, false, tradeType,
        getUserData.user_uuid
      );
    }

    if (response.data['status'] === 'OK') {
      updateSnackbarStatus({
        title: "Ваше объявление успешно опубликовано!",
        status: "success",
        visible: true
      });
      navigate(RoutePaths.MARKET);
    } else if (response.data['status'] === 'UniqueViolationError') {
      updateSnackbarStatus({
        title: "Произошла ошибка уникальности. Попробуйте обновить страницу!",
        status: "success",
        visible: true
      });
    } else if (response.data['status'] === 'DataBase Error') {
      updateSnackbarStatus({
        title: response.data['message'],
        status: "success",
        visible: true
      });
    } else if (response.data['status'] === 'The word is forbidden') {
      setBlockButton(false);
      updateSnackbarStatus({
        title: "Внимание! Вы используете запрещенное слово - " + response.data['word'],
        status: "warning",
        visible: true
      });
    } else if (response.data['status'] === 'There is no balance to create an offer') {
      setBlockButton(false);
      updateSnackbarStatus({
        title: "Недостаточно баланса для публикации объявления!",
        status: "warning",
        visible: true
      });
    } else if (response.data['status'] === 'The limit on offers has been reached') {
      setBlockButton(false);
      updateSnackbarStatus({
        title: "Недостаточно баланса для публикации объявления!",
        status: "warning",
        visible: true
      });
    } else if (response.data['status'] === 'Not send username!') {
      setBlockButton(false);
      updateSnackbarStatus({
        title: "Нельзя выставлять свои контакты!",
        status: "warning",
        visible: true
      });
    }
  };

  const numberInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const eventCode = event.code.toLowerCase();

    if (!/(?=.)([+-]?(?=[\d.,])(\d*)(\,(\d+))*(\.(\d+))?)/.test(event.key) && eventCode !== 'backspace') {
      event.preventDefault();
    }
  };

  const betFromMeInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    betFromYouInputRef.current!.disabled = event.target.value !== '';
  };

  const betFromYouInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    betFromMeInputRef.current!.disabled = event.target.value !== '';
  };

  const betOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value === '') return;
    if (!/^[-0-9]\d*(\.\d+)*(\,\d+)?$/.test(event.target.value)) {
      updateSnackbarStatus({
        title: "Вы ввели неправильное значение в доплате " + event.target.value + ' \nвозможно Вы случайно ввели две точки или забыли указать число после или перед точкой!',
        status: "warning",
        visible: true
      });

      betFromYouInputRef.current!.disabled = false;
      betFromMeInputRef.current!.disabled = false;
      event.target.value = '';
    }
  }

  const amountOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value === '') return;
    if (!/^[-0-9]\d*(\.\d+)*(\,\d+)?$/.test(event.target.value)) {
      updateSnackbarStatus({
        title: "Вы ввели неправильное значение в сумме " + event.target.value + ' \nвозможно Вы случайно ввели две точки или забыли указать число после или перед точкой!',
        status: "warning",
        visible: true
      });

      event.target.value = '';
    }
  }

  const descriptionInputKeyDown = (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    const eventCode = event.code.toLowerCase();

    if (
      !/^[A-Za-zА-Яа-яЁё0-9A-Za-zÀ-ÿёа-зй-шы-яЁА-ЗЙ-ШЫІіЎўa-zA-ZäöüßÄÖÜẞa-pr-uwy-zA-PR-UWY-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻa-zA-ZĂÂÎȘȚăâîșțА-ИК-ШЂЈ-ЋЏа-ик-шђј-ћџа-щА-ЩЬьЮюЯяЇїІіЄєҐґ'a-zA-ZàâäôéèëêïîçùûüÿæœÀÂÄÔÉÈËÊÏÎŸÇÙÛÜÆŒ\s`~!@#$₽€£•%^&*()_+-={}|:;<>?,.–—«»„“”"‘’'₩¥§~…¿¡№\/\"\'\\\[\]]+$/.test(
        event.key
      ) &&
      eventCode !== "backspace" &&
      eventCode !== "enter"
    ) {
      event.preventDefault();
      updateSnackbarStatus({
        title: "Вы попытались написать запрещенный символ " + event.key,
        status: "warning",
        visible: true
      });
    }
  };

  const descriptionInputPaste = (
    event: React.ClipboardEvent<HTMLTextAreaElement>
  ) => {
    if (
      !/^[A-Za-zА-Яа-яЁё0-9A-Za-zÀ-ÿёа-зй-шы-яЁА-ЗЙ-ШЫІіЎўa-zA-ZäöüßÄÖÜẞa-pr-uwy-zA-PR-UWY-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻa-zA-ZĂÂÎȘȚăâîșțА-ИК-ШЂЈ-ЋЏа-ик-шђј-ћџа-щА-ЩЬьЮюЯяЇїІіЄєҐґ'a-zA-ZàâäôéèëêïîçùûüÿæœÀÂÄÔÉÈËÊÏÎŸÇÙÛÜÆŒ\s`~!@#$₽€£•%^&*()_+-={}|:;<>?,.–—«»„“”"‘’'₩¥§~…¿¡№\/\"\'\\\[\]]+$/.test(
        event.clipboardData.getData("text")
      )
    ) {
      event.preventDefault();
      updateSnackbarStatus({
        title: "В тексте который Вы пытаетесь вставить имеются запрещенные символы(смайлики), если это не так, передайте Ваш текст в поддержку.",
        status: "warning",
        visible: true
      });
    }
  };

  const cityInputKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const eventCode = event.code.toLowerCase();

    if (
      !/^[A-Za-zА-Яа-яЁё0-9A-Za-zÀ-ÿёа-зй-шы-яЁА-ЗЙ-ШЫІіЎўa-zA-ZäöüßÄÖÜẞa-pr-uwy-zA-PR-UWY-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻa-zA-ZĂÂÎȘȚăâîșțА-ИК-ШЂЈ-ЋЏа-ик-шђј-ћџа-щА-ЩЬьЮюЯяЇїІіЄєҐґ'a-zA-ZàâäôéèëêïîçùûüÿæœÀÂÄÔÉÈËÊÏÎŸÇÙÛÜÆŒ\s`~!@#$₽€£•%^&*()_+-={}|:;<>?,.–—«»„“”"‘’'₩¥§~…¿¡№\/\"\'\\\[\]]+$/.test(
        event.key
      ) &&
      eventCode !== "backspace" &&
      eventCode !== "enter"
    ) {
      event.preventDefault();
      updateSnackbarStatus({
        title: "Вы попытались написать запрещенный символ " + event.key,
        status: "warning",
        visible: true
      });
    }
  };

  const cityInputPaste = (
    event: React.ClipboardEvent<HTMLInputElement>
  ) => {
    if (
      !/^[A-Za-zА-Яа-яЁё0-9A-Za-zÀ-ÿёа-зй-шы-яЁА-ЗЙ-ШЫІіЎўa-zA-ZäöüßÄÖÜẞa-pr-uwy-zA-PR-UWY-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻa-zA-ZĂÂÎȘȚăâîșțА-ИК-ШЂЈ-ЋЏа-ик-шђј-ћџа-щА-ЩЬьЮюЯяЇїІіЄєҐґ'a-zA-ZàâäôéèëêïîçùûüÿæœÀÂÄÔÉÈËÊÏÎŸÇÙÛÜÆŒ\s`~!@#$₽€£•%^&*()_+-={}|:;<>?,.–—«»„“”"‘’'₩¥§~…¿¡№\/\"\'\\\[\]]+$/.test(
        event.clipboardData.getData("text")
      )
    ) {
      event.preventDefault();
      updateSnackbarStatus({
        title: "В тексте который Вы пытаетесь вставить имеются запрещенные символы(смайлики), если это не так, передайте Ваш текст в поддержку.",
        status: "warning",
        visible: true
      });
    }
  };

  // const changeInputChecked = (event: ChangeEvent<HTMLInputElement>) => {
  //   console.log(!event.target.checked)
  //   event.target.checked = !event.target.checked;
  //   // event.target.
  // }

  return (
      <section className={styles.CreateAdd}>
        <TelegramBackButton
          onClick={() => {
            window.history.back();
          }}
        />
        <div className={styles.CreateAdd__Header}>
          <h2 className={styles.CreateAdd__Header__Title}>Новое объявление</h2>
          <img src={add} alt=""/>
        </div>

        <div className={styles.CreateAdd__Select}>
          <div className={styles['create-add__select__item']} onClick={() => updateTradeType(ETradeType.BUY)}>
            <div className={tradeType !== ETradeType.BUY ? styles['create-add__select__item__circle'] : styles['create-add__select__item__circle__active']}></div>
            <span>Куплю</span>
          </div>

          <div className={styles['create-add__select__item']} onClick={() => updateTradeType(ETradeType.SELL)}>
            <div>
              <div className={tradeType !== ETradeType.SELL ? styles['create-add__select__item__circle'] : styles['create-add__select__item__circle__active']}></div>
            </div>

            <span>Продам</span>
          </div>

          <div className={styles['create-add__select__item']} onClick={() => updateTradeType(ETradeType.REQUEST)}>
            <div>
              <div className={tradeType !== ETradeType.REQUEST ? styles['create-add__select__item__circle'] : styles['create-add__select__item__circle__active']}></div>
            </div>
            <span>Запрос</span>
          </div>
        </div>

        {
          tradeType !== ETradeType.REQUEST &&
            <>
                <div className={styles.CreateAdd__Items}>
                    <div className={styles.CreateAdd__Items__Item}>
                        <h3>Валюта</h3>
                        <div className={styles.CreateAdd__Items__Item__Select}>
                            <CreateAddDropdown currencyRef={currencyRef}></CreateAddDropdown>
                        </div>
                    </div>

                    <div className={styles.CreateAdd__Items__Item}>
                        <h3>Сумма</h3>

                        <input onBlur={(event) => amountOnBlur(event)} onKeyDown={numberInputKeyDown} onFocus={() => updateErrorRequestAmountField(false)} ref={amountInputRef} type="text" placeholder="Сумма" className={!errorRequestFields.amountNoWrite ? styles.ItemsInput + " " + styles.input_Normal : styles.ItemsInput + " " + styles.input_Error}/>
                    </div>

                    <div className={styles.CreateAdd__Items__Item}>
                        <h3>Доплачу Вам, %</h3>

                        <input onBlur={(event) => betOnBlur(event)} onChange={(event) => betFromMeInputChange(event)} onKeyDown={numberInputKeyDown} onFocus={() => updateErrorRequestBetField(false)} ref={betFromMeInputRef} type="text" placeholder={userData.is_prof ? "От меня" : "Необязательное поле"} className={!errorRequestFields.betNoWrite ? styles.ItemsInput + " " + styles.input_Normal : styles.ItemsInput + " " + styles.input_Error}/>
                    </div>

                    <div className={styles.CreateAdd__Items__Item}>
                        <h3>Доплатите мне, %</h3>

                        <input onBlur={(event) => betOnBlur(event)} onChange={(event) => betFromYouInputChange(event)} onKeyDown={numberInputKeyDown} onFocus={() => updateErrorRequestBetField(false)} ref={betFromYouInputRef} type="text" placeholder={userData.is_prof ? "От Вас" : "Необязательное поле"} className={!errorRequestFields.betNoWrite ? styles.ItemsInput + " " + styles.input_Normal : styles.ItemsInput + " " + styles.input_Error}/>
                    </div>

                    <div className={styles.CreateAdd__Items__Item}>
                        <h3>Город</h3>

                        <input onKeyDown={cityInputKeyDown} onPaste={cityInputPaste} onFocus={() => updateErrorRequestCityField(false)} ref={cityInputRef} type="text" placeholder="Город" className={!errorRequestFields.cityNoWrite ? styles.ItemsInput + " " + styles.input_Normal : styles.ItemsInput + " " + styles.input_Error}/>
                    </div>
                </div>
                <div className={styles.CreateAdd__AddInfo}>
                    <h3>Дополнительная информация</h3>

                    <TextareaAutosize ref={additionallyInputRef} maxRows={6} placeholder="Доп. информация" style={{fontSize: '16px'}} onKeyDown={descriptionInputKeyDown} onPaste={descriptionInputPaste}/>
                      {
                        !userData.is_prof &&
                          <div className={styles.CreateAdd__AddText__ForPros}>
                              <input ref={profCheckboxRef} type="checkbox" checked={true} disabled={true}/>
                              <span>Только для профучастников</span>
                          </div>
                      }
                      {
                        !userData.is_prof &&
                          <span className={styles.CreateAdd__SmallRating}>Размещение объявлений для всех доступно только профучастникам! Правила вступления в профучастники доступно после достижения рейтинга 10.</span>
                      }
                </div>
                <div className={styles.CreateAdd__AddInfo}>
                    <div className={styles.CreateAdd__AdditionallyInfo__UsernameWarning}>
                        <p>Правилами нашего проекта запрещено публиковать свои контактные данные и ссылки !
                        </p>
                    </div>
                </div>
            </>
        }

        {
          tradeType === ETradeType.REQUEST &&
          <>
            <div className={styles.CreateAdd__AddInfo}>
              <h3>Текст объявления</h3>

              <TextareaAutosize ref={additionallyInputRef} maxRows={6} placeholder="Текст объявления" style={{fontSize: '16px'}} onKeyDown={descriptionInputKeyDown} onPaste={descriptionInputPaste}/>
              {
                !userData.is_prof &&
                  <div className={styles.CreateAdd__AddText__ForPros}>
                      <input ref={profCheckboxRef} type="checkbox" checked={true} disabled={true}/>
                      <span>Только для профучастников</span>
                  </div>
              }
              {
                !userData.is_prof &&
                  <span className={styles.CreateAdd__SmallRating}>Размещение объявлений для всех доступно только профучастникам! Правила вступления в профучастники доступно после достижения рейтинга 10.</span>
              }
            </div>
            <div className={styles.CreateAdd__AddInfo}>
                <div className={styles.CreateAdd__AdditionallyInfo__UsernameWarning}>
                    <p>Правилами нашего проекта запрещено публиковать свои контактные данные и ссылки !
                    </p>
                </div>
            </div>
          </>
        }


        <div className={styles.CreateAdd__Buttons}>
          <button onClick={tradeType === ETradeType.REQUEST ? publishRequestOfferButton : publishSellOrBuyOfferButton} disabled={blockButton}>Опубликовать</button>
          <button onClick={() => navigate(RoutePaths.MARKET)}>Отменить</button>
        </div>
      </section>
  );
}


// import "./styles.css";
// import React, { ChangeEvent, useEffect, useRef, useState } from "react";
//
// export default function App() {
//   const descriptionInputKeyDown = (
//     event: React.KeyboardEvent<HTMLInputElement>
//   ) => {
//     const eventCode = event.code.toLowerCase();
//
//     if (
//       !/^[A-Za-zА-Яа-яЁё0-9\s`~!@#$%^&*()_+-={}|:;<>?,.\/\"\'\\\[\]]+$/.test(
//         event.key
//       ) &&
//       eventCode !== "backspace" &&
//       eventCode !== "enter"
//     ) {
//       event.preventDefault();
//     }
//   };
//
//   const descriptionInputPaste = (
//     event: React.ClipboardEvent<HTMLInputElement>
//   ) => {
//     if (
//       !/^[A-Za-zА-Яа-яЁё0-9\s`~!@#$%^&*()_+-={}|:;<>?,.\/\"\'\\\[\]]+$/.test(
//         event.clipboardData.getData("text")
//       )
//     ) {
//       event.preventDefault();
//     }
//   };
//
//   return (
//     <div className="App">
//       <input
//         onKeyDown={descriptionInputKeyDown}
//         onPaste={descriptionInputPaste}
//       ></input>
//     </div>
//   );
// }
