import {createEvent, createStore} from "effector";

export interface IRoomsModel {
  room_id?: number;
  room_uuid: string;
  interlocutor_username?: string;
  name: string;
  implementer_uuid?: string;
  client_uuid?: string;
  moderator_uuid: string;
  trade_uuid: string;
  chat_closed: boolean;
  last_message?: string;
  last_message_time?: string;
}

export interface IRoomsLoadModel {
  last_page: boolean;
  offset: number;
  page: number;
}

export enum ERoomsMode {
  ModerationMode,
  UserMode
}

export const roomsLoadingUpdate = createEvent<IRoomsLoadModel>();
export const roomsLoadingUpdatePage = createEvent<number>();
export const roomsUpdateData = createEvent<IRoomsModel[]>();
export const updateSelectedRoomData = createEvent<IRoomsModel>();
export const updateChatClosedStatus = createEvent<boolean>();
export const updateChatClosedStatusEvent = (state: IRoomsModel, data: boolean) => {
  const nextState = {...state};
  nextState.chat_closed = data;
  return nextState;
}

export const roomsResetData = createEvent();
export const roomsResetLoadingPage = createEvent();
export const resetSelectedRoomData = createEvent();

export const roomsLoadingUpdateEvent = (state: IRoomsLoadModel, data: IRoomsLoadModel) => data;
export const roomsLoadingUpdatePageEvent = (state: IRoomsLoadModel, data: number) => {
  const nextState = {...state};
  nextState.page = data;
  return nextState;
};
export const roomsUpdateDataEvent = (state: IRoomsModel[], data: IRoomsModel[]) => [...state, ...data];
export const selectedRoomDataEvent = (state: IRoomsModel, data: IRoomsModel) => data;

export const roomsModeUpdate = createEvent<ERoomsMode>();
export const roomsModeUpdateEvent = (state: ERoomsMode, data: ERoomsMode) => data;
export const roomsModeReset = createEvent();

export const $selectedRoomData = createStore<IRoomsModel>({
  chat_closed: false,
  client_uuid: "",
  implementer_uuid: "",
  last_message: "",
  last_message_time: "",
  moderator_uuid: "",
  interlocutor_username: "",
  name: "Данные отсутствуют",
  room_id: 0,
  room_uuid: "",
  trade_uuid: ""
});
export const $roomsData = createStore<IRoomsModel[]>([]);
export const $roomsLoadingData = createStore<IRoomsLoadModel>({last_page: true, offset: -1, page: 1});
export const $roomsMode = createStore<ERoomsMode>(ERoomsMode.UserMode);