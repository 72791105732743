import React from 'react';
import styles from './BlockedUser.module.scss';
import blocked_user from '../../images/user-ban.svg';

const BlockedUser = () => {
  return (
      <div className={styles.BlockedUser}>
        <div className={styles.BlockedUser__Top}>
          <div className={styles.BlockedUser__Photo}>
            <img src={blocked_user} alt=""/>
          </div>

          <div className={styles.BlockedUser__Text}>
            <h2>Ваш аккаунт заблокирован</h2>

            {/*<p>*/}
            {/*  <b>Причина:</b> причина, по которой заблокировали Lorem ipsum dolor sit amet, consectetur adipisicing elit. At fuga ipsum magnam placeat quas ratione recusandae temporibus veritatis. Corporis distinctio eveniet, exercitationem minus provident quidem rem totam unde ut velit.*/}
            {/*</p>*/}
          </div>
        </div>

        <div className={styles.BlockedUser__Bottom}>
          <div className={styles.BlockedUser__Button}>
            <button>Закрыть</button>
          </div>
        </div>
      </div>
  );
};

export default BlockedUser;