import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import RoutePaths from "../../routePaths";
import {updateSelectedRoomData} from "../../models/Chat/Room";
import {updateRedirectPageStatus} from "../../models/Page";

export function Redirect() {
  const navigate = useNavigate();

  const queryParameters = new URLSearchParams(window.location.search)
  const page = queryParameters.get("page")!.trim()

  useEffect(() => {
    switch (page) {
      case 'chat':
        const roomUuid = queryParameters.get("room_uuid")!.trim();

        // updateSelectedRoomData({
        //   room_uuid: roomUuid,
        //   name: name,
        //   trade_uuid: tradeUuid,
        //   moderator_uuid: moderatorUuid
        // });

        updateRedirectPageStatus(true);
        navigate(RoutePaths.CHAT, {state: {
            roomUuid: roomUuid,
        }});
        break;
      case 'addRedirect':
        const offerUuid = queryParameters.get("offer_uuid")!.trim();

        updateRedirectPageStatus(true);
        navigate(RoutePaths.ADD_REDIRECT, {
          state: {
            offerUuid: offerUuid
          }
        });
        break;
      default:
        navigate(RoutePaths.MARKET);
        break;
    }
    // eslint-disable-next-line
  }, [page]);

  return (
    <div>
      <span>redirecting...</span>
    </div>
  );
}