import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, {AlertColor, AlertProps} from '@mui/material/Alert';
import {forwardRef, SyntheticEvent, useState} from "react";
import {hideSnackbar} from "../../models/Snackbar";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type CustomizedSnackbarsProps = {
  title: string;
  messageType: string;
}

export default function SimpleSnackbar(props: CustomizedSnackbarsProps) {
  const [open, setOpen] = useState(true);

  const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    hideSnackbar();
    setOpen(false);
  };

  return (
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={props.messageType as AlertColor} sx={{ width: '100%' }}>
            {props.title}
          </Alert>
        </Snackbar>
      </Stack>
  );
}
