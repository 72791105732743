import styles from "./Room.module.scss";
import {TelegramBackButton} from "../../../components/BackButton/BackButton";
import React, {useEffect, useRef, useState} from "react";
import {$roomsCombineData, getRoomsFx, getUserRoomsFx} from "../../../models/Chat/Room/init";
import {useInView} from "react-intersection-observer";
import {useStore} from "effector-react";
import {
  ERoomsMode,
  IRoomsModel,
  roomsLoadingUpdatePage,
  roomsResetData,
  roomsResetLoadingPage,
  updateSelectedRoomData
} from "../../../models/Chat/Room";
import {useLocation, useNavigate} from "react-router-dom";
import RoutePaths from "../../../routePaths";
import {socketRooms} from "../../../api/socket";
import Skeleton from "react-loading-skeleton";
import ToggleSwitchChats from "../../../components/ToggleSwitchChats/ToggleSwitch";
import {$user} from "../../../models/User";

export function Room() {
  const navigate = useNavigate();
  const {state} = useLocation();
  const {offerUuid} = state;

  const [scrollTriggered, setScrollTriggered] = useState<boolean>(false);

  const roomsCombineData = useStore($roomsCombineData);
  const userData = useStore($user);

  useEffect(() => {
    roomsResetData();
    roomsResetLoadingPage();

    socketRooms.connect();
    if (offerUuid === 'IN_MARKET') {
      console.log(1)
      getRoomsFx({
        page: 1,
        offset: -1,
        moderatorMode: roomsCombineData.roomsMode === ERoomsMode.ModerationMode
      }).then();
    } else {
      getUserRoomsFx({
        page: 1,
        offset: -1,
        moderatorMode: roomsCombineData.roomsMode === ERoomsMode.ModerationMode,
        offerUuid: offerUuid
      }).then();
    }
  }, []);

  const {ref, inView, entry} = useInView({
    triggerOnce: true
  });

  if (inView && !scrollTriggered && !roomsCombineData.roomsLoadingData.last_page && !roomsCombineData.loading) {
    entry?.target.removeAttribute('ref');
    setScrollTriggered(true);

    roomsLoadingUpdatePage(roomsCombineData.roomsLoadingData.page + 1);
    if (offerUuid === 'IN_MARKET') {
      getRoomsFx({
        page: roomsCombineData.roomsLoadingData.page + 1,
        offset: roomsCombineData.roomsLoadingData.offset,
        moderatorMode: roomsCombineData.roomsMode === ERoomsMode.ModerationMode
      }).then();
    } else {
      getUserRoomsFx({
        page: 1,
        offset: -1,
        moderatorMode: roomsCombineData.roomsMode === ERoomsMode.ModerationMode,
        offerUuid: offerUuid
      }).then();
    }
  }

  useEffect(() => {
    if (!roomsCombineData.loading && !roomsCombineData.roomsLoadingData.last_page) {
      setScrollTriggered(false);
    }
  }, [roomsCombineData])

  const buttonOpenChat = (roomData: IRoomsModel) => {
    roomsResetData();
    roomsResetLoadingPage();

    updateSelectedRoomData(roomData);
    socketRooms.disconnect();

    navigate(RoutePaths.CHAT, {
      state: {
        roomUuid: roomData.room_uuid
      }
    });
  }

  return (
    <div>
      <TelegramBackButton
        onClick={() => {
          window.history.back();
        }}
      />
      <section className={styles.dialogs} id={styles.dialogs}>
        <div className={styles.dialogs__header}>
          {/*<input type="text" name="" id="" className={styles.dialog__search}*/}
          {/*       placeholder="Введите сообщение или слово.."></input>*/}
        </div>

        {/*{*/}
        {/*  userData.is_admin &&*/}
        {/*    <div className={styles.Dialogs__ModeratorSwitch}>*/}
        {/*        <span>Режим модератора</span>*/}
        {/*        <ToggleSwitchChats label="Subscribe"></ToggleSwitchChats>*/}
        {/*    </div>*/}
        {/*}*/}

        {
          roomsCombineData.loading && roomsCombineData.roomsData.length === 0 &&
            [0, 1].map((_, index) =>
              <div className={styles.dialog} key={index}>
                <div className={styles['dialog__photo-wrapper']}>
                  {/*<img alt="" className={styles.dialog__photo}></img>*/}
                </div>
                <div className={styles.dialog__info}>
                  <div className={styles['dialogs__header-left']}>
                    <span
                      className={styles.dialog__name}><Skeleton height={20} width={100}/></span>
                    <span className={styles['dialog__last-online-time']}><Skeleton height={20} width={180}/></span>
                  </div>
                  <div className={styles['dialog__info-right']}>
                    <time><Skeleton height={10} width={25}/></time>
                  </div>
                </div>
              </div>
            )
        }
        {
          roomsCombineData.roomsData.map((room, index) =>
            <div key={room.room_uuid} className={styles.dialog} ref={index === roomsCombineData.roomsData.length - 1 ? ref : null} onClick={() => buttonOpenChat(room)}>
              <div className={styles['dialog__photo-wrapper']}>
                {/*<img alt="" className={styles.dialog__photo}></img>*/}
              </div>
              <div className={styles.dialog__info}>
                <div className={styles['dialogs__header-left']}>
                    <span
                      className={styles.dialog__name}>{room.name}</span>
                  <span className={styles['dialog__last-online-time']}>{room.last_message}</span>
                </div>
                <div className={styles['dialog__info-right']}>
                  <time>{room.last_message_time}</time>
                </div>
              </div>
            </div>
          )
        }
      </section>
    </div>
  );
}