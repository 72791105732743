import * as React from 'react';
import Dropdown from 'react-dropdown';
import './style_custom.scss';

const options = [
  { value: 'BUY', label: 'Локаль (RU)' },
  { value: 'SELL', label: 'Локаль (EN)' },
  { value: 'REQUEST', label: 'Локаль (UA)' },
];

const defaultOption = options[0];

export function DropdownRating() {
  return (
      <div className="custom-dropdown__panel-admin-rating">
        <Dropdown options={options} value={defaultOption} placeholder="Select an option" />
      </div>
  );
}