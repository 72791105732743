import * as React from 'react';
import styles from './Verification.module.scss';
import {CustomDropdown} from "../../components/DropdownVerification/CustomDropdown";

export function VerificationPersonalInfo() {
  return (
      <section className={styles.Kyc}>
        <div className={styles.Kyc__Top}>
          <h2 className={styles.Kyc__Title}>Пройдите верификацию</h2>

          <div className={styles.Kyc__PersonalInfo}>
            <h3>Личная информация</h3>

            <div className={styles.Kyc__PersonalInfo__Items}>
              <div className={styles.Kyc__PersonalInfo__Items__Citizenship}>
                <h4>Гражданство</h4>

                <div className={styles.Kyc__PersonalInfo__Items__Citizenship__Input}>
                  <input type="text" placeholder="Страна/Регион"/>
                </div>
              </div>

              <div className={styles.Kyc__PersonalInfo__Items__FullName}>
                <h4>Полное имя</h4>

                <div className={styles.Kyc__PersonalInfo__Items__FullName__Inputs}>
                  <input type="text" placeholder="Имя"/>
                  <input type="text" placeholder="Фамилия"/>
                  <input type="text" placeholder="Отчество"/>
                </div>

                <p>Как указано в паспорте или удостоверении личности</p>
              </div>

              <div className={styles.Kyc__PersonalInfo__Items__Birthday}>
                <h4>Дата рождения</h4>

                <div className={styles.Kyc__PersonalInfo__Items__Birthday__Inputs}>
                  <input type="text" placeholder="ГГГГ"/>
                  <input type="text" placeholder="ММ"/>
                  <input type="text" placeholder="ДД"/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.Kyc__Bottom}>
          <div className={styles.Kyc__Button}>
            <button>Продолжить</button>
          </div>
        </div>
      </section>
  );
}