import React from 'react';
import styles from './NoUsername.module.scss';
import no_user from '../../images/no_username.svg';

const NoUsername = () => {
  return (
      <div className={styles.NoUsername}>
        <div className={styles.NoUsername__Top}>
          <div className={styles.NoUsername__Photo}>
            <img src={no_user} alt=""/>
          </div>

          <div className={styles.NoUsername__Text}>
            <h2>У вас нет Username</h2>

            <p>
              Чтобы установить себе <b>Username</b>, необходимо выполнить несколько простых действий: <br/><br/>
              1. Нажмите <b>"Настройки Telegram"</b>. <br/>
              2. Справа в верхнем углу нажмите <b>"Изм."</b>. <br/>
              3. Нажмите <b>"Имя пользователя"</b> и придумайте себе username.
            </p>
          </div>
        </div>

        <div className={styles.NoUsername__Bottom}>
          <div className={styles.NoUsername__Button}>
            <button>Закрыть</button>
          </div>
        </div>
      </div>
  );
};

export default NoUsername;