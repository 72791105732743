import * as React from 'react';
import styles from './Verification.module.scss';
import mail from "../../images/mail.svg";
import {useState} from "react";

export function VerificationMail() {
  const [codeSection, setCodeSection] = useState(false);

  const sectionMenu = () => {
    setCodeSection(current => !current)
  }

  return (
      <section className={styles.KycMail}>
        <div className={styles.KycPay__Title}>
          <h2>Подтвердите Вашу Эл. почту</h2>

          <div className={styles.KycPay__Items}>
            <div className={styles.KycPay__Items__Item}>
              <div className={styles.KycPay__Items__Item__Top}>
                <div className={styles.KycPay__Items__Item__Left + ' ' + styles.KycPay__Items__Item__Left__Mail}>
                  <div>
                    <img src={mail} alt=""/>
                    <h3>Почта</h3>
                  </div>

                  <p>Отправьте нам адрес Вашей электронной почты, после чего мы вышлем вам код подтверждения, чтобы мы могли идентифицировать Вашу личность</p>
                </div>
              </div>

              <div className={styles.KycPay__Items__Item__Bottom + ' ' + styles.KycPay__Items__Item__BottomMail} style={{display: !codeSection ? "block" : "none"}}>
                <input type="email" placeholder="example@gmail.com"/>
                <button onClick={sectionMenu}>Отправить</button>
              </div>

              {
                codeSection &&
                <div className={styles.KycMail__Code}>
                  <p>Мы отправили код подтверждения на Вашу эл. почту. Пожалуйста, введите его сюда</p>

                  <div className={styles.KycMail__Code__Bottom}>
                    <input type="text" placeholder="Код"/>
                    <button>Отправить</button>
                    <button onClick={sectionMenu}>Изменить почту</button>
                  </div>
                </div>
              }

            </div>
          </div>
        </div>

        <div className={styles.KycPay__Bottom}>
          <div className={styles.Kyc__Button}>
            <button>Продолжить</button>
          </div>
        </div>
      </section>
  );
}