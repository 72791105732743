import {
  $messagesData,
  $roomAdditionallyData,
  IMessagesModel,
  messagesResetData,
  messagesUpdateData,
  messagesUpdateDataEvent,
  messageUpdateReadStatus,
  messageUpdateReadStatusEvent,
  resetRoomAdditionallyData,
  updateRoomAdditionallyBroken,
  updateRoomAdditionallyBrokenEvent,
  updateRoomAdditionallyData,
  updateRoomAdditionallyDataEvent,
  updateRoomAdditionallyFixed,
  updateRoomAdditionallyFixedEvent,
  updateRoomAdditionallySuccessful, updateRoomAdditionallySuccessfulEvent
} from "./index";
import {Socket} from "socket.io-client";
import {combine, createEffect} from "effector";
import {updateSelectedRoomData} from "../Room";

export const chatGetMessagesEx = createEffect(({socket}: {socket: Socket}): Promise<IMessagesModel[]> => {
  messagesResetData();
  
  socket.emit('get_messages');
  return new Promise<IMessagesModel[]>((resolve, reject) => {
    socket.on('get_messages_response', (rooms: any) => {
      updateRoomAdditionallyData(rooms['data']['sio_room_additionally_data']);
      updateSelectedRoomData(rooms['data']['sio_rooms_data']);

      resolve(rooms['data']['sio_messages_data']);
    });
  });
})

$messagesData
  .on(chatGetMessagesEx.doneData, (_currentData, newData) => [..._currentData, ...newData])
  .on(messagesUpdateData, messagesUpdateDataEvent)
  .on(messageUpdateReadStatus, messageUpdateReadStatusEvent)
  .reset(messagesResetData);

$roomAdditionallyData
  .on(updateRoomAdditionallyData, updateRoomAdditionallyDataEvent)
  .on(updateRoomAdditionallyBroken, updateRoomAdditionallyBrokenEvent)
  .on(updateRoomAdditionallyFixed, updateRoomAdditionallyFixedEvent)
  .on(updateRoomAdditionallySuccessful, updateRoomAdditionallySuccessfulEvent)
  .reset(resetRoomAdditionallyData);

export const messagesDataCombine = combine({
  loading: chatGetMessagesEx.pending,
  messagesData: $messagesData,
  roomAdditionallyData: $roomAdditionallyData,
});
