import * as React from 'react';
import styles from './Market.module.scss';
import arrow from '../../images/arrow_circle.svg';
import arrow_left from '../../images/arrow_left.svg';
import profile from '../../images/profile_user.svg';
import plus from '../../images/plus_on_sircle.svg';
import admin from '../../images/admin.svg';
import faq from '../../images/faq.svg';
import market_logo from '../../images/market_logo.png';
import offer from "../../images/offer.svg";
import moderator from '../../images/moderator.svg';
import requester from '../../images/request.svg';
import successful_offers from '../../images/successful_offers.svg';
import {useNavigate} from "react-router-dom";
import RoutePaths from "../../routePaths";
import {$tradesDataCombine, getSuccessTradesEx} from "../../models/Trades/init";
import {useStore} from "effector-react";
import {useEffect, useState} from "react";
import {useInView} from "react-intersection-observer";
import {resetTradesData, resetTradesLoadingData, tradesLoadingUpdatePage} from "../../models/Trades";
import {roomsModeReset} from "../../models/Chat/Room";
import {$user} from "../../models/User";
import {config} from "../../config";

export function Market() {
  const navigate = useNavigate();

  const [scrollTriggered, setScrollTriggered] = useState<boolean>(false);

  const tradesDataCombine = useStore($tradesDataCombine);
  const userData = useStore($user);

  useEffect(() => {
    resetTradesData();
    resetTradesLoadingData();

    roomsModeReset();

    // getSuccessTradesEx({
    //   page: 1,
    //   offset: -1
    // }).then();
  }, []);

  const {ref, inView, entry} = useInView({
    triggerOnce: true
  });

  if (inView && !scrollTriggered && !tradesDataCombine.loadingData.last_page) {
    entry?.target.removeAttribute('ref');
    setScrollTriggered(true);

    tradesLoadingUpdatePage(tradesDataCombine.loadingData.page + 1);
    getSuccessTradesEx({
      page: tradesDataCombine.loadingData.page + 1,
      offset: tradesDataCombine.loadingData.offset
    }).then();
  }

  useEffect(() => {
    if (!tradesDataCombine.loading && !tradesDataCombine.loadingData.last_page) {
      setScrollTriggered(false);
    }
  }, [tradesDataCombine])

  const openWalletButton = () => navigate(RoutePaths.WALLET);
  const openProfileButton = () => navigate(RoutePaths.PROFILE);
  const openFaqButton = () => {
    navigate(RoutePaths.FAQ);
  }
  const openCreateAdButton = () => navigate(RoutePaths.CREATE_ADD);
  const openAdminPanelButton = () => navigate(RoutePaths.ADMIN_PANEL);
  const openMyOffersButton = (adsType: string) => {
    navigate(RoutePaths.MY_OFFERS, {
      state: {
        adsType: adsType
      }
    });
  }

  const openChatOffer = (roomUuid: string) => {
    // navigate(RoutePaths.CHAT, {state: {
    //     roomUuid: roomUuid,
    // }});
  };

  return (
    <section className={styles.Market}>
      <div className={styles.Market__Header}>
        <div className={styles.Market__Header__Logo}>
          <img src={market_logo} alt=""/>
        </div>

        <div className={styles.Market__Header__Text}>
          {/*<h2>P2P Маркет</h2>*/}
          {/*<p>Слоган</p>*/}
        </div>

        <div className={styles.Market__Header__Button} onClick={openWalletButton}>
          <img src={arrow} alt=""/>
          <h3>Купить у пользователей</h3>
        </div>
      </div>

      <div className={styles.Market__Items}>
        <div className={styles.Market__Items__Button} onClick={openProfileButton}>
          <div className={styles.Market__Items__Button__Left}>
            <div className={styles.Market__Items__Button__Logo}>
              <img src={profile} alt=""/>
            </div>

            <div className={styles.Market__Items__Button__Text}>
              <h3>Профиль продавца</h3>
              <p>Настройки объявлений и платежей</p>
            </div>
          </div>

          <div className={styles.Market__Items__Button__Arrow}>
            <img src={arrow_left} alt=""/>
          </div>
        </div>

        <div className={styles.Market__Items}>
          <div className={styles.Market__Items__Button} onClick={() => openMyOffersButton('IMPL')}>
            <div className={styles.Market__Items__Button__Left}>
              <div className={styles.Market__Items__Button__Logo}>
                <img src={offer} alt=""/>
              </div>

              <div className={styles.Market__Items__Button__Text}>
                <h3>Мои объявления</h3>
                <p>Входящие запросы</p>
              </div>
            </div>

            <div className={styles.Market__Items__Button__Arrow}>
              <img src={arrow_left} alt=""/>
            </div>
          </div>
          <div className={styles.Market__Items__Button} onClick={() => openMyOffersButton('CLIENT')}>
            <div className={styles.Market__Items__Button__Left}>
              <div className={styles.Market__Items__Button__Logo}>
                <img src={requester} alt=""/>
              </div>

              <div className={styles.Market__Items__Button__Text}>
                <h3>Мои запросы</h3>
                <p>Ваши запросы по объявлениям</p>
              </div>
            </div>

            <div className={styles.Market__Items__Button__Arrow}>
              <img src={arrow_left} alt=""/>
            </div>
          </div>

          <div className={styles.Market__Items__Button} onClick={() => openMyOffersButton('SUCCESSFUL')}>
            <div className={styles.Market__Items__Button__Left}>
              <div className={styles.Market__Items__Button__Logo}>
                <img src={successful_offers} alt=""/>
              </div>

              <div className={styles.Market__Items__Button__Text}>
                <h3>История</h3>
                <p>Ваши завершенные объявления</p>
              </div>
            </div>

            <div className={styles.Market__Items__Button__Arrow}>
              <img src={arrow_left} alt=""/>
            </div>
          </div>
        </div>

        {
          userData.is_admin &&
            <div className={styles.Market__Items} onClick={() => openMyOffersButton('MODERATION')}>
                <div className={styles.Market__Items__Button}>
                    <div className={styles.Market__Items__Button__Left}>
                        <div className={styles.Market__Items__Button__Logo}>
                            <img src={moderator} alt=""/>
                        </div>

                        <div className={styles.Market__Items__Button__Text}>
                            <h3>Модерируемые переписки</h3>
                        </div>
                    </div>

                    <div className={styles.Market__Items__Button__Arrow}>
                        <img src={arrow_left} alt=""/>
                    </div>
                </div>
            </div>
        }

        <div className={styles.Market__Items__Button} onClick={openCreateAdButton}>
          <div className={styles.Market__Items__Button__Left}>
            <div className={styles.Market__Items__Button__Logo}>
              <img src={plus} alt="" className={styles.Market__Items__Button__Logo__Plus}/>
            </div>

            <div className={styles.Market__Items__Button__Text}>
              <h3 className={styles.Market__Items__Button__Text__CreateAdd}>Создать объявление</h3>
            </div>
          </div>

          <div className={styles.Market__Items__Button__Arrow}>
            <img src={arrow_left} alt=""/>
          </div>
        </div>
      </div>

      {/*Второй стек кнопок*/}
      <div className={styles.Market__Items}>
        {/*<div className={styles.Market__Items__Button}>*/}
        {/*  <div className={styles.Market__Items__Button__Left}>*/}
        {/*    <div className={styles.Market__Items__Button__Logo}>*/}
        {/*      <img src={notifications} alt=""/>*/}
        {/*    </div>*/}

        {/*    <div className={styles.Market__Items__Button__Text}>*/}
        {/*      <h3>Уведомления</h3>*/}
        {/*    </div>*/}
        {/*  </div>*/}

        {/*  <div className={styles.Market__Items__Button__Arrow}>*/}
        {/*    <img src={arrow_left} alt=""/>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div className={styles.Market__Items__Button} onClick={openFaqButton}>
          <div className={styles.Market__Items__Button__Left}>
            <div className={styles.Market__Items__Button__Logo}>
              <img src={faq} alt=""/>
            </div>

            <div className={styles.Market__Items__Button__Text}>
              <h3>FAQ</h3>
            </div>
          </div>

          <div className={styles.Market__Items__Button__Arrow}>
            <img src={arrow_left} alt=""/>
          </div>
        </div>
      </div>

      {
        userData.is_admin &&
          <div className={styles.Market__Items}>
              <div className={styles.Market__Items__Button} onClick={openAdminPanelButton}>
                  <div className={styles.Market__Items__Button__Left}>
                      <div className={styles.Market__Items__Button__Logo}>
                          <img src={admin} alt=""/>
                      </div>

                      <div className={styles.Market__Items__Button__Text}>
                          <h3>Панель администратора</h3>
                      </div>
                  </div>

                  <div className={styles.Market__Items__Button__Arrow}>
                      <img src={arrow_left} alt=""/>
                  </div>
              </div>
          </div>
      }

      {/*<div className={styles.Market__DealsHistory}>*/}
      {/*  <h3 className={styles.Market__DealsHistory__Title}>История сделок</h3>*/}
      {/*  <div className={styles.Market__DealsHistory__Items}>*/}
      {/*    {*/}
      {/*      !tradesDataCombine.loading && tradesDataCombine.trades.length === 0 &&*/}
      {/*        <div className={styles.Market__DealsHistory__Items}>*/}
      {/*          <p>Здесь появится история ваших сделок</p>*/}
      {/*        </div>*/}
      {/*    }*/}
      {/*    {*/}
      {/*      tradesDataCombine.trades.map((trade, index) =>*/}
      {/*        <div className={styles['user-ad__transaction-item']} ref={index === tradesDataCombine.trades.length - 1 ? ref : null} key={trade.id} onClick={() => openChatOffer(trade.room_uuid)}>*/}
      {/*          <div className={styles['user-ad__transaction-item-left-wrapper']}>*/}
      {/*            <div className={styles['user-ad__transaction-item-left']}>*/}
      {/*              <img src={product} alt=""/>*/}
      {/*              <span className={styles['user-ad__transaction-item-price']}>{trade.amount}</span>*/}
      {/*            </div>*/}

      {/*            <span className={styles['user-ad__transaction-item-code']}><mark>Код: </mark>{trade.id}</span>*/}
      {/*          </div>*/}

      {/*          <div className={styles['user-ad__transaction-item-price-wrapper-div']}>*/}
      {/*            <div className={styles['user-ad__transaction-item-price-wrapper']}>*/}
      {/*              <h3>{trade.amount} {trade.currency}</h3>*/}
      {/*            </div>*/}
      {/*            <img src={arrow_left} alt=""/>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      )*/}
      {/*    }*/}
      {/*  </div>*/}
      {/*</div>*/}
    </section>
  );
}