import * as React from 'react';
import styles from './Verification.module.scss';
import selfie from "../../images/selfie_1.svg";

export function VerificationSelfie() {
  return (
      <section className={styles.KycSelfie}>
        <div className={styles.KycPay__Title}>
          <h2>Отпавьте нам видеоселфи</h2>

          <div className={styles.KycPay__Items}>
            <div className={styles.KycPay__Items__Item}>
              <div className={styles.KycPay__Items__Item__Top}>
                <div className={styles.KycPay__Items__Item__Left}>
                  <div>
                    <img src={selfie} alt=""/>
                    <h3>Видеоселфи</h3>
                  </div>

                  <p>Отправьте нам видео с Вашим лицом и информацией о дате Вашего рождения на бумажном носителе, чтобы мы могли идентифицировать Вашу личность</p>
                </div>
              </div>

              <div className={styles.KycPay__Items__Item__Bottom}>
                <div className={styles.input__wrapper}>
                  <form encType="multipart/form-data" method="post" id="image_add_form">
                    <div className={styles.field__wrapper}>
                      <input name="file" type="file" id="field__file-2" className={styles.field__file} multiple accept="image/*,image/jpeg"></input>
                      <label className={styles['field__file-wrapper']} htmlFor="field__file-2">
                        <div className={styles['field__file-button']}>Отправить</div>
                        <div className={styles['field__file-fake']}>Файл не выбран</div>
                      </label>
                    </div>
                  </form>
                </div>
              </div>

              <div className={styles.KycPay__Items__Item__Photo}>
                <img src={selfie} alt=""/>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.KycPay__Bottom}>
          <div className={styles.Kyc__Button}>
            <button>Продолжить</button>
          </div>
        </div>

      </section>
  );
}