import {createEvent, createStore} from "effector";

export interface IUserProfileModel {
  username: string;
  rating: number;
  role: number;
  balance: number;
  account_status: string;

  username_history: IUsernameHistoryResponseModel[];
  ip_history: IIpHistoryResponseModel[];
}

export interface IUsernameHistoryResponseModel {
  id: number;
  last_username: string;
  new_username: string;
  created_at: string;
}

export interface IIpHistoryResponseModel {
  id: number;
  address: string;
  city: string;
  created_at: string;
}

export const updateAdminUserProfileData = createEvent<IUserProfileModel | null>();
export const updateAdminUserProfileDataEvent = (state: IUserProfileModel | null, data: IUserProfileModel | null) => data;
export const resetAdminUserProfileData = createEvent();

export const $adminUserProfileData = createStore<IUserProfileModel | null>(null);