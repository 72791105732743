import {createEvent, createStore} from "effector";

export interface ITradeResponseModel {
  id: number;
  room_uuid: string;
  currency: string;
  amount: number;
}

export interface ITradeLoadModel {
  last_page: boolean;
  offset: number;
  page: number;
}

export const updateTradesData = createEvent<ITradeResponseModel[]>();
export const updateTradesDataEvent = (state: ITradeResponseModel[], data: ITradeResponseModel[]) => [...state, ...data];

export const tradesLoadingUpdate = createEvent<ITradeLoadModel>();
export const tradesLoadingUpdateEvent = (state: ITradeLoadModel, data: ITradeLoadModel) => data;

export const tradesLoadingUpdatePage = createEvent<number>();
export const tradesLoadingUpdatePageEvent = (state: ITradeLoadModel, data: number) => {
  const nextState = {...state};
  nextState.page = data;
  return nextState;
};

export const resetTradesData = createEvent();
export const resetTradesLoadingData = createEvent();

export const $tradesData = createStore<ITradeResponseModel[]>([]);
export const $tradesLoadingData = createStore<ITradeLoadModel>({last_page: true, offset: -1, page: 1});