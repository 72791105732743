import * as React from 'react';
import styles from './Add.module.scss';
import logo from '../../images/example_logo.svg';
import {$offerModalData} from "../../models/Offers/OfferModalData";
import {useStore} from "effector-react";
import {API} from "../../api";
import {updateSnackbarStatus} from "../../models/Snackbar";
import {$user} from "../../models/User";
import cross from '../../images/cross.svg';
import {roomsResetData, roomsResetLoadingPage} from "../../models/Chat/Room";
import {socketRooms} from "../../api/socket";
import RoutePaths from "../../routePaths";
import {useNavigate} from "react-router-dom";

export function AddDetail(props: {setActive: (v: boolean) => void}) {
  const offerModalData = useStore($offerModalData);
  const userData = useStore($user);

  const navigate = useNavigate();

  const createTradeButton = async () => {
    const response = await API.Trade.createTrade(offerModalData.uuid, offerModalData.author_uuid, userData.user_uuid, offerModalData.amount);

    if (response.data['status'] === 'OK') {
      socketRooms.disconnect();
      updateSnackbarStatus({
        title: "Запрос на создание успешно отправлен!",
        status: "success",
        visible: true
      });
      navigate(RoutePaths.CHAT, {
        state: {
          roomUuid: response.data['room_uuid']
        }
      });
    } else if (response.data['status'] === 'UniqueViolationError') {
      roomsResetData();
      roomsResetLoadingPage();

      socketRooms.disconnect();

      updateSnackbarStatus({
        title: "Вы уже откликнулись на данное исполнение!",
        status: "error",
        visible: true
      });

      navigate(RoutePaths.CHAT, {
        state: {
          roomUuid: response.data['roomUuid']
        }
      });
    } else if (response.data['status'] === 'Error(write yourself)!') {
      updateSnackbarStatus({
        title: "Нельзя выполнить interaction с самим собой!",
        status: "error",
        visible: true
      });
    } else if (response.data['status'] === 'You deal only prof') {
      updateSnackbarStatus({
        title: "Вы можете проводить interaction только с профами!",
        status: "error",
        visible: true
      });
    } else if (response.data['status'] === 'The deal only prof') {
      updateSnackbarStatus({
        title: "Данное объявление доступно только для профов!",
        status: "error",
        visible: true
      });
    }
  };

  return (
      <section className={styles.AddDetail}>
        <div className={styles.AddDetail__Top}>
          <div className={styles.AddDetail__Header}>
            <div className={styles.AddDetail__Header__Top}>

              {/*<div className={styles.AddDetail__Header__Top__Middle}>*/}
              {/*  <img src={logo} alt=""/>*/}
              {/*  <span>Вы покупаете у <strong>продавца</strong></span>*/}
              {/*</div>*/}

              <div className={styles.AddDetail__Header__Top__Right}>
                <img src={cross} alt="" onClick={() => props.setActive(false)}/>
              </div>
            </div>

            <div className={styles.AddDetail__Header__Bottom}>
              <span>Имя скрыто</span>
            </div>
          </div>

          <div className={styles.AddDetail__Items}>
            <div className={styles.AddDetail__Items__Item}>
              <h3>Тип объявления</h3>
              <span>{offerModalData.type_offer}</span>
            </div>

            <div className={styles.AddDetail__Items__Item}>
              <h3>Город</h3>
              <span>{offerModalData.city}</span>
            </div>

            <div className={styles.AddDetail__Items__Item}>
              <h3>Сумма</h3>
              <span>{offerModalData.amount}</span>
            </div>

            <div className={styles.AddDetail__Items__Item}>
              <h3>Валюта</h3>
              <div className={styles.AddDetail__Items__Item__PayMethods}>
                <span>{offerModalData.currency}</span>
              </div>
            </div>
          </div>

          <div className={styles.AddDetail__Items}>
            <h2>Данные продавца</h2>

            {/*<div className={styles.AddDetail__Items__Item}>*/}
            {/*  <h3>Имя продавца</h3>*/}
            {/*  <span>{offerModalData.fake_name}</span>*/}
            {/*</div>*/}

            <div className={styles.AddDetail__Items__Item}>
              <h3>Статус продавца</h3>
              <span>{offerModalData.seller_prof}</span>
            </div>

            <div className={styles.AddDetail__Items__Item}>
              <h3>Объявление для</h3>
              <span>{offerModalData.only_prof}</span>
            </div>
          </div>

          <div className={styles.AddDetail__Items + ' ' + styles.AddDetail__Items__Description}>
            <h2>Дополнительная информация</h2>

            <div className={styles.AddDetail__Items__Item}>
              <p>
                {offerModalData.additionally}
              </p>
            </div>
          </div>
        </div>

        <div className={styles.AddDetail__Bottom}>
          <div className={styles.AddDetail__Button}>
            <button onClick={createTradeButton}>Отправить запрос</button>
          </div>
        </div>
      </section>
  );
}