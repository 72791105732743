import {
  $redirectStatus,
  resetRedirectPageStatus,
  updateRedirectPageStatus,
  updateRedirectPageStatusEvent
} from "./index";
import {persist} from "effector-storage/local";

$redirectStatus
  .on(updateRedirectPageStatus, updateRedirectPageStatusEvent)
  .reset(resetRedirectPageStatus);

persist({store: $redirectStatus, key: 'redirectStatusPage'});