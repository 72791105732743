import * as React from 'react';
import styles from './Settings.module.scss';
import settings from '../../images/settings.svg';
import RoutePaths from "../../routePaths";
import {useNavigate} from "react-router-dom";
import {TelegramBackButton} from "../../components/BackButton/BackButton";
import {useEffect, useRef} from "react";
import {$adminSettingsStore, updateAdminSettingsData} from "../../models/Admin/Settings";
import {useStore} from "effector-react";
import {getAdminSettingsDataEx} from "../../models/Admin/Settings/init";
import {API} from "../../api";
import {updateSnackbarStatus} from "../../models/Snackbar";

export function Settings() {
  const adminSettingsStore = useStore($adminSettingsStore);

  const offerPriceRef = useRef<HTMLInputElement>(null);
  const deleteSuccessfulOffer = useRef<HTMLInputElement>(null);
  const deleteInactiveOffer = useRef<HTMLInputElement>(null);
  const initialBalanceRef = useRef<HTMLInputElement>(null);
  const subMouthPriceRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    getAdminSettingsDataEx().then();
  }, []);

  useEffect(() => {

  }, [adminSettingsStore]);

  const openAdminPanelButton = () => navigate(RoutePaths.ADMIN_USERS_LIST);
  const openAdminPanelGroupButton = () => navigate(RoutePaths.ADMIN_PANEL_GROUP);

  const numberInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const eventCode = event.code.toLowerCase();

    if (!/[0-9]/.test(event.key) && eventCode !== 'backspace') {
      event.preventDefault();
    }
  };

  const inputChange = async () => {
    if (!offerPriceRef.current || isNaN(Number(offerPriceRef.current.value))
      || !deleteSuccessfulOffer.current || isNaN(Number(deleteSuccessfulOffer.current.value))
      || !deleteInactiveOffer.current || isNaN(Number(deleteInactiveOffer.current.value))
      || !initialBalanceRef.current || isNaN(Number(initialBalanceRef.current.value))
      || !subMouthPriceRef.current || isNaN(Number(subMouthPriceRef.current.value))) return;

    updateAdminSettingsData({
      offer_price: Number(offerPriceRef.current.value),
      delete_successful_offers: Number(deleteSuccessfulOffer.current.value),
      delete_inactive_offer: Number(deleteInactiveOffer.current.value),
      initial_balance: Number(initialBalanceRef.current.value),
      sub_mouth_price: Number(subMouthPriceRef.current.value),
    });
  };

  const saveSettingsButton = async () => {
    if (!offerPriceRef.current || isNaN(Number(offerPriceRef.current.value))
      || !deleteSuccessfulOffer.current || isNaN(Number(deleteSuccessfulOffer.current.value))
      || !deleteInactiveOffer.current || isNaN(Number(deleteInactiveOffer.current.value))
      || !initialBalanceRef.current || isNaN(Number(initialBalanceRef.current.value))
      || !subMouthPriceRef.current || isNaN(Number(subMouthPriceRef.current.value))) return;

    const response = await API.Admin.saveExchangerSettings(adminSettingsStore);
    if (response.data['status'] === 'OK') {
      updateSnackbarStatus({
        title: "Настройки успешно обновлены!",
        status: "success",
        visible: true
      });
      return;
    }
  };

  return (
      <section className={styles.Settings}>
        <TelegramBackButton
          onClick={() => {
            window.history.back();
          }}
        />
        <div className={styles.Settings__Header}>
          <h2 className={styles.Settings__Title}>Опции приложения</h2>
          <img src={settings} alt=""/>
        </div>

        <div className={styles.Settings__Items}>
          <div className={styles.Settings__Item}>
            <h3 className={styles.Settings__Item__Title}>
              Стоимость одного объявления
            </h3>

            <div className={styles.Settings__Item__Right}>
              <input ref={offerPriceRef} type="text" onChange={inputChange} value={adminSettingsStore.offer_price} onKeyDown={numberInputKeyDown}/>
              <span>USDT</span>
            </div>
          </div>

          <div className={styles.Settings__Item}>
            <h3 className={styles.Settings__Item__Title}>
              Удалять успешный interaction, через
            </h3>

            <div className={styles.Settings__Item__Right}>
              <input ref={deleteSuccessfulOffer} type="text" onChange={inputChange} value={adminSettingsStore.delete_successful_offers} onKeyDown={numberInputKeyDown}/>
              <span>дн</span>
            </div>
          </div>

          <div className={styles.Settings__Item}>
            <h3 className={styles.Settings__Item__Title}>
              Удалять неактивное объявл, через
            </h3>

            <div className={styles.Settings__Item__Right}>
              <input ref={deleteInactiveOffer} type="text" onChange={inputChange} value={adminSettingsStore.delete_inactive_offer} onKeyDown={numberInputKeyDown}/>
              <span>дн</span>
            </div>
          </div>

          <div className={styles.Settings__Item}>
            <h3 className={styles.Settings__Item__Title}>
              Начальный баланс
            </h3>

            <div className={styles.Settings__Item__Right}>
              <input ref={initialBalanceRef} type="text" onChange={inputChange} value={adminSettingsStore.initial_balance} onKeyDown={numberInputKeyDown}/>
              <span>USDT</span>
            </div>
          </div>

          <div className={styles.Settings__Item}>
            <h3 className={styles.Settings__Item__Title}>
              Стоимость подписки за месяц
            </h3>

            <div className={styles.Settings__Item__Right}>
              <input ref={subMouthPriceRef} type="text" onChange={inputChange} value={adminSettingsStore.sub_mouth_price} onKeyDown={numberInputKeyDown}/>
              <span>USDT</span>
            </div>
          </div>

          <div className={styles.Settings__Item + " " + styles.Settings__Item__Control}>
            <button onClick={openAdminPanelGroupButton}>Управление группами</button>
            <button onClick={openAdminPanelButton}>Управление пользователями</button>
          </div>
        </div>

        <div className={styles.Settings__Buttons}>
          <button className={styles.Settings__Buttons__Save} onClick={saveSettingsButton}>Сохранить</button>
          <button className={styles.Settings__Buttons__Close}>Закрыть</button>
        </div>
      </section>
  );
}