import {
  $errorRequestFields,
  $tradeType, resetErrorRequestFields, updateErrorRequestAddressField, updateErrorRequestAddressFieldEvent,
  updateErrorRequestAmountField,
  updateErrorRequestAmountFieldEvent,
  updateErrorRequestBetField,
  updateErrorRequestBetFieldEvent, updateErrorRequestCityField, updateErrorRequestCityFieldEvent,
  updateErrorRequestFields,
  updateErrorRequestFieldsEvent,
  updateTradeType,
  updateTradeTypeEvent
} from "./index";
import {sample} from "effector";

$tradeType
  .on(updateTradeType, updateTradeTypeEvent);

$errorRequestFields
  .on(updateErrorRequestFields, updateErrorRequestFieldsEvent)
  .on(updateErrorRequestAmountField, updateErrorRequestAmountFieldEvent)
  .on(updateErrorRequestBetField, updateErrorRequestBetFieldEvent)
  .on(updateErrorRequestCityField, updateErrorRequestCityFieldEvent)
  .on(updateErrorRequestAddressField, updateErrorRequestAddressFieldEvent)
  .reset(resetErrorRequestFields);

sample({
  clock: updateTradeType,
  target: resetErrorRequestFields
});