import {io} from "socket.io-client";
import {config} from "../config";

export const socketRooms = io(config.chatSocketHost + '/rooms', {
  auth: {
    webapp_init_data: window.Telegram.WebApp.initData
  }
});

socketRooms.on('disconnect', function (reason, description) {
  if (reason !== 'io client disconnect') console.debug(reason, description);
})

socketRooms.on('connect_error', function (reason) {
  console.debug(reason.message)
})