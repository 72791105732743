import * as React from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import styles from './Search.module.scss';

export function Search() {
  const defaultState = {
    center: [55.751574, 37.573856],
    zoom: 5,
  };

  return (
    <section className={styles.Search}>
      <div className={styles.Search__Map}>
        <YMaps>
          <Map defaultState={defaultState} width="100%" height="auto">
            <Placemark geometry={[55.684758, 37.738521]} />
          </Map>
        </YMaps>
      </div>
    </section>
  );
}