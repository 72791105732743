import * as React from 'react';
import Dropdown from 'react-dropdown';
import './style_custom.scss';

const options = [
  { value: 'USDT(TRC20)', label: 'USDT(TRC20)' },
  { value: 'USDT(ERC20)', label: 'USDT(ERC20)' },
  { value: 'BTC', label: 'BTC' },
  { value: 'ETH', label: 'ETH' },
  { value: 'BCH', label: 'BCH' },
];

const defaultOption = options[0];

export function WalletDropdown(props: {walletRef: React.RefObject<Dropdown>}) {
  return (
      <div className="custom-dropdown__wallet">
        <Dropdown ref={props.walletRef} options={options} value={defaultOption} placeholder="Select an option" />
      </div>
  );
}