import * as React from 'react';
import Dropdown from 'react-dropdown';
import './style_custom.scss';

const options = [
  { value: 'BUY', label: 'Куплю' },
  { value: 'SELL', label: 'Продам' },
  { value: 'REQUEST', label: 'Объявление' },
];

const defaultOption = options[0];

export function DropdownDealing(props: {dealingRef: React.RefObject<Dropdown>}) {
  return (
      <div className="custom-dropdown__dealing-type">
        <Dropdown ref={props.dealingRef} options={options} value={defaultOption} placeholder="Select an option" />
      </div>
  );
}