import React, {useEffect, useRef, useState} from 'react';
import styles from './AdminPanelUsers.module.scss';
import edit from '../../images/edit.svg';
import {$userListCombine, getAllUsersListEx, getSearchUserInListEx} from "../../models/Admin/GetUsers/init";
import {useStore} from "effector-react";
import {$usersData, $usersLoadingData, resetUsersData, usersLoadingUpdatePage} from "../../models/Admin/GetUsers";
import {useInView} from "react-intersection-observer";
import {TelegramBackButton} from "../../components/BackButton/BackButton";
import {useNavigate} from "react-router-dom";
import RoutePaths from "../../routePaths";

const AdminPanelUsers = () => {
  const navigate = useNavigate();

  const userListCombine = useStore($userListCombine)
  const usersLoadingData = useStore($usersLoadingData);

  const [scrollTriggered, setScrollTriggered] = useState<boolean>(false);

  const usernameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    resetUsersData();

    getAllUsersListEx({
      page: 1,
      offset: -1
    }).then();
  }, []);

  const {ref, inView, entry} = useInView({
    triggerOnce: true
  });

  if (inView && !scrollTriggered && !usersLoadingData.last_page && !userListCombine.loading) {
    entry?.target.removeAttribute('ref');
    setScrollTriggered(true);

    usersLoadingUpdatePage(usersLoadingData.page + 1);
    getAllUsersListEx({
      page: usersLoadingData.page + 1,
      offset: usersLoadingData.offset
    }).then();
  }

  useEffect(() => {
    if (!userListCombine.loading && !usersLoadingData.last_page) {
      setScrollTriggered(false);
    }
  }, [usersLoadingData, userListCombine])

  const openUserProfileButton = (userId: number) => {
    navigate(RoutePaths.ADMIN_GET_USER_PROFILE, {
      state: {
        userId: userId
      }
    });
  };

  const searchUserProfile = async () => {
    resetUsersData();
    if (!usernameRef.current || usernameRef.current.value === '') {
      await getAllUsersListEx({
        page: 1,
        offset: -1
      }).then();
      return;
    }

    await getSearchUserInListEx({username: usernameRef.current.value});
  }

  return (
      <section className={styles.AdminPanelRooms}>
        <TelegramBackButton
          onClick={() => {
            window.history.back();
          }}
        />
        <div className={styles.AdminPanelRooms__Header}>
          <input ref={usernameRef} type="search" name="" id="" placeholder="Введите имя пользователя.."/>
          <button onClick={searchUserProfile}></button>
        </div>

        {/*<div className={styles.AdminPanelRooms__Pagination}>*/}
        {/*  <div className={styles.AdminPanelRooms__Pagination__Buttons}>*/}
        {/*    <button className={styles.AdminPanelRooms__Pagination__Button__DoubleLeft}></button>*/}
        {/*    <button className={styles.AdminPanelRooms__Pagination__Button__OnceLeft}></button>*/}
        {/*    <button>1</button>*/}
        {/*    <button>2</button>*/}
        {/*    <button>3</button>*/}
        {/*    <button className={styles.AdminPanelRooms__Pagination__Button__OnceRight}></button>*/}
        {/*    <button className={styles.AdminPanelRooms__Pagination__Button__DoubleRight}></button>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div className={styles.AdminPanelRooms__Main}>
          <div className={styles.AdminPanelRooms__Main__Top}>
            <h3>Username</h3>
            <h3>Статус</h3>
            <h3>Рейтинг</h3>
          </div>

          <div className={styles.AdminPanelRooms__Main__Bottom}>
            {
              userListCombine.usersList.map((user, index) =>
                <div className={styles.AdminPanelRooms__Main__Bottom__Item} key={user.id} ref={index === userListCombine.usersList.length - 1 ? ref : null}>
                  <div className={styles.AdminPanelRooms__Main__Bottom__Item__Content}>
                    <span>{user.username}</span>
                  </div>

                  <div className={styles.AdminPanelRooms__Main__Bottom__Item__Content}>
                    <span>{user.status}</span>
                  </div>

                  <div className={styles.AdminPanelRooms__Main__Bottom__Item__Content}>
                    <span>{user.rating}</span>

                    <div className={styles.AdminPanelRooms__Edit} onClick={() => openUserProfileButton(user.id)}>
                      <img src={edit} alt=""/>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </section>
  );
};

export default AdminPanelUsers;