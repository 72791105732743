enum RoutePaths {
  WALLET = '/wallet',
  SEARCH = '/search',
  SETTINGS = '/settings',
  CREATE_ADD = '/createAdd',
  MARKET = '/market',
  PROFILE = '/profile',
  VERIFICATION_PERSONAL = '/verificationPersonal',
  VERIFICATION_CONFIRM = '/verificationConfirm',
  VERIFICATION_DOCUMENTS = '/verificationDocuments',
  VERIFICATION_PAYMENT = '/verificationPayment',
  VERIFICATION_SELFIE = '/verificationSelfie',
  VERIFICATION_MAIL = '/verificationMail',
  ADD = '/add',
  ADD_DETAIL = '/addDetail',
  CHAT = '/chat',
  ROOMS = '/rooms',
  INFO_PAGE = '/infoPage',
  VIEW_INFO_PAGE = '/view-info-page',
  REDIRECT_PAGE = '/redirect',
  ADD_REDIRECT = '/addRedirect',
  ADMIN_PANEL = '/adminPanel',
  ADMIN_USERS_LIST = '/admin-get-users-list',
  ADMIN_GET_USER_PROFILE = '/admin-get-user-profile',
  NO_USERNAME_ERROR = '/no-username-error',
  BLOCKED_USER_PAGE = '/blockedUser',
  MY_OFFERS = '/my-offers',
  ADMIN_PANEL_GROUP = '/admin-group-panel',
  FAQ = '/faq',
  OPERATION_UNAVAIlBlE = '/operationUnavailble',
  ERROR_PAGE = '/error'
}

export default RoutePaths;
