import {
  $offersData,
  $offersLoadingData,
  IOfferResponseModel,
  IOffersFilterParameters, offersDeleteItem, offersDeleteItemEvent,
  offersLoadingUpdate,
  offersLoadingUpdateEvent,
  offersLoadingUpdatePage,
  offersLoadingUpdatePageEvent, resetOffersData,
  resetOffersLoadingUpdate,
  updateOffersData,
  updateOffersDataEvent
} from "./index";
import {combine, createEffect, sample} from "effector";
import {API} from "../../../api";

export const getAllOffersEx = createEffect(async ({page, offset}: {page: number, offset: number}): Promise<IOfferResponseModel[]> => {
  const response = await API.Offer.getAllOffers(page, offset);
  offersLoadingUpdate(response.data['data']['loading_data']);

  return response.data['data']['offers_data'];
});

export const getFilteredOffersEx = createEffect(async ({page, offset, params}: {page: number, offset: number, params: IOffersFilterParameters}): Promise<IOfferResponseModel[]> => {
  const response = await API.Offer.getFilteredOffers(page, offset, params);
  console.log(response.data['data']['loading_data'])
  offersLoadingUpdate(response.data['data']['loading_data']);

  return response.data['data']['offers_data'];
});

export const getUserOffersEx = createEffect(async ({page, offset}: {page: number, offset: number}): Promise<IOfferResponseModel[]> => {
  const response = await API.Offer.getUserOffers(page, offset);
  offersLoadingUpdate(response.data['data']['loading_data']);

  return response.data['data']['offers_data'];
});

export const getClientOffersEx = createEffect(async ({page, offset}: {page: number, offset: number}): Promise<IOfferResponseModel[]> => {
  const response = await API.Offer.getClientOffers(page, offset);
  offersLoadingUpdate(response.data['data']['loading_data']);

  return response.data['data']['offers_data'];
});

export const getModerationOffersEx = createEffect(async ({page, offset}: {page: number, offset: number}): Promise<IOfferResponseModel[]> => {
  const response = await API.Offer.getModerationOffers(page, offset);
  offersLoadingUpdate(response.data['data']['loading_data']);

  return response.data['data']['offers_data'];
});

export const getSuccessfulOffersEx = createEffect(async ({page, offset}: {page: number, offset: number}): Promise<IOfferResponseModel[]> => {
  const response = await API.Offer.getSuccessfulOffers(page, offset);
  offersLoadingUpdate(response.data['data']['loading_data']);

  return response.data['data']['offers_data'];
});

$offersData
  .on(getAllOffersEx.doneData, (_currentData, _newData) => [..._currentData, ..._newData])
  .on(getFilteredOffersEx.doneData, (_currentData, _newData) => [..._currentData, ..._newData])
  .on(getUserOffersEx.doneData, (_currentData, _newData) => [..._currentData, ..._newData])
  .on(getClientOffersEx.doneData, (_currentData, _newData) => [..._currentData, ..._newData])
  .on(getModerationOffersEx.doneData, (_currentData, _newData) => [..._currentData, ..._newData])
  .on(getSuccessfulOffersEx.doneData, (_currentData, _newData) => [..._currentData, ..._newData])
  .on(updateOffersData, updateOffersDataEvent)
  .on(offersDeleteItem, offersDeleteItemEvent)
  .reset(resetOffersData);

$offersLoadingData
  .on(offersLoadingUpdate, offersLoadingUpdateEvent)
  .on(offersLoadingUpdatePage, offersLoadingUpdatePageEvent)
  .reset(resetOffersLoadingUpdate);

sample({
  clock: resetOffersData,
  target: resetOffersLoadingUpdate
});

export const $offersDataCombine = combine({
  loading: getAllOffersEx.pending,
  loadingFiltered: getFilteredOffersEx.pending,
  userOffersLoading: getUserOffersEx.pending,
  clientOffersLoading: getClientOffersEx.pending,
  moderationOffersLoading: getModerationOffersEx.pending,
  successfulOffersLoading: getSuccessfulOffersEx.pending,
  loadingData: $offersLoadingData,
  offers: $offersData,
});