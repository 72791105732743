import * as React from 'react';
import styles from './Error.module.scss';
import sad_smile from '../../images/sad_smile.svg';
import {useLocation} from "react-router-dom";


export function Error() {
  const {state} = useLocation();
  const {code, title, description} = state;

  return (
    <div>
      <div className={styles['error-page']}>

        <div className={styles['error-page__wrapper']}>
          <div className={styles['error-page__image']}>
            <img src={sad_smile} alt=""/>
          </div>

          <div className={styles['error-page__text']}>
            <span className={styles['error-page__code']}>{code}</span>
            <h1 className={styles['error-page__title']}>{title}</h1>
            <p className={styles['error-page__description']}>{description}</p>
          </div>
        </div>

      </div>
    </div>
  );
}