import * as React from 'react';
import Dropdown from 'react-dropdown';
import './style_custom.scss';

const options = [
  { value: '0', label: 'Юзер' },
  { value: '1', label: 'Проф' },
  { value: '2', label: 'Заблокирован' },
  { value: '3', label: 'Админ' },
];

export function DropdownStatusRole(props: {defaultOption: number, roleDropDownRef: React.RefObject<Dropdown>}) {
  return (
      <div className="custom-dropdown__panel-admin-role">
        <Dropdown ref={props.roleDropDownRef} options={options} value={options[props.defaultOption]} placeholder="Select an option" />
      </div>
  );
}