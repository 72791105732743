import { FC, useEffect } from 'react';

interface BackButtonProps {
  onClick?: VoidFunction;
}

const backButton = window.Telegram.WebApp.BackButton;

let isButtonShown = false;

export const TelegramBackButton: FC<BackButtonProps> = ({
  onClick = () => {
    window.history.back();
  },
}) => {
  useEffect(() => {
    backButton.show();
    isButtonShown = true;
    return () => {
      isButtonShown = false;
      setTimeout(() => {
        if (!isButtonShown) {
          backButton.hide();
        }
      }, 10);
    };
  }, []);

  useEffect(() => {
    window.Telegram.WebApp.onEvent('backButtonClicked', onClick);
    return () => {
      window.Telegram.WebApp.offEvent('backButtonClicked', onClick);
    };
  }, [onClick]);

  return null;
};
