import {combine, createEvent, createStore} from "effector";

export interface IUsersModel {
  id: number;
  username: string;
  status: string;
  rating: number;
}

export interface IUsersLoadModel {
  last_page: boolean;
  offset: number;
  page: number;
}

export const updateUsersData = createEvent<IUsersModel[]>();
export const updateUsersDataEvent = (state: IUsersModel[], data: IUsersModel[]) => [...state, ...data];
export const resetUsersData = createEvent();

export const usersLoadingUpdate = createEvent<IUsersLoadModel>();
export const usersLoadingUpdateEvent = (state: IUsersLoadModel, data: IUsersLoadModel) => data;
export const resetUsersLoadingUpdate = createEvent();

export const usersLoadingUpdatePage = createEvent<number>();
export const usersLoadingUpdatePageEvent = (state: IUsersLoadModel, data: number) => {
  const nextState = {...state};
  nextState.page = data;
  return nextState;
};

export const $usersData = createStore<IUsersModel[]>([]);
export const $usersLoadingData = createStore<IUsersLoadModel>({last_page: true, offset: 0, page: 0});
