import {createEvent, createStore} from "effector";

export interface IUserModel {
  user_uuid: string;
  fake_name: string;
  rating: number;
  is_admin: boolean;
  rules_signed: boolean;
  lang_code: string;
  auth: boolean;
  successful_trades: number;
  is_blocked: boolean;
  is_prof: boolean;
}

export const updateUserData = createEvent<IUserModel>();
export const updateUserAuth = createEvent<boolean>();
export const updateUserRulesSigned = createEvent<boolean>();

export const updateUserDataEvent = (state: IUserModel, data: IUserModel) => data;

export const updateUserAuthEvent = (state: IUserModel, data: boolean) => {
  const nextState = {...state};
  nextState.auth = data;
  return nextState;
};

export const updateUserRulesSignedEvent = (state: IUserModel, data: boolean) => {
  const nextState = {...state};
  nextState.rules_signed = data;
  return nextState;
};

export const $user = createStore<IUserModel>({
  is_prof: false,
  is_blocked: false,
  successful_trades: 0,
  auth: false,
  fake_name: "",
  is_admin: false,
  lang_code: "",
  rating: 0,
  rules_signed: true,
  user_uuid: ""
});