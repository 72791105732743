import {createEvent, createStore} from "effector";

export interface IAdminSettingsModel {
  offer_price: number;
  delete_successful_offers: number;
  delete_inactive_offer: number;
  initial_balance: number;
  sub_mouth_price: number;
}

export const updateAdminSettingsData = createEvent<IAdminSettingsModel>();
export const updateAdminSettingsDataEvent = (state: IAdminSettingsModel, data: IAdminSettingsModel) => data;
export const resetAdminSettingsData = createEvent();

export const $adminSettingsStore = createStore<IAdminSettingsModel>({
  sub_mouth_price: 0,
  initial_balance: 0,
  delete_inactive_offer: 0,
  delete_successful_offers: 0,
  offer_price: 0
});