import {config} from "../../config";
import {ApiPaths} from "../../apiPaths";
import axios from "axios";


export class TradeApi {
  public async createTrade(uuid: string, author_uuid: string, user_uuid: string, amount: number) {
    return await axios.post(config.apiHost + ApiPaths.CREATE_TRADE, {
      "event": 'CreateNewTrade',
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData,
          "uuid": user_uuid
        },
        "offer_data": {
          "uuid": uuid,
          "amount": amount,
          "author_uuid": author_uuid
        },
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    });
  }
  public async fixTrade(roomUuid: string, tradeUuid: string) {
    return await axios.post(config.apiHost + ApiPaths.FIX_TRADE, {
      "event": 'FixTrade',
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData,
        },
        "trade_data": {
          "room_uuid": roomUuid,
          "trade_uuid": tradeUuid,
        },
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    });
  }
  public async confirmTrade(roomUuid: string, tradeUuid: string) {
    return await axios.post(config.apiHost + ApiPaths.CONFIRM_TRADE, {
      "event": 'confirmTrade',
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData,
        },
        "trade_data": {
          "room_uuid": roomUuid,
          "trade_uuid": tradeUuid,
        },
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    });
  }
  public async cancelTrade(roomUuid: string, tradeUuid: string) {
    return await axios.post(config.apiHost + ApiPaths.CANCEL_TRADE, {
      "event": 'cancelTrade',
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData,
        },
        "trade_data": {
          "room_uuid": roomUuid,
          "trade_uuid": tradeUuid,
        },
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    });
  }
  public async callModerator(tradeUuid: string, roomUuid: string) {
    return await axios.post(config.apiHost + ApiPaths.CALL_MODERATOR, {
      "event": 'cancelTrade',
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData,
        },
        "trade_data": {
          "trade_uuid": tradeUuid,
          "room_uuid": roomUuid,
        },
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    });
  }
  public async getSuccessTrades(page: number, offset: number) {
    return await axios.post(config.apiHost + ApiPaths.GET_SUCCESS_TRADES, {
      "event": 'getSuccessTrades',
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData,
        },
        "loading_data": {
          "page": page,
          "offset": offset
        }
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    });
  }
}