import axios from "axios";
import {config} from "../../config";
import {ApiPaths} from "../../apiPaths";

export class UserApi {
  public async auth(uuid: string, amount: number) {
    return await axios.post(config.apiHost + ApiPaths.USER_AUTH, {
      "event": 'RequestUserAuth',
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData
        },
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    });
  }
  public async updateRulesSigned() {
    return await axios.post(config.apiHost + ApiPaths.UPDATE_RULES_SIGNED, {
      "event": 'updateRulesSigned',
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData
        },
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    });
  }
}