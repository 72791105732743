import styles from './Wallet.module.scss';
import logo from '../../images/example_logo.svg';
import {WalletDropdown} from "../../components/DropdownWallet/CustomDropdown";
import React, {useEffect, useRef, useState} from "react";
import {useStore} from "effector-react";
import {$offersDataCombine, getAllOffersEx, getFilteredOffersEx} from "../../models/Offers/GetOffers/init";
import {useInView} from "react-intersection-observer";
import {
  IOfferResponseModel,
  offersLoadingUpdatePage,
  resetOffersData,
  resetOffersLoadingUpdate
} from "../../models/Offers/GetOffers";
import {DropdownDealing} from "../../components/DropdownDealingType/CustomDropdown";
import ModalAdd from "../../components/ModalAdd/ModalAdd";
import {AddDetail} from "../Add/Add_Detail";
import {updateSnackbarStatus} from "../../models/Snackbar";
import ReactDropdown from "react-dropdown";
import {updateModalData} from "../../models/Offers/OfferModalData";
import {TelegramBackButton} from "../../components/BackButton/BackButton";
import Skeleton from "react-loading-skeleton";
import copy from "copy-to-clipboard";
import {$user} from "../../models/User";
import ModalWallet from "../../components/ModalWallet/ModalWallet";
import {API} from "../../api";

interface IDropDownState {
  selected: ISelected;
  isOpen: boolean;
}

interface ISelected {
  label: string;
  value: string;
}

export function Wallet() {
  const [isLoadInFilters, setIsLoadInFilters] = useState<boolean>(false);
  const [scrollTriggered, setScrollTriggered] = useState<boolean>(false);
  const [modalActive, setModalActive] = useState<boolean>(false);
  const [modalDeleteOfferActive, setModalDeleteOfferActive] = useState<boolean>(false);
  const [adminSelectedOffer, setAdminSelectedOffer] = useState<{offer_id: number, offer_uuid: string}>({offer_id: -1, offer_uuid: ''});

  const [adminDeleteOfferConfirm, setAdminDeleteOfferConfirm] = useState<boolean>(false);
  const [modalConfirmDeleteOfferActive, setModalConfirmDeleteOfferActive] = useState<boolean>(false);

  const offersDataCombine = useStore($offersDataCombine);
  const getUserData = useStore($user);

  const filterWalletRef = useRef<ReactDropdown>(null);
  const filterDealingRef = useRef<ReactDropdown>(null);

  useEffect(() => {
    resetOffersData();
    resetOffersLoadingUpdate();

    getAllOffersEx({
      page: 1,
      offset: -1
    }).then();
  }, []);

  const {ref, inView, entry} = useInView({
    triggerOnce: true
  });

  if (inView && !scrollTriggered && !offersDataCombine.loadingData.last_page && !offersDataCombine.loading && !offersDataCombine.loadingFiltered) {
    entry?.target.removeAttribute('ref');
    setScrollTriggered(true);

    offersLoadingUpdatePage(offersDataCombine.loadingData.page + 1);
    if (isLoadInFilters) {
      getFilteredOffersEx({
        page: offersDataCombine.loadingData.page + 1,
        offset: offersDataCombine.loadingData.offset,
        params: {
          currency: "USDT",
          offer_type: "SELL"
        }
      }).then();
    } else {
      getAllOffersEx({
        page: offersDataCombine.loadingData.page + 1,
        offset: offersDataCombine.loadingData.offset
      }).then();
    }
  }

  useEffect(() => {
    if (!offersDataCombine.loading && !offersDataCombine.loadingData.last_page) {
      setScrollTriggered(false);
    } else if (!offersDataCombine.loadingFiltered && !offersDataCombine.loadingData.last_page) {
      setScrollTriggered(false);
    }
  }, [offersDataCombine]);

  const convertOfferType = (text: string): string => {
    switch (text) {
      case 'SELL':
        return 'Продам';
      case 'BUY':
        return 'Куплю';
      case 'REQUEST':
        return 'Объявление';
    }
    return 'Неизвестный тип';
  };

  const searchOffersInFilter = async () => {
    const getDealingFilter = filterDealingRef.current!.state as IDropDownState;
    resetOffersData();
    setIsLoadInFilters(true);

    const getWalletFilter = filterWalletRef.current!.state as IDropDownState;

    if (getUserData.rating < 10) {
      getFilteredOffersEx({
        page: 1,
        offset: -1,
        params: {
          currency: getWalletFilter.selected.value,
          offer_type: getDealingFilter.selected.value
        }
      }).then();
    } else {
      getFilteredOffersEx({
        page: 1,
        offset: -1,
        params: {
          currency: getWalletFilter.selected.value,
          offer_type: getDealingFilter.selected.value
        }
      }).then();
    }
  };

  const copyOfferLink = async (offerUuid: string) => {
    copy('https://t.me/ex_p2p_bot?start=' + offerUuid)
    updateSnackbarStatus({
      title: "Ссылка успешно скопирована в буфер обмена!",
      status: "success",
      visible: true
    });
  }

  const openOfferModal = (offer: IOfferResponseModel) => {
    updateModalData({
      uuid: offer.uuid,
      author_uuid: offer.author_uuid,
      type_offer: convertOfferType(offer.offer_type),
      city: offer.city,
      amount: offer.amount,
      currency: offer.currency,
      fake_name: offer.fake_name,
      only_prof: offer.only_prof ? "Проф" : "Пользователь",
      seller_prof: offer.seller_prof ? "Проф" : "Пользователь",
      additionally: offer.additionally
    });
    setModalActive(current => !current);
  };

  const resetFiltersButton = async () => {
    resetOffersData();
    resetOffersLoadingUpdate();

    await getAllOffersEx({
      page: 1,
      offset: -1
    });
  }

  const convertSurchargeType = (text: string) => {
    switch (text) {
      case 'От меня':
        return 'Доплачу Вам';
      case 'От Вас':
        return 'Доплатите мне'
      default:
        return ''
    }
  }

  const deleteConfirmOfferButton = async (offer_id: number, offer_uuid: string) => {
    setAdminSelectedOffer({
      offer_id: offer_id,
      offer_uuid: offer_uuid
    });
    setModalConfirmDeleteOfferActive(current => !current);
  }

  const deleteOfferButton = async () => {
    setModalConfirmDeleteOfferActive(current => !current);

    const response = await API.Admin.deleteOffer(adminSelectedOffer.offer_id, adminSelectedOffer.offer_uuid);

    if (response.data['status'] === 'OK') {
      updateSnackbarStatus({
        title: "Объявление успешно удалено! Можете обновить страницу.",
        status: "success",
        visible: true
      });
    } else if (response.data['status'] === 'There is a fix') {
      setAdminSelectedOffer({
        offer_id: Number(response.data['offer_data']['offer_id']),
        offer_uuid: response.data['offer_data']['offer_uuid']
      });
      setModalDeleteOfferActive(current => !current);
    } else if (response.data['status'] === 'Ad not found') {
      updateSnackbarStatus({
        title: "Объявление уже было удалено!",
        status: "warning",
        visible: true
      });
    }
  }

  const deleteOfferConfirmButton = async () => {
    const response = await API.Admin.deleteOffer(adminSelectedOffer.offer_id, adminSelectedOffer.offer_uuid, true);

    if (response.data['status'] === 'OK') {
      updateSnackbarStatus({
        title: "Объявление успешно удалено! Можете обновить страницу.",
        status: "success",
        visible: true
      });
    } else if (response.data['status'] === 'Ad not found') {
      updateSnackbarStatus({
        title: "Объявление уже было удалено!",
        status: "warning",
        visible: true
      });
    }
    setModalDeleteOfferActive(current => !current);
  }

  const deleteOfferCancelButton = () => {
    setAdminSelectedOffer({
      offer_id: -1,
      offer_uuid: ''
    });
    setModalDeleteOfferActive(current => !current);
  }

  const deleteConfirmOfferCancelButton = () => {
    setAdminSelectedOffer({
      offer_id: -1,
      offer_uuid: ''
    });
    setModalConfirmDeleteOfferActive(current => !current);
  }

  return (
      <section className={styles.Wallet}>
        <TelegramBackButton
          onClick={() => {
            window.history.back();
          }}
        />
        <div className={styles.Wallet__Header}>
          <div className={styles.Wallet__Header__Select}>
            <div className={styles.Wallet__Header__Select__Item + ' ' + styles.Wallet__Header__Select__Item__Dropdown}>
              <h3 className={styles.Wallet__Header__Select__Item__Title}>Валюта</h3>
              <div className="Wallet__Header__Select__Item__Dropdown">
                <WalletDropdown walletRef={filterWalletRef}></WalletDropdown>
              </div>
            </div>

            <div className={styles.Wallet__Header__Select__Item + ' ' + styles.Wallet__Header__Select__Item__Dropdown}>
              <h3 className={styles.Wallet__Header__Select__Item__Title}>Тип объявления</h3>
              <div className="Wallet__Header__Select__Item__DropdownDealingType">
                <DropdownDealing dealingRef={filterDealingRef}></DropdownDealing>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.Wallet__Buttons}>
          <div className={styles.Wallet__Button} onClick={searchOffersInFilter}>
            <button>Показать объявления</button>
          </div>
          <div className={styles.Wallet__Button} onClick={resetFiltersButton}>
            <button>Сброс</button>
          </div>
        </div>

        <div className={styles.Wallet__Adds}>
          {
            offersDataCombine.loading && offersDataCombine.offers.length === 0 &&
              <>
                <div className={styles.Wallet__Add}>
                    <div className={styles.Wallet__Add__Header}>
                        <div className={styles.Wallet__Add__Header__Left}>
                            <span className={styles.Wallet__Add__Header__Price}><Skeleton height={20} width={150}/></span>
                        </div>

                        <div className={styles.Wallet__Add__Header__Right}>
                            <button className={styles.Wallet__Add__Header__Right__Share}></button>
                            <button className={styles.Wallet__Add__Header__Right__Buy}>КУПИТЬ</button>
                        </div>
                    </div>

                    <div className={styles.Wallet__Add__Main}>
                        <div className={styles.Wallet__Add__Main__Item}>
                            <div className={styles.Wallet__Add__Main__String}>
                                <div className={styles.Wallet__Add__Main__String__Left}>
                                    <img src={logo} alt="" className={styles.Wallet__Add__Main__SellerLogo}/>
                                    <h4 className={styles.Wallet__Add__Main__SellerName}><Skeleton height={20} width={90}/></h4>
                                </div>

                                <div className={styles.Wallet__Add__Main__String__Right}>
                                    <span className={styles.Wallet__Add__Main__String__Span}><Skeleton height={20} width={150}/></span>
                                </div>
                            </div>

                            <div className={styles.Wallet__Add__Main__String}>
                                <div className={styles.Wallet__Add__Main__String__Left}>
                                    <span className={styles.Wallet__Add__Main__Available}>Тип</span>
                                </div>

                                <div className={styles.Wallet__Add__Main__String__Right}>
                                    <span className={styles.Wallet__Add__Main__String__Span}><Skeleton height={20} width={150}/></span>
                                </div>
                            </div>

                            <div className={styles.Wallet__Add__Main__String}>
                                <div className={styles.Wallet__Add__Main__String__Left}>
                                    <span className={styles.Wallet__Add__Main__Limits}>Город</span>
                                </div>

                                <div className={styles.Wallet__Add__Main__String__Right}>
                                    <span className={styles.Wallet__Add__Main__String__Span}><Skeleton height={20} width={150}/></span>
                                </div>
                            </div>

                            <div className={styles.Wallet__Add__Main__String}>
                                <div className={styles.Wallet__Add__Main__String__Left}>
                                    <span className={styles.Wallet__Add__Main__PayMethods}>Валюта</span>
                                </div>

                                <div className={styles.Wallet__Add__Main__String__Right}>
                                    <span className={styles.Wallet__Add__Main__String__Span}><Skeleton height={20} width={150}/></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.Wallet__Add}>
                  <div className={styles.Wallet__Add__Header}>
                    <div className={styles.Wallet__Add__Header__Left}>
                      <span className={styles.Wallet__Add__Header__Price}><Skeleton height={20} width={150}/></span>
                    </div>

                    <div className={styles.Wallet__Add__Header__Right}>
                      <button className={styles.Wallet__Add__Header__Right__Share}></button>
                      <button className={styles.Wallet__Add__Header__Right__Buy}>КУПИТЬ</button>
                    </div>
                  </div>

                  <div className={styles.Wallet__Add__Main}>
                    <div className={styles.Wallet__Add__Main__Item}>
                      <div className={styles.Wallet__Add__Main__String}>
                        <div className={styles.Wallet__Add__Main__String__Left}>
                          <img src={logo} alt="" className={styles.Wallet__Add__Main__SellerLogo}/>
                          <h4 className={styles.Wallet__Add__Main__SellerName}><Skeleton height={20} width={90}/></h4>
                        </div>

                        <div className={styles.Wallet__Add__Main__String__Right}>
                          <span className={styles.Wallet__Add__Main__String__Span}><Skeleton height={20} width={150}/></span>
                        </div>
                      </div>

                      <div className={styles.Wallet__Add__Main__String}>
                        <div className={styles.Wallet__Add__Main__String__Left}>
                          <span className={styles.Wallet__Add__Main__Available}>Тип</span>
                        </div>

                        <div className={styles.Wallet__Add__Main__String__Right}>
                          <span className={styles.Wallet__Add__Main__String__Span}><Skeleton height={20} width={150}/></span>
                        </div>
                      </div>

                      <div className={styles.Wallet__Add__Main__String}>
                        <div className={styles.Wallet__Add__Main__String__Left}>
                          <span className={styles.Wallet__Add__Main__Limits}>Город</span>
                        </div>

                        <div className={styles.Wallet__Add__Main__String__Right}>
                          <span className={styles.Wallet__Add__Main__String__Span}><Skeleton height={20} width={150}/></span>
                        </div>
                      </div>

                      <div className={styles.Wallet__Add__Main__String}>
                        <div className={styles.Wallet__Add__Main__String__Left}>
                          <span className={styles.Wallet__Add__Main__PayMethods}>Валюта</span>
                        </div>

                        <div className={styles.Wallet__Add__Main__String__Right}>
                          <span className={styles.Wallet__Add__Main__String__Span}><Skeleton height={20} width={150}/></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
          }
          {
            offersDataCombine.offers.map((offer, index) => (
              offer.offer_type === "REQUEST" ? (
                <div className={styles.Wallet__Add__Card} key={offer.uuid} ref={index === offersDataCombine.offers.length - 1 ? ref : null}>
                  <div className={styles.Wallet__Add}>
                    <div className={styles.Wallet__Add__Main}>

                      <div className={styles.Wallet__Add__Main__Item}>

                        <div className={styles.Wallet__Add__Main__Item__Card}>
                          <div className={styles.Wallet__Add__Main__Item__Card__Top}>
                            <div className={styles.Wallet__Add__Main__String}>
                              <div className={styles.Wallet__Add__Main__String__Left}>
                                <img src={logo} alt="" className={styles.Wallet__Add__Main__SellerLogo}/>
                                <h4 className={styles.Wallet__Add__Main__SellerName}>Имя скрыто</h4>
                              </div>
                            </div>

                            <div className={styles.Wallet__Add__Header}>
                              <div className={styles.Wallet__Add__Header__Right}>
                                <button className={styles.Wallet__Add__Header__Right__Share} onClick={() => copyOfferLink(offer.uuid)}></button>
                                <button className={styles.Wallet__Add__Header__Right__Buy} onClick={() => openOfferModal(offer)}>КУПИТЬ</button>
                                {
                                  getUserData.is_admin &&
                                    <button className={styles.Wallet__Add__Header__Right__Delete} onClick={() => deleteConfirmOfferButton(offer.id!, offer.uuid)}></button>
                                }
                              </div>
                            </div>
                          </div>

                          <div className={styles.Wallet__Add__Main__String}>
                            <div className={styles.Wallet__Add__Main__String__Left}>
                              <span className={styles.Wallet__Add__Main__Available}>Номер</span>
                            </div>

                            <div className={styles.Wallet__Add__Main__String__Right}>
                              <span className={styles.Wallet__Add__Main__String__Span}>{offer.id}</span>
                            </div>
                          </div>

                          <div className={styles.Wallet__Add__Main__String}>
                            <div className={styles.Wallet__Add__Main__String__Left}>
                              <span className={styles.Wallet__Add__Main__Available}>Тип</span>
                            </div>

                            <div className={styles.Wallet__Add__Main__String__Right}>
                              <span className={styles.Wallet__Add__Main__String__Span}>Объявление</span>
                            </div>
                          </div>

                          <div className={styles.Wallet__Add__Main__String}>
                            <div className={styles.Wallet__Add__Main__String__Left}>
                              <span className={styles.Wallet__Add__Main__PayMethods}>Пользователь</span>
                            </div>

                            <div className={styles.Wallet__Add__Main__String__Right}>
                              <span className={styles.Wallet__Add__Main__String__Span}>{offer.seller_prof ? 'Проф' : 'Пользователь'}</span>
                            </div>
                          </div>
                        </div>

                        <div className={styles.Wallet__Add__Main__String}>
                          <p>{offer.additionally}</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                ) : (
                <div className={styles.Wallet__Add} key={offer.uuid} ref={index === offersDataCombine.offers.length - 1 ? ref : null}>
                  <div className={styles.Wallet__Add__Header}>
                    <div className={styles.Wallet__Add__Header__Left}>
                      <span className={styles.Wallet__Add__Header__Price}>{offer.amount} {offer.currency}</span>
                    </div>

                    <div className={styles.Wallet__Add__Header__Right}>
                      <button className={styles.Wallet__Add__Header__Right__Share} onClick={() => copyOfferLink(offer.uuid)}></button>
                      <button className={styles.Wallet__Add__Header__Right__Buy} onClick={() => openOfferModal(offer)}>КУПИТЬ</button>
                      {
                        getUserData.is_admin &&
                          <button className={styles.Wallet__Add__Header__Right__Delete} onClick={() => deleteConfirmOfferButton(offer.id!, offer.uuid)}></button>
                      }
                    </div>
                  </div>

                  <div className={styles.Wallet__Add__Main}>
                    <div className={styles.Wallet__Add__Main__Item}>
                      {/*<div className={styles.Wallet__Add__Main__String}>*/}
                      {/*  <div className={styles.Wallet__Add__Main__String__Left}>*/}
                      {/*    <img src={logo} alt="" className={styles.Wallet__Add__Main__SellerLogo}/>*/}
                      {/*    <h4 className={styles.Wallet__Add__Main__SellerName}>Имя скрыто</h4>*/}
                      {/*  </div>*/}

                      {/*  <div className={styles.Wallet__Add__Main__String__Right}>*/}
                      {/*    <span className={styles.Wallet__Add__Main__String__Span}>{convertSurchargeType(offer.surcharge_type)} {offer.bet}%</span>*/}
                      {/*  </div>*/}
                      {/*</div>*/}

                      <div className={styles.Wallet__Add__Main__String}>
                        <div className={styles.Wallet__Add__Main__String__Left}>
                          <span className={styles.Wallet__Add__Main__Available}>Номер</span>
                        </div>

                        <div className={styles.Wallet__Add__Main__String__Right}>
                          <span className={styles.Wallet__Add__Main__String__Span}>{offer.id}</span>
                        </div>
                      </div>

                      <div className={styles.Wallet__Add__Main__String}>
                        <div className={styles.Wallet__Add__Main__String__Left}>
                          <span className={styles.Wallet__Add__Main__Available}>Тип</span>
                        </div>

                        <div className={styles.Wallet__Add__Main__String__Right}>
                          <span className={styles.Wallet__Add__Main__String__Span}>{convertOfferType(offer.offer_type)}</span>
                        </div>
                      </div>

                      <div className={styles.Wallet__Add__Main__String}>
                        <div className={styles.Wallet__Add__Main__String__Left}>
                          <span className={styles.Wallet__Add__Main__Limits}>Город</span>
                        </div>

                        <div className={styles.Wallet__Add__Main__String__Right}>
                          <span className={styles.Wallet__Add__Main__String__Span}>{offer.city}</span>
                        </div>
                      </div>

                      {/*<div className={styles.Wallet__Add__Main__String}>*/}
                      {/*  <div className={styles.Wallet__Add__Main__String__Left}>*/}
                      {/*    <span className={styles.Wallet__Add__Main__PayMethods}>Валюта</span>*/}
                      {/*  </div>*/}

                      {/*  <div className={styles.Wallet__Add__Main__String__Right}>*/}
                      {/*    <span className={styles.Wallet__Add__Main__String__Span}>{offer.currency}</span>*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                      {
                        offer.surcharge_type !== 'NONE_SUR' &&
                          <div className={styles.Wallet__Add__Main__String}>
                              <div className={styles.Wallet__Add__Main__String__Left}>
                                  <span className={styles.Wallet__Add__Main__Available}>{convertSurchargeType(offer.surcharge_type)}</span>
                              </div>

                              <div className={styles.Wallet__Add__Main__String__Right}>
                                  <span className={styles.Wallet__Add__Main__String__Span}>{offer.bet}%</span>
                              </div>
                          </div>
                      }

                      <div className={styles.Wallet__Add__Main__String}>
                        <div className={styles.Wallet__Add__Main__String__Left}>
                          <span className={styles.Wallet__Add__Main__PayMethods}>Пользователь</span>
                        </div>

                        <div className={styles.Wallet__Add__Main__String__Right}>
                          <span className={styles.Wallet__Add__Main__String__Span}>{offer.seller_prof ? 'Проф' : 'Пользователь'}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            ))
          }
        </div>
        {
          modalActive &&
            <ModalAdd active={modalActive} setActive={setModalActive}>
                <AddDetail setActive={setModalActive}></AddDetail>
            </ModalAdd>
        }
        {
          getUserData.is_admin &&
            <>
                <ModalWallet active={modalDeleteOfferActive} setActive={setModalDeleteOfferActive}>
                    <div className="ModalWallet__Wrapper">
                        <p>По данному объявлению есть фикс, вы действительно хотите удалить ?</p>

                        <div className="ModalWallet__Buttons">
                            <button onClick={deleteOfferConfirmButton}>Да</button>
                            <button onClick={deleteOfferCancelButton}>Отмена</button>
                        </div>
                    </div>
                </ModalWallet>
                <ModalWallet active={modalConfirmDeleteOfferActive} setActive={setModalConfirmDeleteOfferActive}>
                    <div className="ModalWallet__Wrapper">
                        <p>Подтвердите свое действие</p>

                        <div className="ModalWallet__Buttons">
                            <button onClick={deleteOfferButton}>Удалить</button>
                            <button onClick={deleteConfirmOfferCancelButton}>Отмена</button>
                        </div>
                    </div>
                </ModalWallet>
            </>
        }
      </section>
  );
}