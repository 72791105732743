import axios from "axios";
import {config} from "../../config";
import {ApiPaths} from "../../apiPaths";
import {IOffersFilterParameters} from "../../models/Offers/GetOffers";

export class OfferApi {
  public async publishOffer(additionally: string, only_prof: boolean, tradeType: string, author_uuid: string,
                            currency?: string, amount?: number, bet?: number, city?: string, address?: string,
                            surcharge_type?: string) {
    return await axios.post(config.apiHost + ApiPaths.CREATE_NEW_OFFER, {
      "event": 'CreateNewOffer',
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData
        },
        "offer_data": {
          "offer_type": tradeType,
          "currency": currency,
          "amount": amount,
          "bet": bet,
          "city": city,
          "address": address,
          "additionally": additionally,
          "only_prof": only_prof,
          "author_uuid": author_uuid,
          "surcharge_type": surcharge_type
        }
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    })
  };

  public async publishSellOffer(additionally: string, only_prof: boolean, tradeType: string, author_uuid: string,
                            currency?: string, amount?: number, bet?: number, city?: string, address?: string,
                            surcharge_type?: string) {
    return await axios.post(config.apiHost + ApiPaths.CREATE_SELL_OFFER, {
      "event": 'CreateNewOffer',
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData
        },
        "offer_data": {
          "offer_type": tradeType,
          "currency": currency,
          "amount": amount,
          "bet": bet,
          "city": city,
          "address": address,
          "additionally": additionally,
          "only_prof": only_prof,
          "author_uuid": author_uuid,
          "surcharge_type": surcharge_type
        }
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    })
  };

  public async publishBuyOffer(additionally: string, only_prof: boolean, tradeType: string, author_uuid: string,
                                currency?: string, amount?: number, bet?: number, city?: string, address?: string,
                                surcharge_type?: string) {
    return await axios.post(config.apiHost + ApiPaths.CREATE_BUY_OFFER, {
      "event": 'CreateNewOffer',
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData
        },
        "offer_data": {
          "offer_type": tradeType,
          "currency": currency,
          "amount": amount,
          "bet": bet,
          "city": city,
          "address": address,
          "additionally": additionally,
          "only_prof": only_prof,
          "author_uuid": author_uuid,
          "surcharge_type": surcharge_type
        }
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    })
  };

  public async publishRequestOffer(additionally: string, only_prof: boolean, tradeType: string, author_uuid: string) {
    return await axios.post(config.apiHost + ApiPaths.CREATE_REQUEST_OFFER, {
      "event": 'CreateNewOffer',
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData
        },
        "offer_data": {
          "offer_type": tradeType,
          "additionally": additionally,
          "only_prof": only_prof,
          "author_uuid": author_uuid,
        }
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    })
  };

  public async getAllOffers(page: number, offset: number) {
    return await axios.post(config.apiHost + ApiPaths.GET_ALL_OFFERS, {
      "event": "getAllOffers",
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData
        },
        "loading_data": {
          "page": page,
          "offset": offset
        }
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    });
  };

  public async getUserOffers(page: number, offset: number) {
    return await axios.post(config.apiHost + ApiPaths.GET_USER_OFFERS, {
      "event": "getUserOffers",
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData
        },
        "loading_data": {
          "page": page,
          "offset": offset
        }
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    });
  };

  public async getClientOffers(page: number, offset: number) {
    return await axios.post(config.apiHost + ApiPaths.GET_CLIENT_OFFERS, {
      "event": "getClientOffers",
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData
        },
        "loading_data": {
          "page": page,
          "offset": offset
        }
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    });
  };

  public async getModerationOffers(page: number, offset: number) {
    return await axios.post(config.apiHost + ApiPaths.GET_MODERATION_OFFERS, {
      "event": "getClientOffers",
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData
        },
        "loading_data": {
          "page": page,
          "offset": offset
        }
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    });
  };

  public async getSuccessfulOffers(page: number, offset: number) {
    return await axios.post(config.apiHost + ApiPaths.GET_SUCCESSFUL_OFFERS, {
      "event": "getClientOffers",
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData
        },
        "loading_data": {
          "page": page,
          "offset": offset
        }
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    });
  };

  public async getFilteredOffers(page: number, offset: number, params: IOffersFilterParameters) {
    return await axios.post(config.apiHost + ApiPaths.GET_OFFERS_FROM_FILTERS, {
      "event": "getFilteredOffers",
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData
        },
        "filtered_offer_data": {
          "currency": params.currency,
          "offer_type": params.offer_type
        },
        "loading_data": {
          "page": page,
          "offset": offset
        }
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    });
  }
  public async getOfferData(offerUuid: string) {
    return await axios.post(config.apiHost + ApiPaths.GET_OFFER_DATA, {
      "event": "getOfferData",
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData
        },
        "offer_data": {
          "uuid": offerUuid,
        },
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    });
  }
  public async deleteUserOffer(offerUuid: string) {
    return await axios.post(config.apiHost + ApiPaths.DELETE_USER_OFFER, {
      "event": "deleteUserOffer",
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData
        },
        "offer_data": {
          "uuid": offerUuid,
        },
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    });
  };
}