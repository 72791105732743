import {createEvent, createStore} from "effector";

export interface IMessagesModel {
  message_id: number;
  room_uuid: string;
  sender_uuid: string;
  recipient_uuid: string;
  sender_name: string;
  message: string;
  message_type: string;
  time: string;
  is_read: boolean;
}

export interface IRoomAdditionallyModel {
  interlocutor_username: string;
  is_fixed: boolean;
  is_successful: boolean;
  is_broken: boolean;
}

export const messagesUpdateData = createEvent<IMessagesModel[]>();
export const messagesUpdateDataEvent = (state: IMessagesModel[], data: IMessagesModel[]) => [...state, ...data];

export const updateRoomAdditionallyData = createEvent<IRoomAdditionallyModel>();
export const updateRoomAdditionallyDataEvent = (state: IRoomAdditionallyModel, data: IRoomAdditionallyModel) => data;

export const updateRoomAdditionallyBroken = createEvent<boolean>();
export const updateRoomAdditionallyBrokenEvent = (state: IRoomAdditionallyModel, data: boolean) => {
  const nextState = {...state};
  nextState.is_broken = true;
  return nextState;
}

export const updateRoomAdditionallyFixed = createEvent<boolean>();
export const updateRoomAdditionallyFixedEvent = (state: IRoomAdditionallyModel, data: boolean) => {
  const nextState = {...state};
  nextState.is_fixed = true;
  return nextState;
}

export const updateRoomAdditionallySuccessful = createEvent<boolean>();
export const updateRoomAdditionallySuccessfulEvent = (state: IRoomAdditionallyModel, data: boolean) => {
  const nextState = {...state};
  nextState.is_successful = true;
  return nextState;
}

export const messageUpdateReadStatus = createEvent<number>();
export const resetRoomAdditionallyData = createEvent();

export const messageUpdateReadStatusEvent = (state: IMessagesModel[], data: number) => {
  return state.map(function (message) {
    if (message.message_id === data) {
      const nextState = {...message};
      nextState.is_read = true;
      return nextState;
    } else return message;
  })
}

export const messagesResetData = createEvent();

export const $messagesData = createStore<IMessagesModel[]>([]);
export const $roomAdditionallyData = createStore<IRoomAdditionallyModel>({
  is_broken: false,
  is_fixed: false,
  is_successful: false,
  interlocutor_username: ""});
