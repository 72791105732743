import * as React from 'react';
import styles from '../Add.module.scss';
import logo from '../../../images/example_logo.svg';
import {useStore} from "effector-react";
import {$user} from "../../../models/User";
import {$offerModalData, updateModalData} from "../../../models/Offers/OfferModalData";
import {API} from "../../../api";
import {updateSnackbarStatus} from "../../../models/Snackbar";
import {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {$redirectStatus, resetRedirectPageStatus} from "../../../models/Page";
import RoutePaths from "../../../routePaths";
import {TelegramBackButton} from "../../../components/BackButton/BackButton";

export function AddRedirect() {
  const navigate = useNavigate();
  const {state} = useLocation();
  const {offerUuid} = state;

  const offerModalData = useStore($offerModalData);
  const userData = useStore($user);
  const redirectStatus = useStore($redirectStatus);

  useEffect(() => {
    getOfferData().then();
  }, []);

  const convertOfferType = (text: string): string => {
    switch (text) {
      case 'SELL':
        return 'Продам';
      case 'BUY':
        return 'Куплю';
      case 'REQUEST':
        return 'Объявление';
    }
    return 'Неизвестный тип';
  };

  const getOfferData = async () => {
    const response = await API.Offer.getOfferData(offerUuid);
    updateModalData({
      uuid: response.data['data']['offers_data']['uuid'],
      author_uuid: response.data['data']['offers_data']['author_uuid'],
      type_offer: convertOfferType(response.data['data']['offers_data']['offer_type']),
      city: response.data['data']['offers_data']['city'],
      amount: Number(response.data['data']['offers_data']['amount']),
      currency: response.data['data']['offers_data']['currency'],
      fake_name: response.data['data']['offers_data']['fake_name'],
      only_prof: response.data['data']['offers_data']['only_prof'] ? "Проф" : "Пользователь",
      seller_prof: response.data['data']['offers_data']['seller_prof'] ? "Проф" : "Пользователь",
      additionally: response.data['data']['offers_data']['additionally']
    });
  };

  const createTradeButton = async () => {
    const response = await API.Trade.createTrade(offerModalData.uuid, offerModalData.author_uuid, userData.user_uuid, offerModalData.amount);

    if (response.data['status'] === 'OK') {
      updateSnackbarStatus({
        title: "Запрос на создание исполнения успешно отправлен!",
        status: "success",
        visible: true
      });
    } else if (response.data['status'] === 'Error(write yourself)!') {
      updateSnackbarStatus({
        title: "Нельзя взять исполнение у самого себя!",
        status: "error",
        visible: true
      });
    }
  };

  const backButtonHandler = () => {
    if (redirectStatus && window.history.length === 2) {
      resetRedirectPageStatus();
      navigate(RoutePaths.MARKET);
      return;
    }
    window.history.back();
  }

  return (
    <section className={styles.AddDetail}>
      <TelegramBackButton
        onClick={backButtonHandler}
      />
      <div className={styles.AddDetail__Top}>
        <div className={styles.AddDetail__Header}>
          <div className={styles.AddDetail__Header__Top}>

            {/*<div className={styles.AddDetail__Header__Top__Middle}>*/}
            {/*  <img src={logo} alt=""/>*/}
            {/*  <span>Вы покупаете у <strong>продавца</strong></span>*/}
            {/*</div>*/}
          </div>

          <div className={styles.AddDetail__Header__Bottom}>
            <span>Имя скрыто</span>
          </div>
        </div>

        <div className={styles.AddDetail__Items}>
          <div className={styles.AddDetail__Items__Item}>
            <h3>Тип объявления</h3>
            <span>{offerModalData.type_offer}</span>
          </div>

          <div className={styles.AddDetail__Items__Item}>
            <h3>Город</h3>
            <span>{offerModalData.city}</span>
          </div>

          <div className={styles.AddDetail__Items__Item}>
            <h3>Сумма</h3>
            <span>{offerModalData.amount}</span>
          </div>

          <div className={styles.AddDetail__Items__Item}>
            <h3>Валюта</h3>
            <div className={styles.AddDetail__Items__Item__PayMethods}>
              <span>{offerModalData.currency}</span>
            </div>
          </div>
        </div>

        <div className={styles.AddDetail__Items}>
          <h2>Данные продавца</h2>

          {/*<div className={styles.AddDetail__Items__Item}>*/}
          {/*  <h3>Имя продавца</h3>*/}
          {/*  <span>{offerModalData.fake_name}</span>*/}
          {/*</div>*/}

          <div className={styles.AddDetail__Items__Item}>
            <h3>Статус продавца</h3>
            <span>{offerModalData.seller_prof}</span>
          </div>

          <div className={styles.AddDetail__Items__Item}>
            <h3>Объявление для</h3>
            <span>{offerModalData.only_prof}</span>
          </div>
        </div>

        <div className={styles.AddDetail__Items + ' ' + styles.AddDetail__Items__Description}>
          <h2>Дополнительная информация</h2>

          <div className={styles.AddDetail__Items__Item}>
            <p>
              {offerModalData.additionally}
            </p>
          </div>
        </div>
      </div>

      <div className={styles.AddDetail__Bottom}>
        <div className={styles.AddDetail__Button}>
          <button onClick={createTradeButton}>Отправить запрос</button>
        </div>
      </div>
    </section>
  );
}