import {Route, Routes, useNavigate} from 'react-router-dom'
import RoutePaths from "./routePaths";
import {Wallet} from "./pages/Wallet/Wallet";
import {Search} from "./pages/Search/Search";
import {Settings} from "./pages/Settings/Settings";
import {CreateAdd} from "./pages/CreateAdd/CreateAdd";
import {Market} from "./pages/Market/Market";
import {Profile} from "./pages/Profile/Profile";
import {VerificationPersonalInfo} from "./pages/Verification/VerificationPersonalInfo";
import {VerificationConfirm} from "./pages/Verification/VerificationConfirm";
import {VerificationDocumentType} from "./pages/Verification/VerificationDocumentType";
import {VerificationPaymentPay} from "./pages/Verification/VerificationPaymentDoc";
import {VerificationSelfie} from "./pages/Verification/VerificationSelfie";
import {VerificationMail} from "./pages/Verification/VerificationMail";
import {Add} from "./pages/Add/Add";
import SimpleSnackbar from "./components/Snackbar/Snackbar";
import {$snackbar} from "./models/Snackbar";
import {useStore} from "effector-react";
import {Chat} from "./pages/Chat/Chat/Chat";
import {Room} from "./pages/Chat/Room/Room";
import {useEffect} from "react";
import {$user, IUserModel, updateUserAuth, updateUserData} from "./models/User";
import InfoPage from "./pages/InfoPage/InfoPage";
import {Redirect} from "./pages/Redirect/Redirect";
import {AddRedirect} from "./pages/Add/AddRedirect/AddRedirect";
import AdminPanelUsers from "./pages/AdminPanelUsers/AdminPanelUsers";
import AdminPanelUserPage from "./pages/AdminPanelUserPage/AdminPanelUserPage";
import ViewInfoPage from "./pages/InfoPage/ViewInfoPage/ViewInfoPage";
import NoUsername from "./pages/NoUsername/NoUsername";
import BlockedUser from "./pages/BlockedUser/BlockedUser";
import {UserWallet} from "./pages/UserWallet/UserWallet";
import AdminPanelGroup from "./pages/AdminPanel/AdminPanel";
import Faq from "./pages/FAQ/FAQ";
import {OperationUnavailble} from "./pages/OperationUnavailble/OperationUnavailble";
import {Error} from "./pages/Error/Error";

import * as eruda from "eruda";
import {withSentryReactRouterV6Routing} from "@sentry/react";

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

declare global {
  interface Window {
    P2PMarketAuth: {
      promise: Promise<IUserModel>;
    };
  }
}

const App = () => {
  // eruda.default.init();
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === RoutePaths.VIEW_INFO_PAGE) {
      navigate(RoutePaths.VIEW_INFO_PAGE);
    }
  }, []);

  window.Telegram.WebApp.expand();
  const snackbar = useStore($snackbar);

  useEffect(() => {
    if (window.location.pathname === RoutePaths.VIEW_INFO_PAGE) return;
    if (window.performance.navigation.type !== 1) {
      window.P2PMarketAuth.promise.catch((reason) => {
        if (reason === 'not set username') {
          updateUserAuth(false);
          navigate(RoutePaths.NO_USERNAME_ERROR);
        } else if (reason === 'not allowed country') {
          updateUserAuth(false);
          navigate(RoutePaths.OPERATION_UNAVAIlBlE);
        } else if (reason === 'user not found') {
          updateUserAuth(false);
          navigate(RoutePaths.ERROR_PAGE, {
            state: {
              code: 404,
              title: 'Доступ запрещен',
              description: 'Ваш аккаунт не найден в базе данных.'
            }
          });
        } else if (reason === 'Invalid init data signature') {
          updateUserAuth(false);
          navigate(RoutePaths.ERROR_PAGE, {
            state: {
              code: 1020,
              title: 'Доступ запрещен',
              description: 'Обнаружена попытка входа с браузера! Пожалуйста, используйте Telegram.'
            }
          });
        } else {
          updateUserAuth(false);
          navigate(RoutePaths.ERROR_PAGE, {
            state: {
              code: 500,
              title: 'Доступ запрещен',
              description: 'Неизвестная ошибка. Возможно наши сервера перезагружаются, попробуйте зайти позже!'
            }
          });
        }
      });
      window.P2PMarketAuth.promise?.then((result) => {
        updateUserData({
          user_uuid: result.user_uuid,
          fake_name: result.fake_name,
          rating: result.rating,
          is_admin: result.is_admin,
          rules_signed: result.rules_signed,
          lang_code: result.lang_code,
          successful_trades: result.successful_trades,
          is_blocked: result.is_blocked,
          is_prof: result.is_prof,
          auth: true
        });
        document.documentElement.setAttribute('lang', result.lang_code);

        if (result.is_blocked) {
          navigate(RoutePaths.BLOCKED_USER_PAGE);
          return;
        }

        if (!result.rules_signed) {
          navigate(RoutePaths.INFO_PAGE);
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (window.location.pathname === RoutePaths.VIEW_INFO_PAGE) return;
  //   if (userData.is_blocked) {
  //     navigate(RoutePaths.BLOCKED_USER_PAGE);
  //     return;
  //   }
  //
  //   if (!userData.rules_signed) {
  //     navigate(RoutePaths.INFO_PAGE);
  //   }
  // }, [navigate, userData]);

  return (
    <>
      <SentryRoutes>
        <Route path={RoutePaths.FAQ} element={<Faq/>}/>
        <Route path={RoutePaths.WALLET} element={<Wallet/>}/>
        <Route path={RoutePaths.SEARCH} element={<Search/>}/>
        <Route path={RoutePaths.SETTINGS} element={<Settings/>}/>
        <Route path={RoutePaths.CREATE_ADD} element={<CreateAdd/>}/>
        <Route path={RoutePaths.MARKET} element={<Market/>}/>
        <Route path={RoutePaths.PROFILE} element={<Profile/>}/>
        <Route path={RoutePaths.VERIFICATION_PERSONAL} element={<VerificationPersonalInfo/>}/>
        <Route path={RoutePaths.VERIFICATION_CONFIRM} element={<VerificationConfirm/>}/>
        <Route path={RoutePaths.VERIFICATION_DOCUMENTS} element={<VerificationDocumentType/>}/>
        <Route path={RoutePaths.VERIFICATION_PAYMENT} element={<VerificationPaymentPay/>}/>
        <Route path={RoutePaths.VERIFICATION_SELFIE} element={<VerificationSelfie/>}/>
        <Route path={RoutePaths.VERIFICATION_MAIL} element={<VerificationMail/>}/>
        <Route path={RoutePaths.ADD} element={<Add/>}/>
        <Route path={RoutePaths.CHAT} element={<Chat/>}/>
        <Route path={RoutePaths.ROOMS} element={<Room/>}/>
        <Route path={RoutePaths.INFO_PAGE} element={<InfoPage/>}/>
        <Route path={RoutePaths.VIEW_INFO_PAGE} element={<ViewInfoPage/>}/>
        <Route path={RoutePaths.REDIRECT_PAGE} element={<Redirect/>}/>
        <Route path={RoutePaths.ADD_REDIRECT} element={<AddRedirect/>}/>
        <Route path={RoutePaths.ADMIN_PANEL} element={<Settings/>}/>
        <Route path={RoutePaths.ADMIN_USERS_LIST} element={<AdminPanelUsers/>}/>
        <Route path={RoutePaths.ADMIN_GET_USER_PROFILE} element={<AdminPanelUserPage/>}/>
        <Route path={RoutePaths.NO_USERNAME_ERROR} element={<NoUsername/>}/>
        <Route path={RoutePaths.BLOCKED_USER_PAGE} element={<BlockedUser/>}/>
        <Route path={RoutePaths.MY_OFFERS} element={<UserWallet/>}/>
        <Route path={RoutePaths.ADMIN_PANEL_GROUP} element={<AdminPanelGroup/>}/>
        <Route path={RoutePaths.OPERATION_UNAVAIlBlE} element={<OperationUnavailble/>}/>
        <Route path={RoutePaths.ERROR_PAGE} element={<Error/>}/>
      </SentryRoutes>
      {
        snackbar.visible &&
          <SimpleSnackbar title={snackbar.title}
                          messageType={snackbar.status}></SimpleSnackbar>
      }
    </>
  );
}

export default App;
