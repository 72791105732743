import React, {useEffect, useRef} from 'react';
import styles from './AdminPanelUserPage.module.scss';
import {useLocation} from "react-router-dom";
import {useStore} from "effector-react";
import {$userProfileTabs, EAUserProfileTabs, updateAUserProfileTabs} from "../../models/Admin/GetUsers/PageParametrs";
import {DropdownRating} from "../../components/DropdownAdminRating/CustomDropdown";
import {DropdownStatusRole} from "../../components/DropdownAdminRole/CustomDropdown";

import cross from "../../images/cross.svg"
import {TelegramBackButton} from "../../components/BackButton/BackButton";
import {getAdminUserProfileEx} from "../../models/Admin/GetUserProfile/init";
import {$adminUserProfileData, resetAdminUserProfileData} from "../../models/Admin/GetUserProfile";
import ReactDropdown from "react-dropdown";
import {API} from "../../api";
import {updateSnackbarStatus} from "../../models/Snackbar";
import check_mark from '../../images/check-mark.svg';

interface IDropDownState {
  selected: ISelected;
  isOpen: boolean;
}

interface ISelected {
  label: string;
  value: string;
}

const AdminPanelUserPage = () => {
  const {state} = useLocation();

  const roleRef = useRef<ReactDropdown>(null);
  const ratingRef = useRef<HTMLInputElement>(null);

  const adminUserProfileData = useStore($adminUserProfileData);
  const userProfileTabs = useStore($userProfileTabs);

  const { userId } = state;

  useEffect(() => {
    resetAdminUserProfileData();
    getAdminUserProfileEx({userId: userId}).then();
  }, [userId]);

  let generalSelected = userProfileTabs === EAUserProfileTabs.GENERAL ? styles.AdminPanelUser__Switch__Selected : '';
  let balanceSelected = userProfileTabs === EAUserProfileTabs.BALANCE ? styles.AdminPanelUser__Switch__Selected : '';
  let ipDataSelected = userProfileTabs === EAUserProfileTabs.IP_DATA ? styles.AdminPanelUser__Switch__Selected : '';
  let UsernameHistorySelected = userProfileTabs === EAUserProfileTabs.USERNAME_HISTORY ? styles.AdminPanelUser__Switch__Selected : '';

  const saveUserDataButton = async () => {
    const getRole = roleRef.current!.state as IDropDownState;

    if (!ratingRef.current || ratingRef.current.value === '') return;
    if (getRole.selected.value === '3') {
      updateSnackbarStatus({
        title: "Выдать права администратора возможно только через внутреннюю систему!",
        status: "error",
        visible: true
      });
      return;
    }

    const response = await API.Admin.updateUserData(userId, Number(ratingRef.current.value), Number(getRole.selected.value));
    if (response.data['status'] === 'OK') {
      updateSnackbarStatus({
        title: "Данные успешно обновлены",
        status: "success",
        visible: true
      });
    }
  }

  return (
      <section className={styles.AdminPanelUser}>
        <TelegramBackButton
          onClick={() => {
            window.history.back();
          }}
        />
        <div className={styles.AdminPanelUser__Header}>
          <span>{adminUserProfileData !== null ? adminUserProfileData.username : 'Загрузка..'}</span>
        </div>

        <div className={styles.AdminPanelUser__Switch}>
          <button className={generalSelected} onClick={() => updateAUserProfileTabs(EAUserProfileTabs.GENERAL)}>Основное</button>
          <button className={balanceSelected} onClick={() => updateAUserProfileTabs(EAUserProfileTabs.BALANCE)}>Баланс</button>
          <button className={ipDataSelected} onClick={() => updateAUserProfileTabs(EAUserProfileTabs.IP_DATA)}>IP-адреса</button>
          <button className={UsernameHistorySelected} onClick={() => updateAUserProfileTabs(EAUserProfileTabs.USERNAME_HISTORY)}>Смена имен</button>
        </div>

        {
          userProfileTabs === EAUserProfileTabs.GENERAL && adminUserProfileData !== null &&
            <div className={styles.AdminPanelUser__Main}>
                <div className={styles.AdminPanelUser__Main__Rating}>
                    <h3>Рейтинг:</h3>

                    <div className={styles.AdminPanelUser__Main__Rating__Dropdown}>
                        <input ref={ratingRef} type="text" className={styles.AdminPanelUser__Main__Input} placeholder="Рейтинг" defaultValue={adminUserProfileData.rating}/>
                        <DropdownRating></DropdownRating>
                    </div>
                </div>

                <div className={styles.AdminPanelUser__Main__Role}>
                    <h3>Роли:</h3>

                    <DropdownStatusRole roleDropDownRef={roleRef} defaultOption={adminUserProfileData.role}></DropdownStatusRole>
                </div>
            </div>
        }

        {
          userProfileTabs === EAUserProfileTabs.BALANCE && adminUserProfileData !== null &&
            <div className={styles.AdminPanelUser__Balance}>
                <div className={styles.AdminPanelUser__Balance__Counter}>
                    <h3>Баланс:</h3>
                    <span>{Number(adminUserProfileData.balance) / 1000000}</span>
                </div>

                <div className={styles.AdminPanelUser__Balance__Account}>
                    <h3>Аккаунт:</h3>

                    <div>
                      {
                        adminUserProfileData.account_status === 'Есть' ?
                          <>
                            <img src={check_mark} alt="" className={styles.AdminPanelUser__CheckMark}/>
                            <span>{adminUserProfileData.account_status}</span>
                          </>
                        :
                          <>
                            <img src={cross} alt="" className={styles.AdminPanelUser__Cross}/>
                            <span>{adminUserProfileData.account_status}</span>
                          </>
                      }
                    </div>
                </div>
            </div>
        }

        {
          userProfileTabs === EAUserProfileTabs.IP_DATA && adminUserProfileData !== null &&
            <div className={styles.AdminPanelUser__IP}>
                <div className={styles.AdminPanelUser__IP__Search}>
                    <input type="search" name="" id="" placeholder="Введите что-нибудь"/>
                    <button></button>
                </div>

                <div className={styles.AdminPanelUser__IP__Filters}>
                    <div className={styles.AdminPanelUser__IP__Filters__Header}>
                        <h3>Адрес</h3>
                        <h3>Город</h3>
                        <h3>Дата</h3>
                    </div>
                  {
                    adminUserProfileData.ip_history.length === 0 &&
                      <div className={styles.AdminPanelUser__IP__Filters__Content}>
                        <div className={styles.AdminPanelUser__IP__Filters__Content__Clear}>
                          <img src={cross} alt=""/>
                          <span>Нет </span>
                          <span>данных</span>
                        </div>
                      </div>
                  }
                  {
                    adminUserProfileData.ip_history.map((ip) =>
                      <div className={styles.AdminPanelUser__IP__Filters__Content} key={ip.id}>
                        <span>{ip.address}</span>
                        <span>{ip.city}</span>
                        <span>{ip.created_at}</span>
                      </div>
                    )
                  }
                </div>
            </div>
        }

        {
          userProfileTabs === EAUserProfileTabs.USERNAME_HISTORY && adminUserProfileData !== null &&
            <div className={styles.AdminPanelUser__IP}>
                <div className={styles.AdminPanelUser__IP__Search}>
                    <input type="search" name="" id="" placeholder="Введите что-нибудь"/>
                    <button></button>
                </div>

                <div className={styles.AdminPanelUser__IP__Filters}>
                    <div className={styles.AdminPanelUser__IP__Filters__Header}>
                        <h3>Старый</h3>
                        <h3>Новый</h3>
                        <h3>Дата</h3>
                    </div>

                  {
                    adminUserProfileData.username_history.length === 0 &&
                      <div className={styles.AdminPanelUser__IP__Filters__Content}>
                        <div className={styles.AdminPanelUser__IP__Filters__Content__Clear}>
                          <img src={cross} alt=""/>
                          <span>Нет </span>
                          <span>данных</span>
                        </div>
                      </div>
                  }
                  {
                    adminUserProfileData.username_history.map((username) =>
                      <div className={styles.AdminPanelUser__IP__Filters__Content} key={username.id}>
                        <span>{username.last_username}</span>
                        <span>{username.new_username}</span>
                        <span>{username.created_at}</span>
                      </div>
                    )
                  }
                </div>
            </div>
        }

        <div className={styles.AdminPanelUser__Buttons}>
          <div className={styles.AdminPanelUser__Buttons__Confirm}>
            <button onClick={saveUserDataButton}>Сохранить</button>
          </div>

          <div className={styles.AdminPanelUser__Buttons__Disable}>
            <button>Отменить</button>
          </div>
        </div>
      </section>
  );
};

export default AdminPanelUserPage;