export enum ApiPaths {
  CREATE_NEW_OFFER = '/api/v1/offers/create-offer',
  CREATE_SELL_OFFER = '/api/v1/offers/create-sell-offer',
  CREATE_BUY_OFFER = '/api/v1/offers/create-buy-offer',
  CREATE_REQUEST_OFFER = '/api/v1/offers/create-request-offer',
  GET_ALL_OFFERS = '/api/v1/offers/get-offers',
  GET_USER_OFFERS = '/api/v1/offers/get-user-offers',
  GET_CLIENT_OFFERS = '/api/v1/offers/get-client-offers',
  GET_MODERATION_OFFERS = '/api/v1/offers/get-moderation-offers',
  GET_SUCCESSFUL_OFFERS = '/api/v1/offers/get-successful-offers',
  DELETE_USER_OFFER = '/api/v1/offers/delete-user-offer',
  GET_OFFERS_FROM_FILTERS = '/api/v1/offers/get-filtered-offers',
  CREATE_TRADE = '/api/v1/trade/create-trade',
  USER_AUTH = '/api/v1/user/auth',
  FIX_TRADE = '/api/v1/trade/fix-trade',
  CONFIRM_TRADE = '/api/v1/trade/confirm-trade',
  CANCEL_TRADE = '/api/v1/trade/cancel-trade',
  CALL_MODERATOR = '/api/v1/trade/call-moderator',
  UPDATE_RULES_SIGNED = '/api/v1/user/update-rules-signed',
  GET_SUCCESS_TRADES = '/api/v1/trade/get-success-trades',
  GET_OFFER_DATA = '/api/v1/offers/get-offer-data',
  ADMIN_GET_USERS_LIST = '/api/v1/admin/users/get-users-list',
  ADMIN_GET_USER_PROFILE = '/api/v1/admin/users/get-user-profile',
  ADMIN_SEARCH_USER = '/api/v1/admin/users/search-user',
  ADMIN_USER_UPDATE = '/api/v1/admin/users/update-user',
  ADMIN_GET_SETTINGS = '/api/v1/admin/exchanger/get-settings',
  ADMIN_SAVE_SETTINGS = '/api/v1/admin/exchanger/save-settings',
  ADMIN_BLOCK_USER = '/api/v1/admin/chat/block-user',
  ADMIN_CLOSE_CHAT = '/api/v1/admin/chat/close-chat',
  ADMIN_CLOSE_TRADE = '/api/v1/admin/chat/close-trade',
  ADMIN_DELETE_OFFER = '/api/v1/admin/offer/delete-offer'
}