import {createEvent, createStore} from "effector";

export enum EAUserProfileTabs {
  GENERAL,
  BALANCE,
  IP_DATA,
  USERNAME_HISTORY
}

export const updateAUserProfileTabs = createEvent<EAUserProfileTabs>();
export const updateAUserProfileTabsEvent = (state: EAUserProfileTabs, data: EAUserProfileTabs) => data;

export const $userProfileTabs = createStore<EAUserProfileTabs>(EAUserProfileTabs.GENERAL);