import {
  $adminUserProfileData, IUserProfileModel,
  resetAdminUserProfileData,
  updateAdminUserProfileData,
  updateAdminUserProfileDataEvent
} from "./index";
import {createEffect} from "effector";
import {API} from "../../../api";

export const getAdminUserProfileEx = createEffect(async ({userId}: {userId: number}): Promise<IUserProfileModel> => {
  const response = await API.Admin.getAdminUserProfile(userId);

  return response.data['data']['user_profile_info'];
});

$adminUserProfileData
  .on(getAdminUserProfileEx.doneData, (_currentData, newData) => newData)
  .on(updateAdminUserProfileData, updateAdminUserProfileDataEvent)
  .reset(resetAdminUserProfileData);