import {createEvent, createStore} from "effector";

export interface IOfferModalData {
  uuid: string;
  author_uuid: string;
  type_offer: string;
  city: string;
  amount: number;
  currency: string;
  fake_name: string;
  only_prof: string;
  seller_prof: string;
  additionally: string;
}

export const updateModalData = createEvent<IOfferModalData>();
export const resetModalData = createEvent();

export const updateModalDataEvent = (state: IOfferModalData, data: IOfferModalData) => data;

export const $offerModalData = createStore<IOfferModalData>({
  seller_prof: "",
  uuid: "",
  author_uuid: "",
  additionally: "",
  amount: 0,
  city: "",
  currency: "",
  fake_name: "",
  only_prof: "",
  type_offer: ""
});