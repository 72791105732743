import styles from "./Chat.module.scss";
import {TextareaAutosize} from "@mui/material";
import {TelegramBackButton} from "../../../components/BackButton/BackButton";
import {io, Socket} from "socket.io-client";
import {config} from "../../../config";
import {useLocation, useNavigate} from "react-router-dom";
import {useStore} from "effector-react";
import {chatGetMessagesEx, messagesDataCombine} from "../../../models/Chat/Chat/init";

import double_check from '../../../images/check-double-svgrepo-com.svg';
import check_mark from '../../../images/check-mark1.svg';
import {$user} from "../../../models/User";
import React, {useEffect, useRef, useState} from "react";
import {$selectedRoomData, updateChatClosedStatus, updateSelectedRoomData} from "../../../models/Chat/Room";
import {
  messagesUpdateData,
  messageUpdateReadStatus, resetRoomAdditionallyData, updateRoomAdditionallyBroken,
  updateRoomAdditionallyFixed,
  updateRoomAdditionallySuccessful
} from "../../../models/Chat/Chat";
import {API} from "../../../api";
import {updateSnackbarStatus} from "../../../models/Snackbar";
import Skeleton from "react-loading-skeleton";

import 'react-loading-skeleton/dist/skeleton.css'
import {$redirectStatus, resetRedirectPageStatus} from "../../../models/Page";
import RoutePaths from "../../../routePaths";

export function Chat() {
  const navigate = useNavigate();
  const {state} = useLocation();
  const {roomUuid} = state;

  const storeMessagesData = useStore(messagesDataCombine);
  const selectedRoomData = useStore($selectedRoomData);
  const userData = useStore($user);
  const redirectStatus = useStore($redirectStatus);

  const [isShowDropDown, setShowDropDown] = useState<boolean>(false);

  const messageTextRef = useRef<HTMLTextAreaElement>(null);
  const socketRef = useRef<Socket>();
  const messagesEndRef = React.useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    resetRoomAdditionallyData();
    socketRef.current = io(config.chatSocketHost + '/chat', {
      auth: {
        webapp_init_data: window.Telegram.WebApp.initData,
        room_uuid: roomUuid
      }
    });

    socketRef.current!.on('new_message_response', (message: any) => {
      messagesUpdateData([message['sio_messages_data']]);
      updateSelectedRoomData(message['sio_rooms_data']);

      scrollToBottom();
    });

    socketRef.current!.on('update_message_response', (message: any) => {
      messageUpdateReadStatus(Number(message['message_id']));
    });

    try {
      socketRef.current!.on('disconnect', function (reason, description) {
        if (reason !== 'io client disconnect') console.debug(reason, description);
      })

      socketRef.current!.on('connect_error', function (reason) {
        console.debug(reason.message);
      })

      socketRef.current!.on("connect", () => {
        const transport = socketRef.current!.io.engine.transport.name; // in most cases, "polling"
        const engine = socketRef.current!.io.engine;
        console.debug("Коннекта сокета " + transport + " подключена");

        socketRef.current!.io.engine.on("upgrade", () => {
          const upgradedTransport = socketRef.current!.io.engine.transport.name; // in most cases, "websocket"
          console.debug("Обновление сокета " + upgradedTransport + " выполнено")
        });

        socketRef.current!.once("upgrade", () => {
          console.debug("Имя нового транспорта " + engine.transport.name);
        });

        socketRef.current!.on("close", (reason) => {
          console.debug("Обрыв сокета " + reason);
        });
      });
    } catch (e) {
      console.error("Ошибка слушателя " + e);
    }

    chatGetMessagesEx({socket: socketRef.current}).then();

    return () => {
      socketRef.current!.disconnect();
    }
  }, [roomUuid]);

  useEffect(() => {
    if (!storeMessagesData.loading) {
      for (let index = 0; index < storeMessagesData.messagesData.length; ++index) {
        if (!storeMessagesData.messagesData[index].is_read && storeMessagesData.messagesData[index].sender_uuid !== userData.user_uuid) {
          socketRef.current!.emit('update_reading_message', {
            "message_id": storeMessagesData.messagesData[index].message_id
          });
        }
      }
      scrollToBottom();
    }
  }, [storeMessagesData])

  const sendMessageButton = () => {
    if (!messageTextRef.current || messageTextRef.current.value === '') return;

    socketRef.current!.emit('send_message', {
      "message": messageTextRef.current.value,
    });
    messageTextRef.current.value = '';
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({behavior: "auto"});
  };

  const openDealMenu = () => {
    setShowDropDown(!isShowDropDown);
  };

  const fixDealButton = async () => {
    const response = await API.Trade.fixTrade(selectedRoomData.room_uuid, selectedRoomData.trade_uuid);

    if (response.data['status'] === 'OK') {
      updateSnackbarStatus({
        title: "Запрос фикса interaction успешно отправлен!",
        status: "success",
        visible: true
      });
      socketRef.current!.emit('send_notify', {
        "message": response.data['message'],
        "message_id": response.data['message_id'],
      });

      updateRoomAdditionallyFixed(true);
    } else if (response.data['status'] === 'The performer has already completed the fix.') {
      updateSnackbarStatus({
        title: "Вы уже отправляли запрос фикса!",
        status: "error",
        visible: true
      });
    }
    setShowDropDown(false);
  };

  const confirmTheDealButton = async () => {
    const response = await API.Trade.confirmTrade(selectedRoomData.room_uuid, selectedRoomData.trade_uuid);

    if (response.data['status'] === 'OK') {
      updateSnackbarStatus({
        title: "Запрос подтверждения успешно отправлен!",
        status: "success",
        visible: true
      });
      socketRef.current!.emit('send_notify', {
        "message": response.data['message'],
        "message_id": response.data['message_id'],
      });

      updateRoomAdditionallySuccessful(true);
    } else if (response.data['status'] === 'The performer has already completed the confirm.') {
      updateSnackbarStatus({
        title: "Вы уже отправляли запрос подтверждения!",
        status: "error",
        visible: true
      });
    } else if (response.data['chat_closed']) {
      socketRef.current!.emit('send_notify', {
        "message": response.data['close_message'],
        "message_id": response.data['close_message_id'],
      });

      updateChatClosedStatus(true);
    }
    setShowDropDown(false);
  };

  const refuseTheDealButton = async () => {
    const response = await API.Trade.cancelTrade(selectedRoomData.room_uuid, selectedRoomData.trade_uuid);

    if (response.data['status'] === 'OK') {
      updateSnackbarStatus({
        title: "Запрос отмены успешно отправлен!",
        status: "success",
        visible: true
      });
      socketRef.current!.emit('send_notify', {
        "message": response.data['message'],
        "message_id": response.data['message_id'],
      });

      updateRoomAdditionallyBroken(true);
    } else if (response.data['status'] === 'The performer has already completed the broken.') {
      updateSnackbarStatus({
        title: "Вы уже отправляли запрос отмены!",
        status: "error",
        visible: true
      });
    }
    setShowDropDown(false);
  };

  const callTheModeratorButton = async () => {
    const response = await API.Trade.callModerator(selectedRoomData.trade_uuid, selectedRoomData.room_uuid);
    if (response.data['status'] === 'OK') {
      updateSnackbarStatus({
        title: "Модератор успешно вызван в чат!",
        status: "success",
        visible: true
      });
      socketRef.current!.emit('send_notify', {
        "message": response.data['message'],
        "message_id": response.data['message_id'],
      });
    }
    setShowDropDown(false);
  };

  const backButtonHandler = () => {
    if (redirectStatus && window.history.length === 2) {
      resetRedirectPageStatus();
      navigate(RoutePaths.MARKET);
      return;
    }
    window.history.back();
  }

  const adminBlockImplementerButton = async () => {
    const response = await API.Admin.chatBlockUser(
      selectedRoomData.implementer_uuid!, selectedRoomData.room_uuid, selectedRoomData.trade_uuid
    )
    if (response.data['status'] === 'OK') {
      updateSnackbarStatus({
        title: "Исполнитель успешно заблокирован!",
        status: "success",
        visible: true
      });
      socketRef.current!.emit('send_notify', {
        "message": response.data['message'],
        "message_id": response.data['message_id'],
      });
    }
    setShowDropDown(false);
  };

  const adminBlockClientButton = async () => {
    const response = await API.Admin.chatBlockUser(
      selectedRoomData.client_uuid!, selectedRoomData.room_uuid, selectedRoomData.trade_uuid
    )
    if (response.data['status'] === 'OK') {
      updateSnackbarStatus({
        title: "Клиент успешно заблокирован!",
        status: "success",
        visible: true
      });
      socketRef.current!.emit('send_notify', {
        "message": response.data['message'],
        "message_id": response.data['message_id'],
      });
    }
    setShowDropDown(false);
  };

  const adminCloseChatButton = async () => {
    const response = await API.Admin.closeChat(selectedRoomData.room_uuid, selectedRoomData.trade_uuid)
    if (response.data['status'] === 'OK') {
      updateSnackbarStatus({
        title: "Чат успешно закрыт!",
        status: "success",
        visible: true
      });
      socketRef.current!.emit('send_notify', {
        "message": response.data['message'],
        "message_id": response.data['message_id'],
      });
    }
    setShowDropDown(false);
  };

  const adminCloseTradeButton = async () => {
    const response = await API.Admin.closeTrade(selectedRoomData.room_uuid, selectedRoomData.trade_uuid)
    if (response.data['status'] === 'OK') {
      updateSnackbarStatus({
        title: "Interaction успешно закрыт!",
        status: "success",
        visible: true
      });
      socketRef.current!.emit('send_notify', {
        "message": response.data['message'],
        "message_id": response.data['message_id'],
      });
    }
    setShowDropDown(false);
  };

  return (
    <div>
      <TelegramBackButton
        onClick={backButtonHandler}
      />
      <section className={styles.dialog}>
        {
            isShowDropDown &&
            <div className={styles['dialog__dropdown-list_active']}>
              <div id="categories_id" className={styles['dialog__dropdown-list-categories']}>
                {
                  !storeMessagesData.roomAdditionallyData.is_fixed && selectedRoomData.moderator_uuid !== userData.user_uuid && !selectedRoomData.chat_closed && !storeMessagesData.roomAdditionallyData.is_broken &&
                    <li className={styles.dialog__refusal} onClick={fixDealButton}>
                      <img src={""} alt=""/>
                      <span>Фикс</span>
                    </li>
                }
                {
                  storeMessagesData.roomAdditionallyData.is_fixed && !storeMessagesData.roomAdditionallyData.is_successful && selectedRoomData.moderator_uuid !== userData.user_uuid && !selectedRoomData.chat_closed && !storeMessagesData.roomAdditionallyData.is_broken &&
                    <>
                      <li className={styles.dialog__refusal} onClick={confirmTheDealButton}>
                          <img src={""} alt=""/>
                          <span>Подтвердить выполнение</span>
                      </li>
                      <li className={styles.dialog__refusal} onClick={refuseTheDealButton}>
                        <img src={""} alt=""/>
                        <span>Отмена</span>
                      </li>
                    </>
                }
                {
                  selectedRoomData.moderator_uuid !== userData.user_uuid &&
                    <li className={styles.dialog__moderator} onClick={callTheModeratorButton}>
                        <img src={""} alt=""/>
                        <span>Вызвать модератора</span>
                    </li>
                }

                {
                  selectedRoomData.moderator_uuid === userData.user_uuid &&
                    <>
                        <li className={styles.dialog__moderator} onClick={adminBlockImplementerButton}>
                            <img src={""} alt=""/>
                            <span>Заблокировать исполнителя</span>
                        </li>
                        <li className={styles.dialog__moderator} onClick={adminBlockClientButton}>
                            <img src={""} alt=""/>
                            <span>Заблокировать клиента</span>
                        </li>
                        <li className={styles.dialog__moderator} onClick={adminCloseChatButton}>
                            <img src={""} alt=""/>
                            <span>Закрыть переписку</span>
                        </li>
                        <li className={styles.dialog__moderator} onClick={adminCloseTradeButton}>
                            <img src={""} alt=""/>
                            <span>Закрыть исполнение</span>
                        </li>
                    </>
                }
              </div>
            </div>
        }
        <div className={styles.dialog__header}>
          <div className={styles['dialog__header-top']}>
            <div className={styles['dialog__header-left']} id="dialog__header-left">
              <div className={styles['dialog__header-text']}>
                <div className={styles['dialog__header-text__top']}>
                  <h2 className={styles['dialog__seller']}>{storeMessagesData.loading ? <Skeleton width={120} height={18}/> : storeMessagesData.roomAdditionallyData.interlocutor_username}</h2>
                  {/*<span className={styles['dialog__header__product-name']}>{storeMessagesData.loading ? <Skeleton width={230} height={20}/> : selectedRoomData.name}</span>*/}
                </div>
              </div>
            </div>
            <div className={styles['dialog__additionally-list']}>
              <button className={styles.dialog__additionally} id="dialog__additionally" onClick={openDealMenu}>Меню</button>
            </div>
          </div>
          <div className={styles['dialog__send-message']}>
            <TextareaAutosize maxRows={6} className={styles['dialog__message-input']}
                              id="dialog__message-input"
                              placeholder="Введите сообщение.." ref={messageTextRef} readOnly={selectedRoomData.chat_closed}/>
            <button className={styles['dialog__send-button']} onClick={sendMessageButton}></button>
          </div>
        </div>

        <div className={styles.dialog__messages} id="dialog__messages">
          {
            storeMessagesData.loading &&
              <>
                <div className={styles.dialog__incoming}>
                    <div className={styles['dialog__message-content']}>
                        <p className={styles.dialog__message}><Skeleton height={20} width={150}/></p>
                        <div>
                            <time><Skeleton height={10} width={25} /></time>
                        </div>
                    </div>
                </div>
                <div className={styles.dialog__outgoing}>
                    <div className={styles['dialog__message-content']}>
                        <p className={styles.dialog__message}><Skeleton height={20} width={150}/></p>
                        <div>
                            <time><Skeleton height={10} width={25} /></time>
                        </div>
                    </div>
                </div>
              </>
          }
          {
            storeMessagesData.messagesData.map((message) =>
              message.message_type === 'TEXT' ?
                message.sender_uuid === userData.user_uuid ?
                  <div className={styles.dialog__outgoing} key={message.message_id}>
                    <div className={styles['dialog__message-content']}>
                      <p className={styles.dialog__message}>{message.message}</p>
                      <div>
                        <time>{message.time}</time>
                        {
                          message.is_read ?
                            <img src={double_check} alt="" className={styles['dialog__message__bullet'] + ' ' + styles['check-mark__double']}/>
                            :
                            <img src={check_mark} alt="" className={styles['dialog__message__bullet'] + ' ' + styles['check-mark']}/>
                        }
                      </div>
                    </div>
                  </div>
                  :
                  message.sender_uuid === selectedRoomData.moderator_uuid ?
                    <div className={styles.dialog__incoming} key={message.message_id}>
                      <div className={styles['dialog__message-content']}>
                        <div className={styles['dialog__message-content__top']}>
                          <span className={styles['dialog__username-admin']}>Администратор</span>
                          <p className={styles.dialog__message}>{message.message}</p>
                        </div>
                        <div className={styles['dialog__message-content__bottom']}>
                          <time>{message.time}</time>
                        </div>
                      </div>
                    </div>
                    :
                    <div className={styles.dialog__incoming} key={message.message_id}>
                      <div className={styles['dialog__message-content']}>
                        <div className={styles['dialog__message-content__top']}>
                          <span className={styles['dialog__username']}>{message.sender_name}</span>
                          <p className={styles.dialog__message}>{message.message}</p>
                        </div>
                        <div className={styles['dialog__message-content__bottom']}>
                          <time>{message.time}</time>
                        </div>
                      </div>
                    </div>
                :
                <div className={styles['dialog__system-alert']}>
                  <p>{message.message}</p>
                </div>
            )
          }
          <span ref={messagesEndRef}></span>
        </div>
      </section>
    </div>
  );
}