import * as React from 'react';
import Dropdown from 'react-dropdown';
import './style_dropdown.scss';
import styles from '../CustomDropdown.module.scss';

const options = [
  'VISA', 'MASTERCARD', 'MAESTRO'
];

const defaultOption = options[0];

export function CustomDropdown() {
  return (
      <div className="CustomDropdownAdd__Wrapper">
        <div className="CustomDropdownAdd">
          <Dropdown options={options} value={defaultOption} placeholder="Select an option" />
        </div>
      </div>
  );
}