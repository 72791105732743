import {
  $adminSettingsStore, IAdminSettingsModel,
  resetAdminSettingsData,
  updateAdminSettingsData,
  updateAdminSettingsDataEvent
} from "./index";
import {createEffect} from "effector";
import {API} from "../../../api";

export const getAdminSettingsDataEx = createEffect(async (): Promise<IAdminSettingsModel> => {
  const response = await API.Admin.getExchangerSettings();
  return response.data['data']['admin_settings_data'];
});

$adminSettingsStore
  .on(getAdminSettingsDataEx.doneData, (_currentState, newState) => newState)
  .on(updateAdminSettingsData, updateAdminSettingsDataEvent)
  .reset(resetAdminSettingsData);

