import * as React from 'react';
import styles from './Profile.module.scss';
import arrow_left from "../../images/arrow_left.svg";
import plus from "../../images/plus_on_sircle.svg";
import avatar from "../../images/avatar.svg";
import {TelegramBackButton} from "../../components/BackButton/BackButton";
import {useStore} from "effector-react";
import {$user} from "../../models/User";
import RoutePaths from "../../routePaths";
import {useNavigate} from "react-router-dom";

export function Profile() {
  const navigate = useNavigate();

  const userData = useStore($user);

  const openCreateAdButton = () => navigate(RoutePaths.CREATE_ADD);

  return (
    <section className={styles.Profile}>
      <TelegramBackButton
        onClick={() => {
          window.history.back();
        }}
      />
      <div className={styles.Profile__Header}>
        <div className={styles.Profile__Header__Logo}>
          <img src={avatar} alt=""/>
        </div>

        <div className={styles.Profile__Header__Text}>
          <h2>@{window.Telegram.WebApp.initDataUnsafe.user!.username}</h2>
          <p>Данное имя используется как Ваш уникальный индификатор.</p>
        </div>
      </div>

      <div className={styles.Profile__Items}>
        <div className={styles.Profile__Items__Deals}>
          <div className={styles.Profile__Items__Deals__Item  + ' ' + styles.Profile__Items__Deals__Amount}>
            <span>{userData.rating}</span>
            <span>Рейтинг</span>
          </div>

          <div className={styles.Profile__Items__Deals__Item  + ' ' + styles.Profile__Items__Deals__Completed}>
            <span>{userData.successful_trades}</span>
            <span>Завершенные</span>
          </div>
        </div>

        {/*<div className={styles.Profile__Items__Button}>*/}
        {/*  <div className={styles.Profile__Items__Button__Left}>*/}
        {/*    <div className={styles.Profile__Items__Button__Logo}>*/}
        {/*      <img src={trade} alt=""/>*/}
        {/*    </div>*/}

        {/*    <div className={styles.Profile__Items__Button__Text}>*/}
        {/*      <h3>Торги</h3>*/}
        {/*    </div>*/}
        {/*  </div>*/}

        {/*  <ToggleSwitch label="Subscribe"></ToggleSwitch>*/}
        {/*</div>*/}
      </div>

      <div className={styles.Profile__Adds}>
        <h2 className={styles.Profile__Adds__Title}>Ваши объявления</h2>

        <div className={styles.Profile__Items__Button} onClick={openCreateAdButton}>
          <div className={styles.Profile__Items__Button__Left}>
            <div className={styles.Profile__Items__Button__Logo}>
              <img src={plus} alt="" className={styles.Profile__Items__Button__Logo__Plus}/>
            </div>

            <div className={styles.Profile__Items__Button__Text}>
              <h3 className={styles.Profile__Items__Button__Text__CreateAdd}>Создать объявление</h3>
            </div>
          </div>

          <div className={styles.Profile__Items__Button__Arrow}>
            <img src={arrow_left} alt=""/>
          </div>
        </div>
      </div>
    </section>
  );
}