import * as React from 'react';
import Dropdown from 'react-dropdown';
import './style_custom.scss';

const options = [
  { value: 'USDT(TRC20)', label: 'USDT (Tether TRC20)' },
  { value: 'USDT(ERC20)', label: 'USDT (Tether ERC20)' },
  { value: 'BTC', label: 'BTC (Bitcoin)' },
  { value: 'ETH', label: 'ETH (Etherium)' },
  { value: 'BCH', label: 'BCH (Bitcoin Cash)' },
];

const defaultOption = options[0];

export function CreateAddDropdown(props: {currencyRef: React.RefObject<Dropdown>}) {
  return (
      <div>
        <Dropdown ref={props.currencyRef} options={options} value={defaultOption} placeholder="Select an option" />
      </div>
  );
}