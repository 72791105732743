import {
  $usersData,
  $usersLoadingData, IUsersModel,
  resetUsersData, resetUsersLoadingUpdate,
  updateUsersData,
  updateUsersDataEvent,
  usersLoadingUpdate, usersLoadingUpdateEvent, usersLoadingUpdatePage, usersLoadingUpdatePageEvent
} from "./index";
import {combine, createEffect} from "effector";
import {API} from "../../../api";

export const getAllUsersListEx = createEffect(async ({page, offset}: {page: number, offset: number}): Promise<IUsersModel[]> => {
  const response = await API.Admin.getAllUsersList(page, offset);
  usersLoadingUpdate(response.data['data']['loading_data']);

  return response.data['data']['users_list'];
});

export const getSearchUserInListEx = createEffect(async ({username}: {username: string}): Promise<IUsersModel[]> => {
  const response = await API.Admin.getSearchUserInList(username);
  return response.data['data']['users_list'];
});

$usersData
  .on(getAllUsersListEx.doneData, (_currentState, newData) => [..._currentState, ...newData])
  .on(getSearchUserInListEx.doneData, (_currentState, newData) => [..._currentState, ...newData])
  .on(updateUsersData, updateUsersDataEvent)
  .reset(resetUsersData);

$usersLoadingData
  .on(usersLoadingUpdate, usersLoadingUpdateEvent)
  .on(usersLoadingUpdatePage, usersLoadingUpdatePageEvent)
  .reset(resetUsersLoadingUpdate)


export const $userListCombine = combine({
  loading: getAllUsersListEx.pending,
  usersList: $usersData
})