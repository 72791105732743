import * as React from 'react';
import styles from './Verification.module.scss';
import passport from '../../images/passport.svg';
import arrow from '../../images/arrow_left.svg';
import id_card from '../../images/id_card.svg';
import car from '../../images/car_license.svg';
import {useState} from "react";

export function VerificationDocumentType() {
  const [addBlock, setAddBlock] = useState(true);
  const [id, setID] = useState(null);

  const addBlockMenu = (val: any) => {
    if (addBlock) {
      setAddBlock(false)
    } else {
      setAddBlock(true)
    }
    setID(val)
  }

  return (
      <section className={styles.KycDoc}>
        <div className={styles.KycDoc__Top}>
          <div className={styles.KycDoc__Title}>
            <h2>Выберите страну выдачи документа</h2>

            <div className={styles.KycDoc__Title__Bottom}>
              <input type="text" placeholder="Страна"/>
            </div>
          </div>

          <div className={styles.KycDoc__Items}>
            <h2 className={styles.KycDoc__Items__Title}>Выберите тип документа</h2>

            <div className={styles.KycDoc__Items__Item} id="item_1" onClick={() => addBlockMenu('item_1')}>
              <div className={styles.KycDoc__Items__Item__Top}>
                <div className={styles.KycDoc__Items__Item__Left}>
                  <div>
                    <img src={passport} alt=""/>
                    <h3>Паспорт</h3>
                  </div>

                  <p>Отправьте фото Вашего паспорта, чтобы мы могли идентифицировать Вас.</p>
                </div>

                <div className={styles.KycDoc__Items__Item__Right}>
                  <img src={arrow} alt="" style={{transform: addBlock && id === 'item_1' ? "rotate(-90deg)" : ""}}/>
                </div>
              </div>

              {
                  addBlock && id === 'item_1' &&
                  <div className={styles.KycDoc__Items__Item__Bottom}>
                    <div className={styles.input__wrapper}>
                      <form encType="multipart/form-data" method="post" id="image_add_form">
                        <div className={styles.field__wrapper}>
                          <input name="file" type="file" id="field__file-2" className={styles.field__file} multiple accept="image/*,image/jpeg"></input>
                          <label className={styles['field__file-wrapper']} htmlFor="field__file-2">
                            <div className={styles['field__file-button']}>Отправить</div>
                            <div className={styles['field__file-fake']}>Файл не выбран</div>
                          </label>
                        </div>
                      </form>
                    </div>
                  </div>
              }

              <div className={styles.KycDoc__Items__Item__Photo}>
                <img src={passport} alt=""/>
              </div>
            </div>

            <div className={styles.KycDoc__Items__Item} id="item_2" onClick={() => addBlockMenu('item_2')}>
              <div className={styles.KycDoc__Items__Item__Top}>
                <div className={styles.KycDoc__Items__Item__Left}>
                  <div>
                    <img src={id_card} alt=""/>
                    <h3>ID карта</h3>
                  </div>

                  <p>Отправьте фото Вашей карты, чтобы мы могли идентифицировать Вас.</p>
                </div>

                <div className={styles.KycDoc__Items__Item__Right}>
                  <img src={arrow} alt="" style={{transform: addBlock && id === "item_2" ? "rotate(-90deg)" : ""}}/>
                </div>
              </div>

              {
                  addBlock && id === 'item_2' &&
                  <div className={styles.KycDoc__Items__Item__Bottom}>
                    <div className={styles.input__wrapper}>
                      <form encType="multipart/form-data" method="post" id="image_add_form">
                        <div className={styles.field__wrapper}>
                          <input name="file" type="file" id="field__file-2" className={styles.field__file} multiple accept="image/*,image/jpeg"></input>
                          <label className={styles['field__file-wrapper']} htmlFor="field__file-2">
                            <div className={styles['field__file-button']}>Отправить</div>
                            <div className={styles['field__file-fake']}>Файл не выбран</div>
                          </label>
                        </div>
                      </form>
                    </div>
                  </div>
              }
            </div>

            <div className={styles.KycDoc__Items__Item} id="item_3" onClick={() => addBlockMenu('item_3')}>
              <div className={styles.KycDoc__Items__Item__Top}>
                <div className={styles.KycDoc__Items__Item__Left}>
                  <div>
                    <img src={car} alt=""/>
                    <h3>Водительское удостоверение</h3>
                  </div>

                  <p>Отправьте фото Вашего водительского удостоверения, чтобы мы могли идентифицировать Вас.</p>
                </div>

                <div className={styles.KycDoc__Items__Item__Right}>
                  <img src={arrow} alt="" style={{transform: addBlock && id === "item_3" ? "rotate(-90deg)" : ""}}/>
                </div>
              </div>

              {
                  addBlock && id === 'item_3' &&
                  <div className={styles.KycDoc__Items__Item__Bottom}>
                    <div className={styles.input__wrapper}>
                      <form encType="multipart/form-data" method="post" id="image_add_form">
                        <div className={styles.field__wrapper}>
                          <input name="file" type="file" id="field__file-2" className={styles.field__file} multiple accept="image/*,image/jpeg"></input>
                          <label className={styles['field__file-wrapper']} htmlFor="field__file-2">
                            <div className={styles['field__file-button']}>Отправить</div>
                            <div className={styles['field__file-fake']}>Файл не выбран</div>
                          </label>
                        </div>
                      </form>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>

        <div className={styles.KycDoc__Bottom}>
          <div className={styles.Kyc__Button}>
            <button>Продолжить</button>
          </div>
        </div>
      </section>
  );
}