import {
  $user,
  updateUserAuth,
  updateUserAuthEvent,
  updateUserData,
  updateUserDataEvent,
  updateUserRulesSigned, updateUserRulesSignedEvent
} from "./index";
import { persist } from 'effector-storage/local'

$user
  .on(updateUserData, updateUserDataEvent)
  .on(updateUserAuth, updateUserAuthEvent)
  .on(updateUserRulesSigned, updateUserRulesSignedEvent);

persist({store: $user, key: 'userPersistData'});