import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.module.scss';
import App from './App';
import './models/init';

import * as Sentry from "@sentry/react";
import {CaptureConsole} from "@sentry/integrations";
import {BrowserTracing} from "@sentry/react";
import {BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from 'react-router-dom';

Sentry.init({
  dsn: "https://d2b37b8008844ac3953dc7e3a8bc86a4@xxcas-domain-ec.com/2",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new CaptureConsole(
      {
        levels: ['debug', 'info', 'warn', 'error']
      }
    )
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
