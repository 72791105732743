import * as React from 'react';
import styles from './Verification.module.scss';
import passport from '../../images/passport.svg';
import selfie from '../../images/selfie.svg';
import mail from '../../images/mail.svg';
import license from '../../images/license.svg';

export function VerificationConfirm() {
  return (
      <section className={styles.KycConf}>
        <div className={styles.KycConf__Top}>
          <div className={styles.KycConf__Title}>
            <h2>Подтверждение личности</h2>
            <span>Это займёт не более получаса</span>
          </div>

          <div className={styles.KycConf__Items}>
            <div className={styles.KycConf__Items__Item}>
              <img src={passport} alt=""/>

              <div className={styles.KycConf__Items__Item__Text}>
                <h3>Паспорт</h3>
                <p>Сфотографируйте Ваше удостоверение личности</p>
              </div>
            </div>

            <div className={styles.KycConf__Items__Item}>
              <img src={license} alt=""/>

              <div className={styles.KycConf__Items__Item__Text}>
                <h3>Платежной документ</h3>
                <p>Отправьте нам фотографию официального платежного документа, в которм указаны Ваши ФИО</p>
              </div>
            </div>

            <div className={styles.KycConf__Items__Item}>
              <img src={selfie} alt=""/>

              <div className={styles.KycConf__Items__Item__Text}>
                <h3>Видеоселфи</h3>
                <p>Отправьте видео с Вами и датой вашего рождения на бумажном носителе</p>
              </div>
            </div>

            <div className={styles.KycConf__Items__Item}>
              <img src={mail} alt=""/>

              <div className={styles.KycConf__Items__Item__Text}>
                <h3>Подтвердите почту</h3>
                <p>Подтвердите Вашу почту и пришлите нам код</p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.KycConf__Bottom}>
          <div className={styles.Kyc__Button}>
            <button>Продолжить</button>
          </div>
        </div>
      </section>
  );
}