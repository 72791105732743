import React, {useState} from 'react';
import styles from './AdminPanel.module.scss';
import plus from '../../images/plus_on_sircle.svg';
import cross from '../../images/cross.svg';
import edit from '../../images/edit.svg';
import Modal from "../../components/Modal/Modal";
import {DropdownStatusModal} from "../../components/DropdownAdminModal/CustomDropdown";

const AdminPanelGroup = () => {
  const [modalActive, setModalActive] = useState(false)

  return (
      <section className={styles.AdminPanel}>
        <div className={styles.AdminPanel__Header}>
          <div className={styles.AdminPanel__Header__Button}>
            <img src={plus} alt=""/>
            <button onClick={() => setModalActive(true)}>Добавить группу</button>
          </div>
        </div>

        <div className={styles.AdminPanel__Items}>
          <div className={styles.AdminPanel__Items__Header}>
            <h3>ID</h3>
            <h3>Название</h3>
            <h3>Типы</h3>
          </div>

          <div className={styles.AdminPanel__Items__Main}>
            <div className={styles.AdminPanel__Items__Item}>
              <span>-10493049309</span>
            </div>

            <div className={styles.AdminPanel__Items__Item}>
              <span>Курилка</span>
            </div>


            <div className={styles.AdminPanel__Items__Item}>
              <span>Чат - курилка</span>


              <div className={styles.AdminPanel__Items__Item__Edit}>
                <img src={edit} alt=""/>
                <img src={cross} alt=""/>
              </div>
            </div>
          </div>

          <div className={styles.AdminPanel__Items__Main}>
            <div className={styles.AdminPanel__Items__Item}>
              <span>-10493049309</span>
            </div>

            <div className={styles.AdminPanel__Items__Item}>
              <span>Курилка</span>
            </div>


            <div className={styles.AdminPanel__Items__Item}>
              <span>Чат - курилка</span>


              <div className={styles.AdminPanel__Items__Item__Edit}>
                <img src={edit} alt=""/>
                <img src={cross} alt=""/>
              </div>
            </div>
          </div>

          <div className={styles.AdminPanel__Items__Main}>
            <div className={styles.AdminPanel__Items__Item}>
              <span>-10493049309</span>
            </div>

            <div className={styles.AdminPanel__Items__Item}>
              <span>Курилка</span>
            </div>


            <div className={styles.AdminPanel__Items__Item}>
              <span>Чат - курилка</span>


              <div className={styles.AdminPanel__Items__Item__Edit}>
                <img src={edit} alt=""/>
                <img src={cross} alt=""/>
              </div>
            </div>
          </div>
          <Modal active={modalActive} setActive={setModalActive}>
            <div className="AdminPanel__Modal">
              <div className="AdminPanel__Modal__ID">
                <span>ID:</span>
                <input type="text" placeholder="Введите текст"/>
              </div>

              <div className="AdminPanel__Modal__Name">
                <span>Название:</span>
                <input type="text" placeholder="Введите текст"/>
              </div>

              <div className="AdminPanel__Modal__Name__Type">
                <span>Тип:</span>
                <DropdownStatusModal></DropdownStatusModal>
              </div>

              <div className="AdminPanel__Modal__Buttons">
                <button>Сохранить</button>
                <button>Отменить</button>
              </div>
            </div>
          </Modal>
        </div>
      </section>
  );
};

export default AdminPanelGroup;