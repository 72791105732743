import styles from './UserWallet.module.scss';
import offer_logo from '../../images/offer.svg';
import {useStore} from "effector-react";
import {
  $offersDataCombine,
  getClientOffersEx,
  getModerationOffersEx, getSuccessfulOffersEx,
  getUserOffersEx
} from "../../models/Offers/GetOffers/init";
import React, {useEffect, useState} from "react";
import {useInView} from "react-intersection-observer";
import {offersDeleteItem, offersLoadingUpdatePage, resetOffersData} from "../../models/Offers/GetOffers";
import {$user} from "../../models/User";
import RoutePaths from "../../routePaths";
import {useLocation, useNavigate} from "react-router-dom";
import {API} from "../../api";
import {updateSnackbarStatus} from "../../models/Snackbar";
import {TelegramBackButton} from "../../components/BackButton/BackButton";
import {ERoomsMode, roomsModeUpdate, roomsResetData, roomsResetLoadingPage} from "../../models/Chat/Room";
import {socketRooms} from "../../api/socket";

export function UserWallet() {
  const {state} = useLocation();
  const {adsType} = state;

  const navigate = useNavigate();
  const [scrollTriggered, setScrollTriggered] = useState<boolean>(false);

  const offersData = useStore($offersDataCombine);

  useEffect(() => {
    resetOffersData();

    if (adsType === 'IMPL') {
      roomsModeUpdate(ERoomsMode.UserMode);
      getUserOffersEx({
        page: 1,
        offset: -1
      }).then();
    } else if (adsType === 'CLIENT') {
      roomsModeUpdate(ERoomsMode.UserMode);
      getClientOffersEx({
        page: 1,
        offset: -1
      }).then();
    } else if (adsType === 'MODERATION') {
      roomsModeUpdate(ERoomsMode.ModerationMode);
      getModerationOffersEx({
        page: 1,
        offset: -1
      }).then();
    } else if (adsType === 'SUCCESSFUL') {
      roomsModeUpdate(ERoomsMode.UserMode);
      getSuccessfulOffersEx({
        page: 1,
        offset: -1
      }).then();
    }
  }, []);

  const {ref, inView, entry} = useInView({
    triggerOnce: true
  });

  if (inView && !scrollTriggered && !offersData.loadingData.last_page && !offersData.userOffersLoading
    && !offersData.clientOffersLoading && !offersData.moderationOffersLoading && !offersData.successfulOffersLoading) {
    entry?.target.removeAttribute('ref');
    setScrollTriggered(true);

    offersLoadingUpdatePage(offersData.loadingData.page + 1);

    if (adsType === 'IMPL') {
      getUserOffersEx({
        page: offersData.loadingData.page + 1,
        offset: offersData.loadingData.offset
      }).then();
    } else if (adsType === 'CLIENT') {
      getClientOffersEx({
        page: offersData.loadingData.page + 1,
        offset: offersData.loadingData.offset
      }).then();
    } else if (adsType === 'MODERATION') {
      getModerationOffersEx({
        page: offersData.loadingData.page + 1,
        offset: offersData.loadingData.offset
      }).then();
    } else if (adsType === 'SUCCESSFUL') {
      getSuccessfulOffersEx({
        page: offersData.loadingData.page + 1,
        offset: offersData.loadingData.offset
      }).then();
    }
  }

  useEffect(() => {
    if (adsType === 'IMPL' && !offersData.userOffersLoading && !offersData.loadingData.last_page) {
      setScrollTriggered(true);
    } else if (adsType === 'CLIENT' && !offersData.clientOffersLoading && !offersData.loadingData.last_page) {
      setScrollTriggered(true);
    } else if (adsType === 'MODERATION' && !offersData.moderationOffersLoading && !offersData.loadingData.last_page) {
      setScrollTriggered(true);
    } else if (adsType === 'SUCCESSFUL' && !offersData.successfulOffersLoading && !offersData.loadingData.last_page) {
      setScrollTriggered(true);
    }
  }, [adsType, offersData])

  const convertOfferType = (text: string): string => {
    switch (text) {
      case 'SELL':
        return 'Продам';
      case 'BUY':
        return 'Куплю';
      case 'REQUEST':
        return 'Объявление';
    }
    return 'Неизвестный тип';
  };

  const convertPageTitle = (): string => {
    switch (adsType) {
      case 'IMPL':
        return 'Объявления';
      case 'CLIENT':
        return 'Запросы';
      case 'MODERATION':
        return 'Модерируемые чаты';
      case 'SUCCESSFUL':
        return 'История';
    }
    return 'Неизвестный тип';
  };

  const openOfferChatsButton = (offerUuid: string, roomUuid?: string) => {
    if (adsType === 'SUCCESSFUL') {
      roomsResetData();
      roomsResetLoadingPage();

      socketRooms.disconnect();

      navigate(RoutePaths.CHAT, {
        state: {
          roomUuid: roomUuid
        }
      });
    } else {
      navigate(RoutePaths.ROOMS, {
        state: {
          offerUuid: offerUuid
        }
      });
    }
  };

  const deleteOfferButton = async (offerUuid: string, index: number) => {
    const response = await API.Offer.deleteUserOffer(offerUuid);
    if (response.data['status'] === 'OK') {
      offersDeleteItem(index);
      updateSnackbarStatus({
        title: "Данное объявление было успешно удалено!",
        status: "success",
        visible: true
      });
    } else if (response.data['status'] === 'Offer not found') {
      updateSnackbarStatus({
        title: "Данное объявление не найдено!",
        status: "error",
        visible: true
      });
    } else if (response.data['status'] === 'Found active trades!') {
      updateSnackbarStatus({
        title: "Данное объявление нельзя удалить, так как по нему есть активные interactions",
        status: "error",
        visible: true
      });
    }
  };

  const convertSurchargeType = (text: string) => {
    switch (text) {
      case 'От меня':
        return 'Доплачу Вам';
      case 'От Вас':
        return 'Доплатите мне'
      default:
        return ''
    }
  }

  return (
      <section className={styles.UserWallet}>
        <TelegramBackButton
          onClick={() => {
            window.history.back();
          }}
        />
          <div className={styles.UserWallet__Header}>
            <h2>{convertPageTitle()}</h2>
            <img src={offer_logo} alt=""/>
          </div>
        {/*{*/}
        {/*  userData.is_admin &&*/}
        {/*    <div className={styles.UserWallet__ModeratorButton + " " + styles.UserWallet__ModeratorButtonActive}>*/}
        {/*        <button>Режим модератора</button>*/}
        {/*    </div>*/}
        {/*}*/}
          <div className={styles.UserWallet__Adds}>
            {
              offersData.offers.map((offer, index) =>
                offer.offer_type === 'REQUEST' ? (
                  <div className={styles.UserWallet__Add__Card} ref={index === offersData.offers.length - 1 ? ref : null}>
                    <div className={styles.UserWallet__Add}>
                      <div className={styles.UserWallet__Add__Main}>

                        <div className={styles.UserWallet__Add__Main__Item}>

                          <div className={styles.UserWallet__Add__Main__Item__Card}>
                            <div className={styles.UserWallet__Add__Main__Item__Card__Top}>
                              <div className={styles.UserWallet__Add__Main__String}>
                                <div className={styles.UserWallet__Add__Main__String__Left}></div>
                              </div>

                              <div className={styles.UserWallet__Add__Header}>
                                <div className={styles.UserWallet__Add__Header__Right}>
                                  <div className={styles.UserWallet__Add__Header__Right__Top}>
                                    <button className={styles.UserWallet__Add__Header__Right__Share}></button>
                                    <button className={styles.UserWallet__Add__Header__Right__OpenChats} onClick={() => openOfferChatsButton(offer.uuid, offer.room_uuid)}>Открыть чаты</button>
                                    {
                                      adsType === 'IMPL' &&
                                        <button className={styles.UserWallet__Add__Header__Right__Delete} onClick={() => deleteOfferButton(offer.uuid, index)}>Удалить</button>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className={styles.UserWallet__Add__Main__String}>
                              <div className={styles.UserWallet__Add__Main__String__Left}>
                                <span className={styles.UserWallet__Add__Main__Available}>Номер</span>
                              </div>

                              <div className={styles.UserWallet__Add__Main__String__Right}>
                                <span className={styles.UserWallet__Add__Main__String__Span}>{offer.id}</span>
                              </div>
                            </div>

                            <div className={styles.UserWallet__Add__Main__String}>
                              <div className={styles.UserWallet__Add__Main__String__Left}>
                                <span className={styles.UserWallet__Add__Main__Available}>Тип</span>
                              </div>

                              <div className={styles.UserWallet__Add__Main__String__Right}>
                                <span className={styles.UserWallet__Add__Main__String__Span}>Объявление</span>
                              </div>
                            </div>
                          </div>

                          <div className={styles.UserWallet__Add__Main__String}>
                            <p>{offer.additionally}</p>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                ) : (
                  <div className={styles.UserWallet__Add} key={offer.uuid} ref={index === offersData.offers.length - 1 ? ref : null}>
                    <div className={styles.UserWallet__Add__Header}>
                      <div className={styles.UserWallet__Add__Header__Left}>
                        <span className={styles.UserWallet__Add__Header__Price}>{offer.amount} {offer.currency}</span>
                      </div>

                      <div className={styles.UserWallet__Add__Header__Right}>
                        <div className={styles.UserWallet__Add__Header__Right__Top}>
                          <button className={styles.UserWallet__Add__Header__Right__Share}></button>
                          <button className={styles.UserWallet__Add__Header__Right__OpenChats} onClick={() => openOfferChatsButton(offer.uuid, offer.room_uuid)}>Открыть чаты</button>
                        </div>

                        {
                          adsType === 'IMPL' &&
                            <button className={styles.UserWallet__Add__Header__Right__Delete} onClick={() => deleteOfferButton(offer.uuid, index)}>Удалить</button>
                        }
                      </div>
                    </div>

                    <div className={styles.UserWallet__Add__Main}>
                      <div className={styles.UserWallet__Add__Main__Item}>
                        <div className={styles.UserWallet__Add__Main__String}>
                          <div className={styles.UserWallet__Add__Main__String__Left}>
                            <span className={styles.UserWallet__Add__Main__Available}>Номер</span>
                          </div>

                          <div className={styles.UserWallet__Add__Main__String__Right}>
                            <span className={styles.UserWallet__Add__Main__String__Span}>{offer.id}</span>
                          </div>
                        </div>

                        <div className={styles.UserWallet__Add__Main__String}>
                          <div className={styles.UserWallet__Add__Main__String__Left}>
                            <span className={styles.UserWallet__Add__Main__Available}>Тип</span>
                          </div>

                          <div className={styles.UserWallet__Add__Main__String__Right}>
                            <span className={styles.UserWallet__Add__Main__String__Span}>{convertOfferType(offer.offer_type)}</span>
                          </div>
                        </div>

                        <div className={styles.UserWallet__Add__Main__String}>
                          <div className={styles.UserWallet__Add__Main__String__Left}>
                            <span className={styles.UserWallet__Add__Main__Limits}>Город</span>
                          </div>

                          <div className={styles.UserWallet__Add__Main__String__Right}>
                            <span className={styles.UserWallet__Add__Main__String__Span}>{offer.city}</span>
                          </div>
                        </div>

                        {/*<div className={styles.UserWallet__Add__Main__String}>*/}
                        {/*  <div className={styles.UserWallet__Add__Main__String__Left}>*/}
                        {/*    <span className={styles.UserWallet__Add__Main__PayMethods}>Валюта</span>*/}
                        {/*  </div>*/}

                        {/*  <div className={styles.UserWallet__Add__Main__String__Right}>*/}
                        {/*    <span className={styles.UserWallet__Add__Main__String__Span}>{offer.currency}</span>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                        {
                          offer.surcharge_type !== 'NONE_SUR' &&
                            <div className={styles.UserWallet__Add__Main__String}>
                                <div className={styles.UserWallet__Add__Main__String__Left}>
                                    <h4 className={styles.UserWallet__Add__Main__SellerName}>{convertSurchargeType(offer.surcharge_type)}</h4>
                                </div>

                                <div className={styles.UserWallet__Add__Main__String__Right}>
                                    <span className={styles.UserWallet__Add__Main__String__Span}>{offer.bet}%</span>
                                </div>
                            </div>
                        }

                        <div className={styles.UserWallet__Add__Main__String + " " + styles.AdditionallyInfo}>
                          <div className={styles.UserWallet__Add__Main__String__Left}>
                            <span>Доп. информация</span>
                          </div>

                          <div className={styles.UserWallet__Add__Main__String__Right}>
                            <span className={styles.UserWallet__Add__Main__String__Span}>{offer.additionally}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )
            }
          </div>
      </section>
  );
}