import axios from "axios";
import {config} from "../../config";
import {ApiPaths} from "../../apiPaths";
import {IAdminSettingsModel} from "../../models/Admin/Settings";

export class AdminApi {
  public async getAllUsersList(page: number, offset: number) {
    return await axios.post(config.apiHost + ApiPaths.ADMIN_GET_USERS_LIST, {
      "event": 'getAllUsersList',
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData
        },
        "loading_data": {
          "page": page,
          "offset": offset
        }
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    })
  };
  public async getAdminUserProfile(userId: number) {
    return await axios.post(config.apiHost + ApiPaths.ADMIN_GET_USER_PROFILE, {
      "event": 'getAdminUserProfile',
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData
        },
        "admin_user_data": {
          "user_id": userId,
        }
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    })
  }
  public async getSearchUserInList(username: string) {
    return await axios.post(config.apiHost + ApiPaths.ADMIN_SEARCH_USER, {
      "event": 'getSearchUserInList',
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData
        },
        "admin_user_data": {
          "username": username,
        }
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    })
  };
  public async updateUserData(userId: number, rating: number, role: number) {
    return await axios.post(config.apiHost + ApiPaths.ADMIN_USER_UPDATE, {
      "event": 'getSearchUserInList',
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData
        },
        "admin_user_data": {
          "user_id": userId,
          "rating": rating,
          "role": role,
        }
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    })
  };
  public async getExchangerSettings() {
    return await axios.post(config.apiHost + ApiPaths.ADMIN_GET_SETTINGS, {
      "event": 'getExchangerSettings',
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData
        },
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    })
  };
  public async saveExchangerSettings(data: IAdminSettingsModel) {
    return await axios.post(config.apiHost + ApiPaths.ADMIN_SAVE_SETTINGS, {
      "event": 'saveExchangerSettings',
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData
        },
        "new_settings_data": {
          "offer_price": data.offer_price,
          "delete_successful_offers": data.delete_successful_offers,
          "delete_inactive_offer": data.delete_inactive_offer,
          "initial_balance": data.initial_balance,
          "sub_mouth_price": data.sub_mouth_price
        }
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    })
  };
  public async chatBlockUser(user_uuid: string, room_uuid: string, trade_uuid: string) {
    return await axios.post(config.apiHost + ApiPaths.ADMIN_BLOCK_USER, {
      "event": 'saveExchangerSettings',
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData
        },
        "admin_chat_data": {
          "user_uuid": user_uuid,
          "room_uuid": room_uuid,
          "trade_uuid": trade_uuid
        }
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    })
  };
  public async closeChat(room_uuid: string, trade_uuid: string) {
    return await axios.post(config.apiHost + ApiPaths.ADMIN_CLOSE_CHAT, {
      "event": 'saveExchangerSettings',
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData
        },
        "admin_chat_data": {
          "room_uuid": room_uuid,
          "trade_uuid": trade_uuid
        }
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    })
  };
  public async closeTrade(room_uuid: string, trade_uuid: string) {
    return await axios.post(config.apiHost + ApiPaths.ADMIN_CLOSE_TRADE, {
      "event": 'saveExchangerSettings',
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData
        },
        "admin_chat_data": {
          "room_uuid": room_uuid,
          "trade_uuid": trade_uuid
        }
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    })
  };
  public async deleteOffer(offer_id: number, offer_uuid: string, deleteConfirm?: boolean) {
    return await axios.post(config.apiHost + ApiPaths.ADMIN_DELETE_OFFER, {
      "event": 'saveExchangerSettings',
      "data": {
        "user_data": {
          "web_view_init_data": window.Telegram.WebApp.initData
        },
        "admin_offer_data": {
          "offer_id": offer_id,
          "offer_uuid": offer_uuid,
          "delete_confirm": deleteConfirm
        }
      },
      "jsonapi": {
        "version": '1.0'
      },
      "meta": null
    })
  };
}