import React from 'react';
import styles from './FAQ.module.scss';
import {TelegramBackButton} from "../../components/BackButton/BackButton";
import {useNavigate} from "react-router-dom";
import RoutePaths from "../../routePaths";

const Faq = () => {
  const navigate = useNavigate();
  const openViewInfoButton = () => {
    navigate(RoutePaths.VIEW_INFO_PAGE);
  };

  return (
      <section className={styles.Faq}>
        <TelegramBackButton
          onClick={() => {
            window.history.back();
          }}
        />
        <div className={styles.Faq__Title}>
          <h2>FAQ</h2>
        </div>

        <div className={styles.Faq__Params}>
          <div className={styles.Faq__Param}>
            <p>
              Запрещен самостоятельный обмен никнеймами в переписке. За нарушение этого правила будет сниматься один балл. Действует система рейтингов, начисляется 1 балл в случае удачного взаимодействия двух участников.
            </p>
          </div>

          <div className={styles.Faq__Param}>
            <p>
              Юзер+профучастник = 1 балл каждому.
            </p>
          </div>

          <div className={styles.Faq__Param + " " + styles.Faq__Param__Border}>
            <p>
              Действует система рейтинга профучастников по удачным взаимодействиям, определенное количество баллов отражается в виде смайликов.
            </p>
          </div>

          <div className={styles.Faq__Strings}>
              <span>0-4 🏁</span>
              <span>4-10 🏃‍♂</span>

              <span>11-40 🚴‍♀🚴‍♀</span>

              <span>41-90 🏎🏎🏎</span>

              <span>91-150 🚀🚀🚀🚀</span>

              <span>151-250 🔥</span>
              <span>251-400 🔥🔥</span>

              <span>401-600 🔥🔥🔥</span>

              <span>601-750 🔥🔥🔥🔥</span>

              <span>751-1000 🔥🔥🔥🔥🔥</span>
          </div>

          <div className={styles.Faq__Param}>
            <p>
              Если аккаунт будет иметь отрицательное количество баллов, восстановление рейтинга до нуля, 1 балл =100usdt
            </p>
          </div>

          <div className={styles.Faq__Button}>
            <button onClick={openViewInfoButton}>Открыть соглашение</button>
          </div>
        </div>
      </section>
  );
};

export default Faq;